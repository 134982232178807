// @flow

import {
    FETCH_TEAM_PLAYER_START,
    FETCH_TEAM_PLAYER_SUCCESS,
    FETCH_TEAM_PLAYER_FAILURE,
} from 'actions/team/teamPlayerActions';

import {
    STATUS_SUCCESS,
    STATUS_FAILURE,
    STATUS_LOADING,
} from 'constants/statusConstants';

import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

type SeasonState = {
    status: Status,
    players: {
        id: string,
        numeroAffiliation: string,
        nom: string,
        nom_f: string,
        poste: string,
        photo: any
    }[]
};

export type State = {
    [key: string]: SeasonState
};

const initialState: State = {};

export default function (state: State = initialState, action: ActionType): State {
    let key;

    switch (action.type) {
        case FETCH_TEAM_PLAYER_START:
            key = action.payload.season.toString();

            return {
                ...state,
                [key]: state[key]
                    ? { ...state[key], status: STATUS_LOADING }
                    : { status: STATUS_LOADING }
            };

        case FETCH_TEAM_PLAYER_SUCCESS:
            key = action.payload.season.toString();

            return {
                ...state,
                [key]: {
                    status: STATUS_SUCCESS,
                    players: action.payload.players,
                }
            };

        case FETCH_TEAM_PLAYER_FAILURE:
            key = action.payload.season.toString();

            return {
                ...state,
                [key]: { players: [], status: STATUS_FAILURE }
            };

        default:
            return state;
    }
}
