// @flow
import type { RawYoutubePostsType, YoutubeContent } from 'types/YoutubeContent';
import { nullYoutubePosts } from 'types/YoutubeContent';
import { mergeIntoDefault } from 'utils/objectUtils';

export function convertYoutubePostsFromApi(rawYoutubePosts: RawYoutubePostsType): YoutubeContent {
  if (!rawYoutubePosts) {
    return nullYoutubePosts;
  }

  const { id, player, contentDetails, snippet, statistics } = rawYoutubePosts;

  return mergeIntoDefault(nullYoutubePosts, {
    id,
    description: snippet.title + (snippet.description ? '<br /><br />' + snippet.description : ''),
    likes: parseInt(statistics.likeCount, 10),
    share: parseInt(statistics.viewCount, 10),
    comments: parseInt(statistics.commentCount, 10),
    date: snippet.publishedAt,
    player: player.embedHtml,
    backgroundImage: snippet.thumbnails ? snippet.thumbnails.medium.url : '',
    backgroundImageStandard: snippet.thumbnails ? snippet.thumbnails.standard.url : '',
    duration: contentDetails.duration,
    tags: snippet.tags
  });
}
