// @flow

import { STATUS_UNKNOWN, STATUS_DEFAULT, STATUS_LOADING, STATUS_SUCCESS } from 'constants/statusConstants';
import { FETCH_PAGE_LIST_SUCCESS, FETCH_UNKNOWN, FETCH_PAGE_LIST_START } from 'actions/pageActions';

import type { BlocContent } from 'types/NewsletterBloc';
import type { Status } from 'types/Status';
import type { CardType, NewsNunType } from 'types/PageList';
import type { Pratique } from 'types/Club';
import type { ActionType } from 'types/Actions';
import { nullPratique } from 'types/Club';

export type State = {
  title: string,
  titleParent: string,
  description: BlocContent[],
  excerpt: string,
  slug: string,
  label: {
    id: number,
    name: string,
    slug: string
  },
  featured_media: {
    src: string,
    legend: string
  },
  rencontre_background: string,
  status: Status,
  sidebar_content: BlocContent[],
  competitions: number[],
  compte_joueurs: number,
  effectif: {
    id: string,
    numeroAffiliation: string,
    nom: string,
    nom_f: string,
    poste: string,
    photo: any
  }[],
  pratique: Pratique,
  meta_title: string,
  meta_description: string,
  template: string,
  classe_age: string,
  redirect_resultats: string,
  news: NewsNunType[],
  redirect_competitions: string,
  liste_enfants: CardType[],
  liste_freres: CardType[],
  genre: string,
  redirection: string,
  competitions_saisons: {
    [key: string]: {
      competitions?: { id: string, identifiant: string, nom: string }[],
      effectifMoins: string | string[] | number[]
    }
  },
  effectifMoins: string | number[] | string[],
  albums_filtres: {
    term_id: number,
    name: string,
    slug: string
  }[],
  albums_saisons: {
    term_id: number,
    name: string,
    slug: string
  }[]
};

const initialState: State = {
  title: '',
  titleParent: '',
  excerpt: '',
  description: [
    {
      tagName: 'p',
      type: 'element',
      attributes: [],
      children: [
        {
          type: 'text',
          text: 'Chargement du contenu de la page...'
        }
      ]
    }
  ],
  slug: '',
  label: {
    id: 0,
    name: '',
    slug: ''
  },
  featured_media: {
    src: '',
    legend: ''
  },
  rencontre_background: '',
  status: STATUS_DEFAULT,
  sidebar_content: [],
  meta_title: '',
  meta_description: '',
  template: '',
  classe_age: '',
  redirect_resultats: '',
  liste_freres: [],
  redirect_competitions: '',
  liste_enfants: [],
  genre: '',
  news: [],
  redirection: '',
  competitions: [],
  compte_joueurs: 0,
  effectif: [],
  pratique: nullPratique,
  competitions_saisons: {},
  effectifMoins: ([]: number[]),
  albums_filtres: [],
  albums_saisons: []
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_PAGE_LIST_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_PAGE_LIST_SUCCESS:
      return {
        ...state,
        title: action.payload.pageList.title,
        description: action.payload.pageList.description,
        excerpt: action.payload.pageList.excerpt,
        slug: action.payload.pageList.slug,
        label: action.payload.pageList.label,
        featured_media: action.payload.pageList.featured_media,
        rencontre_background: action.payload.pageList.rencontre_background,
        sidebar_content: action.payload.pageList.sidebar_content,
        liste_enfants: action.payload.pageList.liste_enfants,
        liste_freres: action.payload.pageList.liste_freres,
        meta_title: action.payload.pageList.meta_title,
        meta_description: action.payload.pageList.meta_description,
        news: action.payload.pageList.news,
        genre: action.payload.pageList.genre,
        template: action.payload.pageList.template,
        classe_age: action.payload.pageList.classe_age,
        redirect_resultats: action.payload.pageList.redirect_resultats,
        redirect_competitions: action.payload.pageList.redirect_competitions,
        redirection: action.payload.pageList.redirection,
        competitions: action.payload.pageList.competitions,
        compte_joueurs: action.payload.pageList.compte_joueurs,
        pratique: action.payload.pageList.pratique,
        effectif: action.payload.pageList.effectif,
        competitions_saisons: action.payload.pageList.competitions_saisons,
        albums_filtres: action.payload.pageList.albums_filtres,
        albums_saisons: action.payload.pageList.albums_saisons,
        status: STATUS_SUCCESS
      };
    case FETCH_UNKNOWN:
      return {
        ...state,
        status: STATUS_UNKNOWN
      };
    default:
      return state;
  }
}
