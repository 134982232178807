// @flow

import type { Saga } from 'redux-saga';
import { takeLatest, call, put } from 'redux-saga/effects';
import type { GeneratorType } from 'sagas/root';

import {
    FETCH_TEAM_PLAYER_START,
    fetchTeamPlayerSuccess,
    fetchTeamPlayerError,
    type FETCH_TEAM_PLAYER_START_ACTION,
} from 'actions/team/teamPlayerActions';
import { fetchPlayersFromGraph } from 'api/graphApi/PlayerApi';

function formatPerson(item) {
    return {
        fdm: true,
        id: item.id,
        nom: `${item.prenom} ${item.nom}`,
        nom_f: item.nom,
        numeroAffilation: item.numeroAffilation,
        poste: 'Rugby compétition',
    };
}

export default function () {
    return function* teamPlayerSaga(): GeneratorType {
        yield takeLatest(FETCH_TEAM_PLAYER_START, fetchPlayerTeam);
    };

    function* fetchPlayerTeam(action: FETCH_TEAM_PLAYER_START_ACTION): Saga<void> {
        try {
            const { payload: { classeAge, clubID, sexe, season } } = action;

            const { data: { Personnes } } = yield call(
                fetchPlayersFromGraph,
                parseInt(clubID, 10),
                classeAge,
                sexe,
                parseInt(season, 10),
            );

            yield put(fetchTeamPlayerSuccess(Personnes.map(formatPerson), season));
        } catch (err) {
            fetchTeamPlayerError(err);
        }
    }
};
