// @flow

import { convertHomeFromApi } from 'services/Home';
import type { ApiExecutorType } from 'types/ApiExecutorType';

function HomeApi(executor: ApiExecutorType) {
  return {
    fetchHome
  };

  function fetchHome() {
    return executor.get(`/ffr/v1/home`).then(response => convertHomeFromApi(response));
  }
}

export default HomeApi;
