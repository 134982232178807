
// @flow

import {
  FETCH_PARTENAIRE_START,
  FETCH_PARTENAIRE_SUCCESS,
  FETCH_PARTENAIRE_FAILURE,
} from 'actions/partenaireActions';

import {
  STATUS_LOADING,
  STATUS_FAILURE,
  STATUS_SUCCESS,
  STATUS_DEFAULT,
} from 'constants/statusConstants';

import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';
import { nullPartenaire, type PartenaireType } from 'types/Partenaires';

export type State = {
  partenaireStatus: Status,
  partenaire: PartenaireType,
}

const initialState: State = {
  partenaireStatus: STATUS_DEFAULT,
  partenaire: nullPartenaire,
};

export default (state: State = initialState, action: ActionType): State => {
  switch (action.type) {
  case FETCH_PARTENAIRE_START:
    return {
      ...state,
      partenaireStatus: STATUS_LOADING,
    };
  case FETCH_PARTENAIRE_SUCCESS:
    return {
      ...state,
      partenaireStatus: STATUS_SUCCESS,
      partenaire: action.payload.partenaire,
    };
  case FETCH_PARTENAIRE_FAILURE:
    return {
      ...state,
      partenaireStatus: STATUS_FAILURE,
    };
  default:
    return state;
  }
};
