// @flow

import type { SidebarType } from 'types/Sidebar';

export const FETCH_SIDEBAR_START = 'FETCH_SIDEBAR_START';
export const FETCH_SIDEBAR_SUCCESS = 'FETCH_SIDEBAR_SUCCESS';
export const FETCH_SIDEBAR_FAILURE = 'FETCH_SIDEBAR_FAILURE';

export type FETCH_SIDEBAR_START_ACTION = {
  type: 'FETCH_SIDEBAR_START',
};

export type FETCH_SIDEBAR_SUCCESS_ACTION = {
  type: 'FETCH_SIDEBAR_SUCCESS',
  payload: {
    data: SidebarType,
  },
};

export type FETCH_SIDEBAR_FAILURE_ACTION = {
  type: 'FETCH_SIDEBAR_FAILURE',
};

export type Action = FETCH_SIDEBAR_START_ACTION | FETCH_SIDEBAR_SUCCESS_ACTION | FETCH_SIDEBAR_FAILURE_ACTION;

export function fetchGenericSidebar(): FETCH_SIDEBAR_START_ACTION {
  return {
    type: FETCH_SIDEBAR_START,
  };
}

export function sidebarGenericFetched(data: SidebarType): FETCH_SIDEBAR_SUCCESS_ACTION {
  return {
    type: FETCH_SIDEBAR_SUCCESS,
    payload: { data },
  };
}

export function sidebarGenericFetchError(error: any): FETCH_SIDEBAR_FAILURE_ACTION {
  return {
    type: FETCH_SIDEBAR_FAILURE,
    payload: {

    }
  };
}
