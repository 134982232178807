// @flow
import type { RawPageListType, PageListType } from 'types/PageList';
import type { RawPageNodeType, PageNodeType } from 'types/PageNode';
import type {
  RawPageDetailType,
  RawPageDetailPreviewType,
  PageDetailType,
  PageDetailPreviewType
} from 'types/PageDetail';
import { nullPageList } from 'types/PageList';
import { nullPageNode } from 'types/PageNode';
import { nullPageDetail, nullPageDetailPreview } from 'types/PageDetail';
import { mergeIntoDefault } from 'utils/objectUtils';
import { convertEncodedHTML } from 'utils/convertUtils';
import { store } from './../store';

/**
 * Pages de niveau 1
 */
export function convertPageListFromApi(rawPageList: RawPageListType[]): PageListType {
  if (!rawPageList) {
    return nullPageList;
  }

  let liste_enfantsMerged = nullPageList.liste_enfants;
  let labelMerged = nullPageList.label;

  if (rawPageList.length === 0) {
    return nullPageList;
  }

  const {
    title,
    parent,
    content,
    excerpt,
    slug,
    label,
    link,
    featured_media,
    rencontre_background,
    liste_enfants,
    liste_freres,
    meta,
    niveau,
    template,
    sidebar_content,
    meta_title,
    meta_description,
    news,
    redirect: { url },
    effectif,
    pratique,
    competitions_saisons = {},
    albums_saisons,
    albums_filtres
  } = rawPageList[0];

  if (liste_enfants) {
    liste_enfantsMerged = liste_enfants.map(liste_enfant => {
      return mergeIntoDefault(nullPageList.liste_enfants[0], liste_enfant);
    });
  }

  if (label) {
    labelMerged = label;
  }

  return mergeIntoDefault(nullPageList, {
    title: title.raw,
    description: content.data,
    excerpt: excerpt.raw,
    parent,
    link,
    slug,
    label: {
      id: labelMerged.id,
      name: convertEncodedHTML(labelMerged.name),
      slug: labelMerged.slug
    },
    featured_media,
    rencontre_background,
    liste_enfants: liste_enfantsMerged,
    niveau,
    classe_age: meta ? meta.classe_age : '',
    genre: meta ? meta.genre : '',
    template,
    sidebar_content: sidebar_content ? sidebar_content.data : [],
    meta_title,
    meta_description,
    news,
    liste_freres: liste_freres || [],
    redirection: url,
    competitions: meta ? meta.competitions_ovale : [],
    compte_joueurs: meta ? meta.compte_joueurs : 0,
    competitions_saisons: competitions_saisons,
    effectifMoins: (meta && meta.effectifMoins) || [],
    pratique,
    effectif,
    albums_saisons,
    albums_filtres
  });
}

/**
 * Page de niveau 2
 */
export function convertPageNodeFromApi(rawPageNode: RawPageNodeType[]): PageNodeType {
  if (!rawPageNode || rawPageNode.length === 0) {
    return nullPageNode;
  }

  let liste_enfantsMerged;

  const {
    id,
    title,
    title_parent,
    parent,
    excerpt,
    slug_parent,
    slug,
    rencontre_background,
    featured_media,
    liste_enfants,
    content,
    sidebar_content,
    niveau,
    template,
    meta_title,
    meta_description,
    redirect: { url },
    pratiques_structure,
    pratique
  } = rawPageNode[0];

  if (liste_enfants) {
    liste_enfantsMerged = liste_enfants.map(liste_enfant => {
      return mergeIntoDefault(nullPageNode.liste_enfants[0], liste_enfant);
    });
  } else {
    liste_enfantsMerged = nullPageNode.liste_enfants;
  }

  return mergeIntoDefault(nullPageNode, {
    id: id,
    title: title.raw,
    titleParent: convertEncodedHTML(title_parent),
    parent,
    description: excerpt.raw,
    content: content.data,
    sidebar_content: sidebar_content ? sidebar_content.data : [],
    slug_parent,
    slug,
    featured_media,
    rencontre_background,
    liste_enfants: liste_enfantsMerged,
    niveau,
    template,
    meta_title,
    meta_description,
    redirection: url,
    pratiques_structure,
    pratique
  });
}

/**
 * Page de niveau 3
 */
export function convertPageDetailFromApi(rawPageDetails: RawPageDetailType[]): PageDetailType {
  if (!rawPageDetails) {
    return nullPageDetail;
  }
  const { pageNodeState } = store.getState();

  let rawPageDetailFiltered = rawPageDetails.filter(
    rawPageDetail => rawPageDetail.parent === pageNodeState.pageNode.id
  );

  if (rawPageDetails[0].status === 'draft') {
    rawPageDetailFiltered = rawPageDetails;
  }

  if (rawPageDetailFiltered[0]) {
    const {
      title,
      content,
      slug,
      featured_media,
      rencontre_background,
      niveau,
      sidebar_content,
      template,
      meta_title,
      meta_description,
      redirect: { url }
    } = rawPageDetailFiltered[0];

    return mergeIntoDefault(nullPageDetail, {
      content: content.data,
      title: title ? title.raw : '',
      slug,
      featured_media,
      rencontre_background,
      niveau,
      sidebar_content: sidebar_content.data,
      template,
      meta_title,
      meta_description,
      redirection: url
    });
  }
  return nullPageDetail;
}

export function convertPageDetailCustomFromApi(rawPageDetails: RawPageDetailType[]): PageDetailType {
  if (!rawPageDetails) {
    return nullPageDetail;
  }

  if (rawPageDetails[0]) {
    const {
      title,
      content,
      slug,
      label,
      featured_media,
      niveau,
      sidebar_content,
      template,
      meta_title,
      meta_description,
      redirect: { url }
    } = rawPageDetails[0];

    return mergeIntoDefault(nullPageDetail, {
      content: content.data,
      title: title ? title.raw : '',
      slug,
      featured_media,
      niveau,
      label,
      sidebar_content: sidebar_content.data,
      template,
      meta_title,
      meta_description,
      redirection: url
    });
  }
  return nullPageDetail;
}

export function convertPageDetailPreviewFromApi(rawPageDetails: RawPageDetailPreviewType): PageDetailPreviewType {
  if (!rawPageDetails) {
    return nullPageDetailPreview;
  }

  const {
    id,
    title,
    title_parent,
    excerpt,
    content,
    slug,
    slug_parent,
    rencontre_background,
    featured_media,
    liste_enfants,
    niveau,
    link,
    sidebar_content,
    template,
    meta_title,
    meta_description,
    redirect: { url },
    pratiques_structure,
    pratique,
    news
  } = rawPageDetails;

  return mergeIntoDefault(nullPageDetailPreview, {
    id,
    content: content.data,
    title: title ? title.raw : '',
    title_parent,
    description: excerpt.raw,
    slug,
    slug_parent,
    link,
    featured_media,
    niveau,
    sidebar_content: sidebar_content.data,
    template,
    meta_title,
    meta_description,
    redirection: url,
    rencontre_background,
    liste_enfants,
    pratiques_structure,
    pratique,
    news
  });
}
