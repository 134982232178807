// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import { fetchRankingFromGraph } from 'api/graphApi/RankingApi';
import {
  FETCH_RANKING_START,
  rankingFetched,
  rankingFetchError
} from 'actions/team/rankingActions';

import type { FETCH_RANKING_START_ACTION } from 'actions/team/rankingActions';

export default function* rankingSaga(): GeneratorType {
  yield takeLatest(FETCH_RANKING_START, fetchRanking);
};

function* fetchRanking(action: FETCH_RANKING_START_ACTION): Saga<void> {
  try {
    const { competitions, clubID } = action.payload;

    if (competitions.length > 0 && clubID) {
      const ranking = yield call(fetchRankingFromGraph, competitions, clubID);
      yield put(rankingFetched(ranking));
    } else {
      yield put(rankingFetched([]));
    }
  } catch (e) {
    yield put(rankingFetchError());
  }
}
