// @flow
import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import TagArticleApi from 'api/tagarticle/TagArticleApi';
import { 
  FETCH_TAG_ARTICLE_START, 
  tagArticleFetched, 
  tagArticleFetchError,
} from 'actions/tagArticleActions';
import type { 
  FETCH_TAG_ARTICLE_START_ACTION, 
} from 'actions/tagArticleActions';

export default function(apiExecutor: ApiExecutorType) {
  const tagArticleApi = new TagArticleApi(apiExecutor);

  return function* tagArticleSaga(): GeneratorType {
    yield takeLatest(FETCH_TAG_ARTICLE_START, fetchTagArticle);
  };

  function* fetchTagArticle(action: FETCH_TAG_ARTICLE_START_ACTION): Saga<void> {
    try {
      const { page, per_page, slug } = action.payload;
      const { tagArticles, tagArticleNumber, tag } = yield call(
        tagArticleApi.fetchTagArticleByTag,
        page,
        per_page,
        slug
        );
      if (tagArticleNumber > 0) {
        yield put(tagArticleFetched(tagArticles, tagArticleNumber, tag));
      } else {
        yield put(tagArticleFetchError(tagArticles));
      }
    } catch (e) {
      yield put(tagArticleFetchError(e));
    }
  }
}
