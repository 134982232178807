// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import { FETCH_INSTAGRAM_START, instagramPostsFetched, instagramPostsFetchError } from 'actions/instagramActions';
import InstagramApi from 'api/social/InstagramApi';
import type { FETCH_INSTAGRAM_START_ACTION } from 'actions/instagramActions';

export default function(apiExecutor: ApiExecutorType) {
  const instagramApi = new InstagramApi(apiExecutor);

  return function* instagramSaga(): GeneratorType {
    yield takeLatest(FETCH_INSTAGRAM_START, fetchInstagramPosts);
  };

  function* fetchInstagramPosts(action: FETCH_INSTAGRAM_START_ACTION): Saga<void> {
    try {
      const posts = yield call(instagramApi.fetchTmpPosts);
      yield put(instagramPostsFetched(posts));
    } catch (e) {
      yield put(instagramPostsFetchError());
    }
  }
}
