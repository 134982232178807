// @flow

import { takeLatest, put, call, all, take, select } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import { ARTICLE_PER_PAGE } from 'constants/articleConstants';

import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import type { FETCH_TEAM_START_ACTION } from 'actions/team/teamActions';

import PageApi from 'api/page/PageApi';
import { fetchSeasonsFromGraph } from 'api/graphApi/SeasonsApi';

import { STATUS_SUCCESS } from 'constants/statusConstants';
import { FETCH_TEAM_START } from 'actions/team/teamActions';
import { FETCH_SETTINGS_SUCCESS } from 'actions/appActions';
import { fetchTeamResultStart } from 'actions/team/teamResultActions';
import { fetchTeamCalendarStart } from 'actions/team/teamCalendarActions';
import { fetchRanking } from 'actions/team/rankingActions';
import { seasonsFetched } from 'actions/seasonsActions';
import { fetchArticlesCategory } from 'actions/newsletterCategoryActions';
import { fetchPageNode } from 'actions/pageActions';
import { pageListFetched } from 'actions/pageActions';

export default function(apiExecutor: ApiExecutorType) {
  const pageApi = new PageApi(apiExecutor);

  return function* teamSaga(): GeneratorType {
    yield takeLatest(FETCH_TEAM_START, fetchTeam);
  };

  function* fetchTeam(action: FETCH_TEAM_START_ACTION): Saga<void> {
    try {
      const {
        payload: { slug, season }
      } = action;
      const pageList = yield call(pageApi.fetchPageList, slug);
      yield put(pageListFetched(pageList));

      const {
        settingsState: { status }
      } = yield select();
      if (status !== STATUS_SUCCESS) {
        yield take(FETCH_SETTINGS_SUCCESS);
      }
      const {
        settingsState: { club_id: clubID }
      } = yield select();

      const seasonsCall = yield call(fetchSeasonsFromGraph);
      yield put(seasonsFetched(seasonsCall));

      const lastSeason = parseInt(seasonsCall.lastSeason.id, 10);

      const {
        pageListState: {
          competitions_saisons,
          label: { slug: clubSlug },
          liste_enfants
        }
      } = yield select();

      const saison = competitions_saisons[lastSeason.toString()];
      // $FlowFixMe
      const competitions = saison?.competitions ?? [];

      const childrenNodeSlug = liste_enfants[0].object_slug;

      const ids = competitions.map(({ id }) => id.toString());
      yield all([
        put(fetchTeamResultStart(clubID, ids, season)),
        put(fetchTeamCalendarStart(clubID, ids)),
        put(
          fetchRanking(
            clubID,
            ids.map(id => parseInt(id, 10))
          )
        ),
        put(fetchArticlesCategory(1, ARTICLE_PER_PAGE, clubSlug)),
        put(fetchPageNode(childrenNodeSlug))
      ]);
    } catch (err) {
      console.log(err);
      // @TODO: Catch error
    }
  }
}
