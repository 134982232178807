// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import type { GeneratorType } from 'sagas/root';
import type { FETCH_TEAM_RESULT_START_ACTION } from 'actions/team/teamResultActions';

import {
  FETCH_TEAM_RESULT_START,
  fetchTeamResultSuccess,
  fetchTeamResultError,
} from 'actions/team/teamResultActions';

import { fetchResultFromGraph } from 'api/graphApi/ResultApi';

export default function () {
  return function* teamResultSaga(): GeneratorType {
    yield takeLatest(FETCH_TEAM_RESULT_START, fetchResultTeam);
  };

  function* fetchResultTeam(action: FETCH_TEAM_RESULT_START_ACTION): Saga<void> {
    try {
      const { payload: { clubID, competitions, season } } = action;
      const competitionsResponse = yield call(
        fetchResultFromGraph,
        competitions && competitions.map((c) => parseInt(c, 10)),
        parseInt(clubID, 10),
        season,
      );

      yield put(fetchTeamResultSuccess(
        competitionsResponse.competitions,
        competitionsResponse.stats,
        parseInt(season, 10)
      ));
    } catch (err) {
      fetchTeamResultError(err);
    }
  }
};
