// @flow
import type { NewsletterSubscriptionType, RawNewsletterSubscriptionType } from 'types/NewsletterSubscription';
import { mergeIntoDefault } from 'utils/objectUtils';
import { nullNewsletterSubscription } from 'types/NewsletterSubscription';

export function convertOptionsFromApi(
  rawNewsletterSubscription: RawNewsletterSubscriptionType
): NewsletterSubscriptionType {
  if (!rawNewsletterSubscription) {
    return nullNewsletterSubscription;
  }

  const { FieldName, Key, FieldOptions } = rawNewsletterSubscription;

  return mergeIntoDefault(nullNewsletterSubscription, {
    field: FieldName,
    key: Key,
    options: FieldOptions
  });
}
