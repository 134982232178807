// @flow

import type { LastResultType, RawRankingType } from 'types/Ranking';
import { nullStructure } from 'types/Result';
import { mergeIntoDefault } from 'utils/objectUtils';

export const getRanking = (rawRanking: RawRankingType[]) => {
  return rawRanking.reduce((ranks, rank) => {
    const { nom, embleme, lastResult: rawLastResults, id } = mergeIntoDefault(nullStructure,
      rank.equipe.regroupement
        ? { ...rank.equipe.regroupement, lastResult: rank.equipe.lastResult }
        : { ...rank.equipe.structure, lastResult: rank.equipe.lastResult });

    const allLastResults = rawLastResults.reduce((results, { local, visiteur }) => {
      if (local && visiteur) {
        results.push({ local, visiteur });
      }
      return results;
    }, []);

    ranks.push(
      {
      ...rank.classement,
      nom,
      id,
      embleme,
      position: rank.position,
      lastResult: allLastResults.splice(0, 5) || [],
      }
    );
    return ranks;
  }, []);
};

export const getRankingLayout = (rawRanks: RawRankingType[], referenceIndex: number) => {
  const reducedRanks = rawRanks.reduce((ranks, rank) => {
    const { nom, embleme, id, lastResult: rawLastResults } = mergeIntoDefault(nullStructure,
      rank.equipe.regroupement
        ? { ...rank.equipe.regroupement, lastResult: rank.equipe.lastResult }
        : { ...rank.equipe.structure, lastResult: rank.equipe.lastResult });
    const allLastResults = rawLastResults.reduce((results, { local, visiteur }) => {
      if (local && visiteur) {
        results.push({ local, visiteur });
      }
      return results;
    }, []);
    ranks.push(
      {
      ...rank.classement,
      nom,
      id,
      embleme,
      position: rank.position,
      lastResult: allLastResults.splice(0, 5) || [],
      }
    );
    return ranks;
  }, []);

  if (referenceIndex === 0 || referenceIndex === 1) {
    const ranking = (): any[] => reducedRanks.slice(0, 5);
    return ranking();
  }

  if (referenceIndex === rawRanks.length - 2) {
    const ranking = (): any[] => reducedRanks.slice(referenceIndex - 3);
    return ranking();
  }

  if (referenceIndex === rawRanks.length - 1) {
    const ranking = (): any[] => reducedRanks.slice(referenceIndex - 4);
    return ranking();
  }

  const ranking = (): any[] => reducedRanks.slice(referenceIndex - 2, referenceIndex + 3);
  return ranking();
};

export const doesClubHasWonMatch = ({ local, visiteur, id } : LastResultType & { id: string }) => {
  if (local.structure && parseInt(local.structure.id, 10) === parseInt(id, 10)) {
    return local.pointsTerrains > visiteur.pointsTerrains;
  }

  if (visiteur.structure && parseInt(visiteur.structure.id, 10) === parseInt(id, 10)) {
    return visiteur.pointsTerrains > local.pointsTerrains;
  }

  if (local.regroupement && parseInt(local.regroupement.id, 10) === parseInt(id, 10)) {
    return local.pointsTerrains > visiteur.pointsTerrains;
  }

  if (visiteur.regroupement && parseInt(visiteur.regroupement.id, 10) === parseInt(id, 10)) {
    return visiteur.pointsTerrains > local.pointsTerrains;
  }
  return false;
};

export const getCompetitionTag = (competition: { [key: string]: string }) => {
  if (!competition) {
    return '';
  }

  const { nomFamille, classeAgeCode, sexeCode } = competition;

  return (
    `${nomFamille || ''}${classeAgeCode || ''}${sexeCode || ''}`
  );
};
