// @flow

import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS, STATUS_DEFAULT } from 'constants/statusConstants';
import { FETCH_TAGS_START, FETCH_TAGS_SUCCESS, FETCH_TAGS_FAILURE } from 'actions/tagsActions';

import type { TagType } from 'types/Tag';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  tags: Array<TagType>,
  status: Status
};

const initialState: State = {
  tags: [],
  status: STATUS_DEFAULT
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_TAGS_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_TAGS_SUCCESS:
      return {
        ...state,
        tags: action.payload.tags,
        status: STATUS_SUCCESS
      };
    case FETCH_TAGS_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
