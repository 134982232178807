// @flow

export type TeamStatsType = {
  teamFacts: {
    joueurs: ?number,
    match_joues: ?number,
    points_marques: ?number,
    essais: ?number,
    drops: ?number,
  },
  averageStats: {
    points_marques: ?number,
    essais: ?number,
    drops: ?number,
    cartons_jaunes: ?number,
    cartons_rouge: ?number,
  },
  bonus: {
    offensif: ?number,
    defensif: ?number,
  },
  joueursId: ?string[],
}

export const nullTeamStats: TeamStatsType = {
  teamFacts: {
    joueurs: null,
    match_joues: null,
    points_marques: null,
    essais: null,
    drops: null,
  },
  averageStats: {
    points_marques: null,
    essais: null,
    drops: null,
    cartons_jaunes: null,
    cartons_rouge: null,
  },
  bonus: {
    offensif: null,
    defensif: null,
  },
  joueursId: null,
};
