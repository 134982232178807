// @flow

import React, { PureComponent } from 'react';

import { MEDIA_UPLOADING_LIMIT, VIDEO_FORMAT_WHITELIST } from 'constants/app';

import { MEDIA_UPLOADING_MAX_SIZE as MAX_SIZE } from 'constants/app';

type Props = {
  onFileChange: (files: { name: string, base64: string }[]) => void,
  accept: ?string,
  buttonText: ?string
}

type State = {
  files: { name: string, base64: string }[],
}

export default class MediaUploader extends PureComponent<Props, State> {
  state = {
    files: [],
  };

  recordFileInState = (fileName: string, base64: string) => {
    const { onFileChange } = this.props;

    const file = {
      name: fileName,
      base64,
    };

    this.setState(({ files: prevFiles }) => {
      const files = [
          ...prevFiles,
          file,
        ];
        return { files };
      },
      () => {
        const { files } = this.state;
        onFileChange(files);
      }
    );
  };

  handleUpload = (e: SyntheticInputEvent<EventTarget>) => {
    const reader = new FileReader();

    if (e.target.files.length === 1) {
      const rawFile = e.target.files[0];

      if (VIDEO_FORMAT_WHITELIST.includes(rawFile.type)) {
        reader.readAsDataURL(rawFile);

        reader.onload = () => {
          const { result } = reader;

          if (result) {
            this.recordFileInState(rawFile.name, result.toString());
          }
        };
      } else {
        reader.readAsDataURL(rawFile);

        reader.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          const image = new Image();
          image.src = reader.result.toString();
          image.onload = () => {
            const { width, height } = image;
            let base64 = image.src;
            if (width > MAX_SIZE) {
              const ratio = parseInt(width, 10) / parseInt(height, 10);
              canvas.width = MAX_SIZE;
              canvas.height = MAX_SIZE / ratio;
              ctx.drawImage(image, 0, 0, width, height, 0, 0, canvas.width, canvas.height);
              const canvasData = canvas.toDataURL();
              if (canvasData.length < base64.length) {
                base64 = canvasData;
              }
            }  else if (height > MAX_SIZE) {
              const ratio = parseInt(width, 10) / parseInt(height, 10);
              canvas.height = MAX_SIZE;
              canvas.width = MAX_SIZE*ratio;
              ctx.drawImage(image, 0, 0, width, height, 0, 0, canvas.width, canvas.height);
              const canvasData = canvas.toDataURL();
              if (canvasData.length < base64.length) {
                base64 = canvasData;
              }
            }
            this.recordFileInState(rawFile.name, base64);
          };

          image.onerror = (error: any) => {
            alert("Votre image ou video n'est pas au bon format !");
          };
        };
      };
    }
  };

  handleRemoveFile = (e: SyntheticInputEvent<EventTarget>) => {
    const { onFileChange } = this.props;
    const { files: filesInState } = this.state;

    const targetName =  e.target.dataset.file;
    const files = filesInState.filter(({ name }) => targetName !== name);

    this.setState({ files }, () => onFileChange(files));
  };

  renderFilesUploaded = (): React$Element<any>[] => {
    const { files } = this.state;
    const { accept } = this.props;
    return files.map(({ name }, i): React$Element<'div'> => (
      <div key={i} className="input--file has-file mb-3">
        <label htmlFor="oihdd929">
          <i className="icon icon-valid" />
            {`${name.substr(0, 22)}...`}
        </label>
        <input id="oihdd929" name="inputFile" type="file" accept={ accept } />
        <i
          role='button'
          data-file={name}
          tabIndex={0}
          className="icon icon-delete"
          onClick={this.handleRemoveFile} />
      </div>
    ));
  };

  render() {
    const { files } = this.state;
    const { accept, buttonText } = this.props;
    const maxCapacityReached = files.length === 3;

    const infoWording = maxCapacityReached
      ? 'Vous avez chargé 3 fichiers'
      : `Encore ${MEDIA_UPLOADING_LIMIT - files.length} fichiers max`;

    const buttonStyle = (maxCapacityReached
      && {
        pointerEvents: 'none',
        background: '#FAFAFA',
        color: '#ccc',
        boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.12)',
      })
      || {};

    return (
      <>
        {this.renderFilesUploaded()}
        <div className='mb-3'>
          <div
            className='input--file input--file-club'
            style={buttonStyle}>
            <label htmlFor='86jgpiz'>{buttonText || 'Sélectionner un nouveau fichier'}</label>
            <input
              id='86jgpiz'
              name='inputFile'
              type='file'
              accept={ accept }
              onChange={this.handleUpload}
              disabled={maxCapacityReached}/>
          </div>
          <p className='ft-center ft-black-secondary mt-1'>
            {infoWording}
          </p>
        </div>
      </>
    );
  }
}
