// @flow

import React from 'react';

type Props = { className?: string };

export default function DidomiCookie({ className }: Props) {
  let cn = 'accept-cookie-container';
  if (className) {
    cn += ` ${className}`;
  }

  const handleDidomiChoices = e => {
    e.preventDefault();
    if (window && window.Didomi) {
      window.Didomi.preferences.show();
    }
  };

  return (
    <div className={cn}>
      <div onClick={e => handleDidomiChoices(e)}>Consentement cookies</div>
    </div>
  );
}
