// @flow

import type { MenuElementType } from 'types/MenuElement';
import type { ParticipateType } from 'types/Participate';

export const FETCH_MENU_START = 'FETCH_MENU_START';
export const FETCH_MENU_SUCCESS = 'FETCH_MENU_SUCCESS';
export const FETCH_MENU_FAILURE = 'FETCH_MENU_FAILURE';

export type FETCH_MENU_START_ACTION = {
  type: 'FETCH_MENU_START'
};

export type FETCH_MENU_FAILURE_ACTION = {
  type: 'FETCH_MENU_FAILURE'
};

export type FETCH_MENU_SUCCESS_ACTION = {
  type: 'FETCH_MENU_SUCCESS',
  payload: {
    topBar: MenuElementType[],
    header: MenuElementType[],
    footer: MenuElementType[],
    participate: ParticipateType[],
    bottomNav: MenuElementType[],
  }
};

export type Action = FETCH_MENU_START_ACTION | FETCH_MENU_FAILURE_ACTION | FETCH_MENU_SUCCESS_ACTION;

export function fetchMenu(): FETCH_MENU_START_ACTION {
  return {
    type: FETCH_MENU_START
  };
}

export function menuFetched(
  topBar: MenuElementType[],
  header: MenuElementType[],
  footer: MenuElementType[],
  participate: ParticipateType[],
  bottomNav: MenuElementType[],
): FETCH_MENU_SUCCESS_ACTION {
  return {
    type: FETCH_MENU_SUCCESS,
    payload: { topBar, header, footer, participate, bottomNav }
  };
}

export function menuFetchError(): FETCH_MENU_FAILURE_ACTION {
  return {
    type: FETCH_MENU_FAILURE
  };
}
