// @flow

import {
  FETCH_ALBUM_START,
  FETCH_ALBUM_SUCCESS,
  FETCH_ALBUM_FAILURE,
  FETCH_ALBUMS_START,
  FETCH_ALBUMS_SUCCESS,
  FETCH_ALBUMS_FAILURE
} from 'actions/albumActions';

import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS, STATUS_DEFAULT } from 'constants/statusConstants';

import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';
import { nullAlbum, type AlbumType } from 'types/Albums';

export type State = {
  albumStatus: Status,
  albumsStatus: Status,
  album: AlbumType,
  albums: AlbumType[]
};

const initialState: State = {
  albumStatus: STATUS_DEFAULT,
  albumsStatus: STATUS_DEFAULT,
  album: nullAlbum,
  albums: []
};

export default (state: State = initialState, action: ActionType): State => {
  switch (action.type) {
    case FETCH_ALBUM_START:
      return {
        ...state,
        albumStatus: STATUS_LOADING
      };
    case FETCH_ALBUM_SUCCESS:
      const { featured_media, ...payload } = action.payload.album;

      return {
        ...state,
        albumStatus: STATUS_SUCCESS,
        album: { ...payload, featured_media: { src: featured_media.src || '', sizes: featured_media.sizes || {} } }
      };
    case FETCH_ALBUM_FAILURE:
      return {
        ...state,
        albumStatus: STATUS_FAILURE
      };
    case FETCH_ALBUMS_START:
      return {
        ...state,
        albumsStatus: STATUS_LOADING
      };
    case FETCH_ALBUMS_SUCCESS:
      const albums = action.payload.albums.map(({ featured_media, ...item }) => ({
        ...item,
        featured_media: { src: featured_media.src || '', sizes: featured_media.sizes || {} }
      }));

      return {
        ...state,
        albumsStatus: STATUS_SUCCESS,
        albums
      };
    case FETCH_ALBUMS_FAILURE:
      return {
        ...state,
        albumsStatus: STATUS_FAILURE
      };
    default:
      return state;
  }
};
