// @flow

export type SEOMetaType = {
  meta_title: string,
  meta_description: string,
};

export const nullSeoMeta = {
  meta_title: '',
  meta_description: '',
};
