// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import {
  convertPageListFromApi,
  convertPageNodeFromApi,
  convertPageDetailFromApi,
  convertPageDetailCustomFromApi,
  convertPageDetailPreviewFromApi
} from 'services/Page';
import { FORM_CONTACT_TOKEN } from 'constants/api';

function PageApi(executor: ApiExecutorType) {
  return {
    fetchPageList,
    fetchPageNode,
    fetchPageDetail,
    fetchPageDetailCustom,
    fetchPagePreviewById,
  };

  function fetchPageList(slug: string) {
    return executor.get(`/ffr/v1/pages?slug=${slug}`)
                    .then(response => convertPageListFromApi(response));
  }

  function fetchPageNode(slug: string) {
    return executor.get(`/ffr/v1/pages?slug=${slug}`)
                    .then(response => convertPageNodeFromApi(response));
  }

  function fetchPageDetail(slug: string) {
    return executor.get(`/ffr/v1/pages?slug=${slug}`)
                    .then(response => convertPageDetailFromApi(response));
  }

  function fetchPageDetailCustom(slug: string) {
    return executor.get(`/ffr/v1/pages?slug=${slug}`)
                    .then(response => convertPageDetailCustomFromApi(response));
  }

  function fetchPagePreviewById(id: number) {
    return executor.get(`/ffr/v1/pages/${id}`, {
        withCredentials: false,
        headers: {
          Authorization: `Basic ${FORM_CONTACT_TOKEN}`
        }
      }).then(response => convertPageDetailPreviewFromApi(response));
  }
}

export default PageApi;
