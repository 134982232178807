// @flow
import React from 'react';
import { getPublicAssets } from 'utils/assetsUtils';

type Props = {
  isWhite?: boolean,
  style?: Object,
};

const Loading = (props?: Props) => {
  let link;
  const style = {
    height:'100vh',
    display:'flex',
    backgroundColor: 'transparent',
  };
  if (props && props.isWhite === true) {
    link = '/img/club_loader_white.gif';
  } else {
    link = '/img/club_loader.gif';
  }
// @TODO : See if we need a classname "img-blue" later
  return (
    <div className='' style={props && props.style ? props.style : style}>
      <img src={getPublicAssets(link)} alt={'Chargement ...'} style={{ margin:'auto' }} />
    </div>
  );
};

export default Loading;
