// @flow
import TopBar from 'components/header/TopBar';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import type { DispatchType, StateType } from 'types/Actions';
import type { DispatchProps } from 'components/header/TopBar';
import { fetchArticlesBillboard } from 'actions/newsletterActions';

export default withRouter(connect(
  (state: StateType, ...ownProps: any) => ({
    connectionInProgress: state.userState.connectionInProgress,
    keycloakData: state.userState.keycloakData,
    structure_parente: state.settingsState.structure_parente,
    topBarElements: state.menuState.topBar,
    tickerElements: state.settingsState.top_news,
  }), (dispatch: DispatchType): DispatchProps => ({
    fetchArticlesBillboard: () => dispatch(fetchArticlesBillboard())
  })
)(TopBar));
 