// @flow
export function convertObjectToQueryString(query?: Object): string {
  let queryString: string = ``;
  if (query) {
    queryString += `?`;
    queryString += Object.keys(query)
      .filter(filterKey => (
        query &&
        query.hasOwnProperty(filterKey) &&
        (query[filterKey].length > 0 || typeof query[filterKey] === 'number')
      ))
      .map(key => {
        const value = (query && query.hasOwnProperty(key) && query[key]) || '';

        return `${key}=${value}`;
      })
      .join('&');
  }
  return queryString;
}

export function convertQueryStringToContactObject(queryString: string, initialObject: Object): Object {
  let searchQS = queryString.substring(1);
  searchQS = searchQS.split('&');

  return searchQS.reduce((result, item) => {
    const indexOfEqual = item.indexOf('=');
    const key = item.slice(0, indexOfEqual);
    const value = item.substring(indexOfEqual + 1).split(',')
      .map((valueItem) => (
        isNaN(valueItem) ? decodeURI(valueItem) : parseInt(valueItem, 10)
      ));

    return {
      ...result,
      ...{[key]: value},
    };
  }, initialObject);
}

export function convertQueryStringToTeam(queryString: string): Object {
  let searchQS = queryString.substring(1);
  searchQS = searchQS.split('&');

  return searchQS.reduce((result, item) => {
    const indexOfEqual = item.indexOf('=');
    const key = item.slice(0, indexOfEqual);
    const value = item.substring(indexOfEqual + 1).split(',')
      .map((valueItem) => (
        isNaN(valueItem) ? valueItem : parseInt(valueItem, 10)
      ));

    return {
      ...result,
      ...{[key]: value},
    };
  }, {
    equipe: '',
    season: '',
    poste: [],
    competitions: []
  });
}

export function convertQueryStringToEvent(queryString: string): Object {
  let searchQS = queryString.substring(1);
  searchQS = searchQS.split('&');

  return searchQS.reduce((result, item) => {
    const indexOfEqual = item.indexOf('=');
    const key = item.slice(0, indexOfEqual);
    const value = item.substring(indexOfEqual + 1).split(',')
      .map((valueItem) => (
        isNaN(valueItem) ? valueItem : parseInt(valueItem, 10)
      ));

    return {
      ...result,
      ...{[key]: value},
    };
  }, {
    equipe: '',
    season: ''
  });
}

export function convertQueryStringToCompetitions(queryString: string): Object {
  let searchQS = queryString.substring(1);
  searchQS = searchQS.split('&');

  return searchQS.reduce((result, item) => {
    const indexOfEqual = item.indexOf('=');
    const key = item.slice(0, indexOfEqual);
    let value = item.substring(indexOfEqual + 1).split(',')
      .map((valueItem) => (
        isNaN(valueItem) ? valueItem : parseInt(valueItem, 10)
      ));
    if ((key === 'season' || key === 'end_date') && value.length === 1) {
      value = value[0];
    }
    if (key === 'saison') {
      return {
        ...result
      };
    }
    return {
      ...result,
      ...{[key]: value},
    };
  }, {
    equipe: '',
    season: '',
  });
}

export function getGGMapsLink(coordinates: ?number[], address: string): string {
  if (coordinates) {
    return `https://maps.google.com/?ll=${coordinates.join(',')}`;
  }

  return `https://maps.google.com/?q=${address}`;
}

export function getFileNameFromPath(path: string): string {
  return path.replace(/^.*[\\/]/, '');
}
