// @flow
import React from 'react';
import { handleTagEvent } from 'utils/tagManagerUtils';
import type { liensRsType } from 'types/Settings';
import NewsletterSubscriberContainer from 'containers/footer/NewsletterSubscriberContainer';

export type StateProps = {
  titleClub: string,
  liens_rs: liensRsType
};

const FooterTop = (props: StateProps) => {
  const {
    liens_rs: {
      fb_lien,
      twitter_lien,
      instagram_lien,
      youtube_lien,
      flickr_lien,
      linkedin_lien,
      twitch_lien,
      snapchat_lien,
      tik_tok_lien
    },
    titleClub
  } = props;
  if (
    fb_lien === '' &&
    twitter_lien === '' &&
    instagram_lien === '' &&
    youtube_lien === '' &&
    flickr_lien === '' &&
    linkedin_lien === '' &&
    twitch_lien === '' &&
    snapchat_lien === '' &&
    tik_tok_lien === ''
  ) {
    return null;
  }
  return (
    <div className="container footer__top">
      <div className="row">
        <div className="col col-lg-3">
          <h2 className="mb-1 ft-h5">Nous suivre</h2>
          <div className="mt-3 mb-5">
            {fb_lien && (
              <a
                className="link-icon is-dark mb-2"
                href={fb_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Facebook de ${titleClub}`}
                onClick={handleTagEvent('footer', 'clic_icone_sociale', 'facebook')}
              >
                <i className="icon icon-facebook" />
              </a>
            )}
            {twitter_lien && (
              <a
                className="link-icon is-dark mb-2"
                href={twitter_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Twitter de ${titleClub}`}
                onClick={handleTagEvent('footer', 'clic_icone_sociale', 'twitter')}
              >
                <i className="icon icon-twitter" />
              </a>
            )}
            {instagram_lien && (
              <a
                className="link-icon is-dark mb-2"
                href={instagram_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Instagram de ${titleClub}`}
                onClick={handleTagEvent('footer', 'clic_icone_sociale', 'instagram')}
              >
                <i className="icon icon-instagram" />
              </a>
            )}
            {youtube_lien && (
              <a
                className="link-icon is-dark mb-2"
                href={youtube_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Youtube de ${titleClub}`}
                onClick={handleTagEvent('footer', 'clic_icone_sociale', 'youtube')}
              >
                <i className="icon icon-youtube" />
              </a>
            )}
            {flickr_lien && (
              <a
                className="link-icon is-dark mb-2"
                href={flickr_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Flickr de ${titleClub}`}
                onClick={handleTagEvent('footer', 'suivre reseau social', 'flickr')}
              >
                <i className="icon icon-flickr" />
              </a>
            )}
            {linkedin_lien && (
              <a
                className="link-icon is-dark mb-2"
                href={linkedin_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Linkedin de ${titleClub}`}
                onClick={handleTagEvent('footer', 'suivre reseau social', 'linkedin')}
              >
                <i className="icon icon-linkedin" />
              </a>
            )}
            {twitch_lien !== '' && (
              <a
                className="link-icon is-dark mb-2"
                href={twitch_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Twitch de ${titleClub}`}
                onClick={handleTagEvent('social wall', 'suivre reseau social', 'twitch')}
              >
                <i className="icon icon-twitch" />
              </a>
            )}
            {snapchat_lien !== '' && (
              <a
                className="link-icon is-dark mb-2"
                href={snapchat_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Snapchat de ${titleClub}`}
                onClick={handleTagEvent('social wall', 'suivre reseau social', 'snapchat')}
              >
                <i className="icon icon-snapchat" />
              </a>
            )}
            {tik_tok_lien !== '' && (
              <a
                className="link-icon is-dark mb-2"
                href={tik_tok_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page TikTok de ${titleClub}`}
                onClick={handleTagEvent('social wall', 'suivre reseau social', 'tiktok')}
              >
                <i className="icon icon-tik-tok" />
              </a>
            )}
          </div>
        </div>
        <NewsletterSubscriberContainer />
      </div>
      <hr />
    </div>
  );
};

export default FooterTop;
