// @flow

import React, { PureComponent } from 'react';

import TextInput from 'components/fragments/input/TextInput';
import Button from 'components/fragments/button/Button';
import type { DisplayModalFunctionType } from 'types/Modal';
import { validateEmail } from 'utils/validatorUtils';

export type DispatchProps = {
  displayModal: DisplayModalFunctionType
};

type Props = {
  title: string
} & DispatchProps;

type State = {
  email: string
};

export default class MediaSubmissionFormEmail extends PureComponent<Props, State> {
  state = {
    email: ''
  };

  handleMailChange = (email: string) => {
    this.setState({ email });
  };

  handleSubmitMail = () => {
    const { displayModal, title } = this.props;
    const { email } = this.state;

    if (email && validateEmail(email)) {
      displayModal('SHARE_CONTENT_FORM', { email, title }, true);
    }
  };

  render() {
    const { email } = this.state;

    return (
      <div>
        <p>Renseignez une adresse pour continuer</p>
        <TextInput
          id="test"
          hasError={email.length > 0 && !validateEmail(email)}
          errorMessage={email === '' ? 'Ce champ est requis' : "Cet e-mail n'est pas valide"}
          onChange={this.handleMailChange}
          placeholder="Adresse email"
        />
        <Button label="Continuer" onClick={this.handleSubmitMail} isDisabled={!validateEmail(email)} isFull />
      </div>
    );
  }
}
