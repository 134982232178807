// @flow
import { omit } from 'lodash';

import type { ApolloResponseType } from 'types/ApolloType';
import { nullTeamStats } from 'types/TeamStats';
import { nullStructure, type StructureType } from 'types/Result';

import { mergeIntoDefault } from 'utils/objectUtils';

export const convertStatsFromGraph = (rawGraphResponse: ApolloResponseType, clubID: number) => {
  if (!rawGraphResponse || !rawGraphResponse.data) {
    return nullTeamStats;
  }

  try {
    const { data: { Rencontres } } = rawGraphResponse;

    if (Rencontres.length === 0) {
      return {
        teamFacts: null,
        averageStats: null,
        bonus: {
          offensif: null,
          defensif: null,
        },
      };
    }
    let players = [];
    const allClubMatches = Rencontres.reduce((acc, {
      RencontreResultatVisiteuse,
      RencontreResultatLocale,
      CompetitionEquipeLocale: localStructureTmp,
      EquipeLocal,
      CompetitionEquipeVisiteuse: exterieurStructureTmp,
      EquipeVisiteur
    }) => {

      const localStructure: StructureType = mergeIntoDefault(nullStructure, localStructureTmp.Structure
        ? { ...localStructureTmp.Structure, isRegroupement: false, nom: localStructureTmp.nom }
        : { ...localStructureTmp.Regroupement, isRegroupement: true, nom: localStructureTmp.nom });

      const exterieurStructure: StructureType = mergeIntoDefault(nullStructure, exterieurStructureTmp.Structure
        ? { ...exterieurStructureTmp.Structure, isRegroupement: false, nom: exterieurStructureTmp.nom }
        : { ...exterieurStructureTmp.Regroupement, isRegroupement: true, nom: exterieurStructureTmp.nom });

      const clubPlaysOutside = exterieurStructure && exterieurStructure.isRegroupement
        ? exterieurStructureTmp.Regroupement.StructuresItem.find(({ id }) => parseInt(id, 10) === clubID)
        : parseInt(exterieurStructure.id, 10) === clubID;

      const clubPlaysAtHome = localStructure && localStructure.isRegroupement
        ? localStructureTmp.Regroupement.StructuresItem.find(({ id }) => parseInt(id, 10) === clubID)
        : parseInt(localStructure.id, 10) === clubID;
      
      if (clubPlaysOutside) {
        if (EquipeVisiteur && EquipeVisiteur.length > 0) {
          const playerMatch = EquipeVisiteur.map(({Personne: { id }}) => {
            return id;
          });
          players = players.concat(playerMatch);
        }
        acc.push(omit(RencontreResultatVisiteuse, 'structure'));
      }

      if (clubPlaysAtHome) {
        if (EquipeLocal && EquipeLocal.length > 0) {
          const playerMatch = EquipeLocal.map(({Personne: { id }}) => {
            return id;
          });
          players = players.concat(playerMatch);
        }
        acc.push(omit(RencontreResultatLocale, 'structure'));
      }

      return acc;
    }, []);

    const reducedStats = allClubMatches.reduce((acc, match) => {
      acc = {
        bonusDefensif: (acc.bonusDefensif || 0) + (match.bonusDefensif || 0),
        bonusOffensif: (acc.bonusOffensif || 0) + (match.bonusOffensif || 0),
        cartonsRouges: (acc.cartonsRouges || 0) + (match.cartonsRouges || 0),
        dropGoals: (acc.dropGoals || 0) + (match.dropGoals || 0),
        essais: (acc.essais || 0) + (match.essais || 0),
        pointsDeMarque: (acc.pointsDeMarque || 0) + (match.pointsDeMarque || 0),
      };

      return acc;
    }, {});

    players = [...new Set(players)];
    const { Competition: { ClasseAge: { Sexe: { code } } } } = Rencontres[0];
    let dataTeamFacts = {};
    if (code === 'F') {
      dataTeamFacts = {
        joueuses: players.length,
        match_joues: allClubMatches.length,
        points_marques: reducedStats.pointsDeMarque,
        essais: reducedStats.essais,
        drops: reducedStats.dropGoals,
      };
    } else {
      dataTeamFacts = {
        joueurs: players.length,
        match_joues: allClubMatches.length,
        points_marques: reducedStats.pointsDeMarque,
        essais: reducedStats.essais,
        drops: reducedStats.dropGoals,
      };
    }
    const formatedStats = {
      teamFacts: dataTeamFacts,
      averageStats: {
        points_marques: parseFloat((reducedStats.pointsDeMarque / allClubMatches.length).toFixed(1)) || 0,
        essais: parseFloat((reducedStats.essais / allClubMatches.length).toFixed(1)) || 0,
        drops: parseFloat((reducedStats.dropGoals / allClubMatches.length).toFixed(1)) || 0,
        cartons_jaunes: parseFloat((reducedStats.cartonsJaunes / allClubMatches.length).toFixed(1)) || 0, //@TODO => not in graph datas
        cartons_rouge: parseFloat((reducedStats.cartonsRouges / allClubMatches.length).toFixed(1)) || 0,
      },
      bonus: {
        offensif: reducedStats.bonusOffensif || 0,
        defensif: reducedStats.bonusDefensif || 0,
      },
      joueursId: players,
    };
    return mergeIntoDefault(nullTeamStats, formatedStats);
  } catch (error) {
    return nullTeamStats;
  }
};
