// @flow
import React, { PureComponent } from 'react';
import type { Element } from 'react';
import { Link } from 'react-router-dom';

import { dynamicClassName } from 'utils/dynamicClassName';

import type { BreadcrumbType } from 'types/Settings';

type Props = {
  elements: Array<string | BreadcrumbType>,
  isDark?: boolean,
  breadcrumbStyle?: string
};

class Breadcrumb extends PureComponent<Props> {
  props: Props;

  static defaultProps = {
    elements: []
  };

  renderElements = (elements: Array<string | { name: string, to: string }>): Element<'li'>[] => {
    return elements.map((el, index) => <li key={index}>{this.renderElement(el)}</li>);
  };

  renderElement = (element: string | { name: string, to: string }) => {
    return typeof element === 'string' ? (
      element
    ) : (
      <Link
        to={{
          pathname: element.to
        }}
        title={`Se rendre à la page ${element.name}`}
        role="button"
        tabIndex={0}
      >
        {element.name}
      </Link>
    );
  };

  renderRichSnippet = (): string[] => {
    const { elements } = this.props;
    return elements.reduce((acc ,element, i) => {
      if (typeof element !== 'string') {
        acc.push(`{
          "@type": "ListItem",
          "position": ${i + 1},
          "name": "${element.name}",
          "item": "https://${window.location.host}${element.to}"
        }`);
      }
      return acc;
    }, []);
  };

  render() {
    const { breadcrumbStyle, isDark, elements } = this.props;

    const classNamesBreadcrumb = dynamicClassName('breadcrumb ft-h6 ft-up');
    const richSnippets = `{
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [${this.renderRichSnippet().join(',')}]
    }`;

    breadcrumbStyle && classNamesBreadcrumb.add(breadcrumbStyle);
    isDark && classNamesBreadcrumb.add('is-dark');

    return (
      <>
        <script type='application/ld+json'>
          {richSnippets}
        </script>
        <ol className={classNamesBreadcrumb.build()}>{this.renderElements(elements)}</ol>
      </>
    );
  }
}
export default Breadcrumb;
