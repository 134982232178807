// @flow
import type { RawThemeType, ThemeType, RawQuestionType, QuestionType } from 'types/Faq';
import { nullTheme, nullQuestion } from 'types/Faq';
import { mergeIntoDefault } from 'utils/objectUtils';

export function convertThemeFromApi(rawTheme: RawThemeType): ThemeType {
  if (!rawTheme) {
    return nullTheme;
  }

  const {
    id,
    description,
    name,
    slug,
    meta: {
      page_liee,
      contact_lie
    },
    page_liee_link
  } = rawTheme;

  return mergeIntoDefault(nullTheme, {
    id,
    description,
    name,
    slug,
    page_liee,
    contact_lie,
    page_liee_link
  });
}

export function convertQuestionFromApi(rawQuestion: RawQuestionType): QuestionType {
  if (!rawQuestion) {
    return nullQuestion;
  }

  const {
    id,
    slug,
    title,
    content,
    theme,
    label,
    meta_description,
    meta_title
  } = rawQuestion;

  return mergeIntoDefault(nullQuestion, {
    id,
    slug,
    title: title.raw,
    label,
    content: content.rendered,
    meta_description,
    meta_title,
    theme: theme[0],
  });
}
