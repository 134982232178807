// @flow

import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS, STATUS_DEFAULT } from 'constants/statusConstants';
import {
  FETCH_EVENTS_PREVIEW_START,
  FETCH_EVENTS_PREVIEW_SUCCESS,
  FETCH_EVENTS_PREVIEW_FAILURE
} from 'actions/eventsPreviewActions';

import type { EventPreviewType } from 'types/Event';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  events: Array<EventPreviewType>,
  status: Status
};

const initialState: State = {
  events: [],
  status: STATUS_DEFAULT
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_EVENTS_PREVIEW_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_EVENTS_PREVIEW_SUCCESS:
      return {
        ...state,
        events: action.payload.events,
        status: STATUS_SUCCESS
      };
    case FETCH_EVENTS_PREVIEW_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
