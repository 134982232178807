/// @flow
import React, { Fragment, PureComponent } from 'react';
import { dynamicClassName } from 'utils/dynamicClassName';
import ErrorMessage from 'components/fragments/ErrorMessage';

type Props = {
  id: string,
  placeholder: string,
  errorMessage?: string,
  hasError?: boolean,
  isDark?: boolean,
  isLight?: boolean,
  onChange?: (value: string) => void,
  onBlur?: () => void,
  onFocus?: () => void,
};

class TextInput extends PureComponent<Props> {
  props: Props;

  static defaultProps = {
    id: 'TextInput',
    onBlur: () => {},
    onFocus: () => {},
    placeholder: 'PlaceHolder'
  };

  handleInputChanged = (e: any) => {
    if (this.props.onChange) this.props.onChange(e.target.value);
  };

  render() {
    const { isDark, isLight, hasError, id, placeholder, onBlur, onFocus } = this.props;
    const classNames = dynamicClassName('input');
    isDark && classNames.add('is-dark');
    isLight && classNames.add('input--light');
    hasError && classNames.add('has-error');

    // const input =
    return (
      <Fragment>
        <div className={classNames.build()}>
          <input
            type='text'
            id={id}
            placeholder={placeholder}
            onBlur={() => onBlur && onBlur()}
            onFocus={() => onFocus && onFocus()}
            onChange={this.handleInputChanged}
            />
          <label htmlFor={id}>{placeholder}</label>
          {hasError && <i className="icon icon-info" />}
        </div>
        {hasError && this.props.errorMessage && <ErrorMessage message={this.props.errorMessage} />}
      </Fragment>
    );
  }
}

export default TextInput;
