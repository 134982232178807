// @flow

export const SEND_VPV = 'SEND_VPV';

export type SEND_VPV_ACTION = {
    type: 'SEND_VPV',
};

export type Action = SEND_VPV_ACTION;

export function sendVpv (): SEND_VPV_ACTION {
    return {
        type: SEND_VPV,
    };
};
