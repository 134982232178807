// @flow
import { connect } from 'react-redux';

import TeamRouter, {
  type DispatchProps,
  type StateProps
} from './../../TeamRoutes';

import { sendVpv } from 'actions/hitTagsActions';
import { fetchTeam } from 'actions/team/teamActions';

import type { DispatchType, StateType } from 'types/Actions';

const mapStateToProps = (state: StateType): StateProps => ({
  organization: state.settingsState.title,
  liste_enfants: state.pageListState.liste_enfants,
  competitions: state.pageListState.competitions,
  status: state.pageListState.status,
  compte_joueurs: state.pageListState.compte_joueurs,
  pratique: state.pageListState.pratique,
});

const mapDispatchToProps = (dispatch: DispatchType): DispatchProps => ({
  fetchTeam: (slug: string) => dispatch(fetchTeam(slug)),
  sendVpv: () => dispatch(sendVpv()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamRouter);
