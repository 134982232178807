// @flow
import React, { PureComponent } from 'react';
import Search from 'components/header/Search';
import { dynamicClassName } from 'utils/dynamicClassName';
import { Link } from 'react-router-dom';

type StateProps = {
  logo: string,
  clubName: string
};

type Props = {
  onToggleMenu: Function,
  isMenuOpen: boolean,
  headerOver: boolean,
  onCloseMenu: Function
} & StateProps;

type ComponentState = {
  hasOverlay: boolean
};

class HeaderMobile extends PureComponent<Props, ComponentState> {
  state: ComponentState = {
    hasOverlay: false
  };

  setOverlay = (hasOverlay: boolean) => {
    this.setState({ hasOverlay });
  };

  render() {
    const { onCloseMenu, isMenuOpen, logo, clubName } = this.props;
    const { hasOverlay } = this.state;
    const classNamesIconShowMenu = dynamicClassName('icon icon-menu js-showMenu');
    const classNamesOverlay = dynamicClassName('overlay');
    isMenuOpen && classNamesIconShowMenu.add('is-open');
    hasOverlay && classNamesOverlay.add('is-visible');

    return (
      <>
        <div className="header__mobile">
          <Link title="Se rendre sur la page d'accueil" to="/" onClick={onCloseMenu}>
            <img src={logo} alt="logo" />
          </Link>

          <div className="header__mobile__right">
            <Search mobile={true} onSetOverlay={this.setOverlay} onCloseMenu={onCloseMenu} clubName={clubName} />

            <i
              className={classNamesIconShowMenu.build()}
              role="button"
              tabIndex={0}
              onClick={this.props.onToggleMenu}
              aria-label={'Afficher le menu'}
            />
          </div>
        </div>
        <div className={classNamesOverlay.build()} onClick={onCloseMenu} role="button" tabIndex={0} aria-label={'Fermer ce menu'} />
      </>
    );
  }
}

export default HeaderMobile;
