// @flow

import { takeLatest, put, call, all, take } from 'redux-saga/effects';
import { store } from './../store';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import ArticleApi from 'api/article/ArticleApi';
import {
  FETCH_ARTICLES_START,
  FETCH_ARTICLES_BILLBOARD_START,
  FETCH_ARTICLES_CATEGORIES_START,
  FETCH_ARTICLES_STATIC_CATEGORIES_START,
  articlesFetched,
  articlesFetchError,
  articlesBillboardFetched,
  articlesBillboardFetchError,
  articlesCategoriesFetched,
  articlesCategoriesFetchError
} from 'actions/newsletterActions';

import { convertRencontreArticleCategory } from 'services/Rencontre';

import {FETCH_CATEGORIES_SUCCESS} from 'actions/categoriesActions';

export default function(apiExecutor: ApiExecutorType, ffrStaticApiExecutor: ApiExecutorType) {
  const articleApi = new ArticleApi(apiExecutor);
  const articleApiStatic = new ArticleApi(ffrStaticApiExecutor);

  return function* newsletterSaga(): GeneratorType {
    yield takeLatest(FETCH_ARTICLES_START, fetchArticles);
    yield takeLatest(FETCH_ARTICLES_BILLBOARD_START, fetchArticlesBillboard);
    yield takeLatest(FETCH_ARTICLES_CATEGORIES_START, fetchArticlesCategories);
    yield takeLatest(FETCH_ARTICLES_STATIC_CATEGORIES_START, fetchArticlesStaticCategories);
  };

  function* fetchArticles(action): Saga<void> {
    try {
      const { page, per_page } = action.payload;
      const article = yield call(articleApi.fetchArticlesByPage, page, per_page);
      yield put(articlesFetched(article));
    } catch (e) {
      yield put(articlesFetchError(e));
    }
  }

  function* fetchArticlesBillboard(action): Saga<void> {
    try {
      const article = yield call(articleApiStatic.fetchArticlesBillboard);
      yield put(articlesBillboardFetched(article));
    } catch (e) {
      yield put(articlesBillboardFetchError(e));
    }
  }

  function* fetchArticlesCategories(action): Saga<void> {
    try {
      const { page, per_page } = action.payload;
      const {categoriesState} = store.getState();
      let categories = [];
      if (categoriesState.categories.length === 0) {
        const success = yield take(FETCH_CATEGORIES_SUCCESS);
        categories = success.payload.categories;
      } else {
        categories = categoriesState.categories;
      }
      yield all(categories.map(category => {
        return call(articleApi.fetchArticleByCategory, page, per_page, category);
      }));
      yield put(articlesCategoriesFetched(categories));
    } catch (e) {
      yield put(articlesCategoriesFetchError(e));
    }
  }

  function* fetchArticlesStaticCategories(action): Saga<void> {
    try {
      const {categoriesState} = store.getState();
      let categories = [];

      if (categoriesState.categories.length === 0) {
        const success = yield take(FETCH_CATEGORIES_SUCCESS);
        categories = success.payload.categories;
      } else {
        categories = categoriesState.categories;
      }
      categories = yield call(articleApi.fetchTmpArticleStaticCategories);

      const allCategories = categories.map((category) => ({
        ...category,
        items: (
          category && category.items.map((item) => ({
          ...item,
          rencontre_details: item.rencontre_details
            ? convertRencontreArticleCategory(item.rencontre_details)
            : null
        }))
        ) || [],
      }));
      yield put(articlesCategoriesFetched(allCategories));
    } catch (e) {
      yield put(articlesCategoriesFetchError(e));
    }
  }
}
