// @flow
import type { Status } from 'types/Status';
import { STATUS_DEFAULT, STATUS_FAILURE, STATUS_LOADING, STATUS_SUCCESS } from 'constants/statusConstants';

export function validateEmail(email: string | null): boolean {
  if (email === null)
    return false;
  // eslint-disable-next-line
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function validatePhone(phone: string): boolean {
  const regex = /^[+]?[0-9]([(][0-9][)]|[0-9])+(\d)$/g;

  return regex.test(phone);
}

export const isLoadingStatus = (status: Status): boolean => (
  status === STATUS_DEFAULT || status === STATUS_LOADING
);

export const isReadyStatus = (status: Status): boolean => (
  status === STATUS_SUCCESS || status === STATUS_FAILURE
);
