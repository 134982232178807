// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';

import { convertPartnerFromApi } from 'services/Partner';

function PartnerApi(executor: ApiExecutorType) {
  return {
    fetchPartners,
    fetchTmpPartners,
  };

 function fetchPartners() {
    return executor.get(`partenaires.json`)
      .then(response => response.map(convertPartnerFromApi));
  }

 function fetchTmpPartners() {
    return executor.get(`/ffr/v1/partenaires_banniere/?orderby=menu_order&order=asc&per_page=100`)
      .then(response => response.map(convertPartnerFromApi));
  }
}

export default PartnerApi;
