// @flow

export type RawClassicType = {
  id: string,
  nom: string,
}

export type RawStructureType = RawClassicType & {
  code?: string,
  embleme: string,
}

export type RawCompetitionEquipeType = RawClassicType & {
  Structure: RawStructureType,
  Regroupement: RawStructureType,
  ordre: number,
};

export type RawCompetitionType = RawClassicType & {
  id: string,
  nomCourt: string,
  Famille: RawClassicType,
  ClasseAge: RawClassicType & {
    code: string,
    Sexe: RawClassicType & {
      code: string,
    },
  },
}

export type RawRencontreResultatType = {
  id: string,
  essais: number,
  essaisDePenalite: number,
  butsApresEssai: number,
  butsPenalite: number,
  dropGoals: number,
  matchEffectifIncomplet: boolean,
  forfait: boolean,
  forfaitGeneral: boolean,
  meiMotif: string,
  cartonsRouges: number,
  tirsAuBut: number,
  pointsTerrains: number,
  regulationPoitsTerrain: number,
  pointsDeMarque: number,
  bonusOffensif: number,
  bonusDefensif: number,
  goalAverage: number,
}

export type RawJourneeType = RawClassicType & {
  numero: number,
}

export type RawMatchType = {
  Competition: RawCompetitionType,
  CompetitionEquipeLocale: RawCompetitionEquipeType,
  CompetitionEquipeVisiteuse: RawCompetitionEquipeType,
  Etat: RawClassicType,
  Journee: RawJourneeType,
  RencontreResultatLocale: ?RawRencontreResultatType,
  RencontreResultatVisiteuse: ?RawRencontreResultatType,
  dateEffective: string,
  dateOfficielle: string,
  id: string,
  termine: ?boolean,
}

export const nullClassic: RawClassicType = {
  id: '',
  nom: '',
};
