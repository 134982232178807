// @flow
import type { RencontreActionType, RencontreActionContainerType } from 'types/Rencontre';

export const getIconClassName = (faitType: string): string => {
  switch (faitType) {
    case 'essai':
      return "icon icon-ball";
    case 'transformation':
      return "icon icon-cage";
    case 'penalite':
      return "icon icon-cage";
    case 'drop':
      return "icon icon-drop";
    case 'carton rouge':
      return "icon icon-red-card";
    case 'carton jaune':
      return "icon icon-yellow-card";
    case 'remplacement':
      return "icon-sub";
    default:
      return 'icon-sub';
  }
};

type ActionType = {
  minutes: number,
  period: number,
  player1: string,
  player2?: ?string,
  subType?: string,
  teamId: string,
  type: string,
};

export const getScoreByAction = (action: RencontreActionType): number => {
  switch (action.type) {
    case 'essai':
      if (action.sousType === 'De pénalité') {
        return 7;
      }

      return 5;
    case 'transformation':
      return 2;
    case 'penalite':
      return 3;
    case 'drop':
      return 3;
    case 'PENALTYTRY':
      return 7;
    default:
      return 0;
  }
};

export const extractHalf = (actions: RencontreActionContainerType[]) => {
  const { firstHalf, secondHalf } = actions.reduce((acc, current) => {
    const { localActions, visiteurActions } = current;

    const localFirst = localActions.filter((action) => action.periode === 1).map((action) => ({
      ...action,
      isLocal: true,
    }));
    const visitFirst = visiteurActions.filter((action) => action.periode === 1).map((action) => ({
      ...action,
      isLocal: false,
    }));

    const localSecond = localActions.filter((action) => action.periode === 2).map((action) => ({
      ...action,
      isLocal: true,
    }));
    const visitSecond = visiteurActions.filter((action) => action.periode === 2).map((action) => ({
      ...action,
      isLocal: false,
    }));

    acc.firstHalf.push(...localFirst, ...visitFirst);
    acc.secondHalf.push(...localSecond, ...visitSecond);

    return acc;
  }, { firstHalf: [], secondHalf: [] });

  const sortedFirstHalf = ():any[] => firstHalf.sort((a, b) => b.minutes - a.minutes);
  const sortedSecondHalf = ():any[] => secondHalf.sort((a, b) => b.minutes - a.minutes);

  return {
    firstHalf: sortedFirstHalf(),
    secondHalf: sortedSecondHalf(),
  };
};

export const getMidMatchScore = (
  actions: RencontreActionContainerType[]
): { scoreVis: number, scoreLoc: number } => {
  const { firstHalf: actionsFirst } = extractHalf(actions);

  let scoreLoc = 0;
  let scoreVis = 0;

  actionsFirst.forEach((action) => {
    const result = getScoreByAction(action);

    if (action.isLocal) {
      scoreLoc += result;
    } else {
      scoreVis += result;
    }
  });

  return { scoreVis, scoreLoc };
};

export const getMatchScore = (
  actions: RencontreActionContainerType[]
): { scoreVis: number, scoreLoc: number } => {
  const { firstHalf: actionsFirst, secondHalf: actionsSecond } = extractHalf(actions);

  let scoreLoc = 0;
  let scoreVis = 0;

  [...actionsFirst, ...actionsSecond].forEach((action) => {
    const result = getScoreByAction(action);

    if (action.isLocal) {
      scoreLoc += result;
    } else {
      scoreVis += result;
    }
  });

  return { scoreVis, scoreLoc };
};

export const getLastMinute = (actions: RencontreActionContainerType[]): number => {
  const { firstHalf: actionsFirst, secondHalf: actionsSecond } = extractHalf(actions);

  let lastMinute = 0;

  [...actionsFirst, ...actionsSecond].forEach(({ periode, minutes }) => {
    const minute = periode === 1 ? Math.min(minutes, 40) : minutes;

    lastMinute = minute > lastMinute ? minute : lastMinute;
  });

  return lastMinute;
};

export const formatedType = (rawType: string, rawSubType: string) => {
  let type = '';
  let subType = '';

  switch (rawType) {
    case 'TRY':
      type = 'essai';
      break;
    case 'REPLACEMENT':
      type = 'remplacement';
      break;
    case 'CARD':
      type = 'carton';
      break;
    case 'GOALAFTERTRY':
      type = 'transformation';
      break;
    case 'GOALPENALTY':
      type = 'penalite';
      break;
    case 'DROPGOAL':
      type = 'drop';
      break;
    case 'PENALTYTRY':
      type = 'essai';
      subType = 'De pénalité';
      break;
    case 'KICKOFF':
      type = 'debut_match';
      break;
    default:
      type = rawType;
  }

  if (rawSubType && rawSubType !== '' && subType === '') {
    switch (rawSubType) {
      case 'TACTICAL':
        subType = 'tactique';
        break;
      case 'INJURY':
        subType = 'sur blessure';
        break;
      case 'BLEED':
        subType = 'sur saignement';
        break;
      case 'YELLOW':
        type = 'carton jaune';
        break;
      case 'RED':
        type = 'carton rouge';
        break;
      case 'BLUE':
        type = 'carton bleu';
        break;
      case 'WHITE':
        type = 'carton white';
        break;
      default:
        subType = '';
    }
  }

  return { type, subType };
};

export const actionsMock: ActionType[] = [
   {
    "minutes": 3,
    "period": 1,
    "player1": "2",
    "player2": "17",
    "subType": "INJURY",
    "teamId": "668",
    "type": "REPLACEMENT"
   },
   {
    "minutes": 12,
    "period": 1,
    "player1": "13",
    "player2": null,
    "teamId": "668",
    "type": "TRY"
   },
   {
    "minutes": 21,
    "period": 1,
    "player1": "3",
    "player2": "16",
    "subType": "INJURY",
    "teamId": "20668",
    "type": "REPLACEMENT"
   },
   {
    "minutes": 22,
    "period": 1,
    "player1": "14",
    "teamId": "20668",
    "type": "GOALPENALTY"
   },
   {
    "minutes": 28,
    "period": 1,
    "player1": "14",
    "teamId": "20668",
    "type": "GOALPENALTY"
   },
   {
    "minutes": 37,
    "period": 1,
    "player1": "3",
    "player2": null,
    "teamId": "668",
    "type": "TRY"
   },
   {
    "minutes": 40,
    "period": 1,
    "player1": "12",
    "player2": "12",
    "teamId": "668",
    "type": "TRY"
   },
   {
    "minutes": 40,
    "period": 1,
    "player1": "12",
    "teamId": "668",
    "type": "GOALAFTERTRY"
   },
   {
    "minutes": 40,
    "period": 1,
    "player1": "21",
    "player2": null,
    "teamId": "20668",
    "type": "TRY"
   },
   {
    "minutes": 40,
    "period": 1,
    "player1": "14",
    "player2": "22",
    "subType": "TACTICAL",
    "teamId": "20668",
    "type": "REPLACEMENT"
   },
   {
    "minutes": 40,
    "period": 1,
    "player1": "1",
    "player2": "18",
    "subType": "TACTICAL",
    "teamId": "20668",
    "type": "REPLACEMENT"
   },
   {
    "minutes": 40,
    "period": 1,
    "player1": "1",
    "player2": "18",
    "subType": "TACTICAL",
    "teamId": "20668",
    "type": "REPLACEMENT"
   },
   {
    "minutes": 40,
    "period": 1,
    "player1": "11",
    "player2": "21",
    "subType": "TACTICAL",
    "teamId": "20668",
    "type": "REPLACEMENT"
   },
   {
    "minutes": 40,
    "period": 1,
    "player1": "9",
    "player2": "19",
    "subType": "TACTICAL",
    "teamId": "20668",
    "type": "REPLACEMENT"
   },
   {
    "minutes": 40,
    "period": 1,
    "player1": "12",
    "player2": "20",
    "subType": "TACTICAL",
    "teamId": "20668",
    "type": "REPLACEMENT"
   },
   {
    "minutes": 40,
    "period": 1,
    "player1": "8",
    "subType": "WHITE",
    "teamId": "20668",
    "type": "CARD"
   },
   {
    "minutes": 40,
    "period": 1,
    "player1": "9",
    "subType": "YELLOW",
    "teamId": "668",
    "type": "CARD"
   },
   {
    "minutes": 40,
    "period": 1,
    "player1": "15",
    "subType": "WHITE",
    "teamId": "668",
    "type": "CARD"
   },
   {
    "minutes": 40,
    "period": 1,
    "player1": "5",
    "player2": "10",
    "teamId": "20668",
    "type": "TRY"
   },
   {
    "minutes": 40,
    "period": 1,
    "player1": "10",
    "teamId": "20668",
    "type": "GOALAFTERTRY"
   }
  ];
