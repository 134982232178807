// @flow
import React from 'react';

type Props = {
  content: any,
  hideModal: () => void
};

function NewsletterSubscriptionFailedModalContent({ content, hideModal }: Props) {
  const text = content.wording || "Nous avons rencontré un problème lors de l'inscription";

  return (
    <div className="modal-content box newsletter-unsubscribe-done modal-content--stripe">
      <i className="icon icon-close js-closeModal" role="button" tabIndex={0} onClick={hideModal}></i>
      <div className="modal__wrapper">
        <h3 className="mb-2">{text}</h3>
        <div className="modal__body modal__body--medium narrow-2 mb-3">
          <div className="circle-icon circle-icon--alert"></div>
          <p>Merci de vérifier les informations envoyées !</p>
        </div>
      </div>
    </div>
  );
}

export default NewsletterSubscriptionFailedModalContent;
