// @flow
export type MenuElementType = {
  id: number,
  parent: number,
  order: number,
  type: string,
  type_name: string,
  type_slug: string,
  object_id: number,
  object_slug: string,
  slug_complet: string,
  label: string,
  url: string,
  title: string,
  target: string,
  description: string,
  items: MenuElementType[],
  classes: string
};

export type RawMenuResponse = Array<{
  id: number,
  name: string,
  slug: string,
  position: string,
  description: string,
  items: MenuElementType[],
  classes: string
}>;

export const nullMenuElement: MenuElementType = {
  id: 0,
  parent: 0,
  order: 0,
  type: '',
  type_name: '',
  type_slug: '',
  object_id: 0,
  object_slug: '',
  slug_complet: '',
  label: '',
  url: '',
  title: '',
  target: '',
  description: '',
  items: [],
  classes: ''
};
