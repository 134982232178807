// @flow

import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS, STATUS_DEFAULT } from 'constants/statusConstants';
import {
  SEND_MEDIA_START,
  SEND_MEDIA_SUCCESS,
  SEND_MEDIA_FAILURE,
  REINIT_MEDIA_SEND,
} from 'actions/mediaActions';

import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  sendStatus: Status,
};

const initialState: State = {
  sendStatus: STATUS_DEFAULT,
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case SEND_MEDIA_START:
      return {
        ...state,
        sendStatus: STATUS_LOADING
      };
    case SEND_MEDIA_SUCCESS:
      return {
        ...state,
        sendStatus: STATUS_SUCCESS
      };
    case SEND_MEDIA_FAILURE:
      return {
        ...state,
        sendStatus: STATUS_FAILURE
      };
    case REINIT_MEDIA_SEND:
      return {
        ...state,
        sendStatus: STATUS_DEFAULT
      };
    default:
      return state;
  }
}
