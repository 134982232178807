// @flow

export type paramsType = {
  slug: string,
  typeRugby?: string,
  category?: string,
}

export type SEOPageType = {
  slug: (params?: paramsType) => string,
  title: (str?: any, ...params: Array<any>) => string,
  description: (str?: any, ...params: Array<any>) => ?string,
  keywords: (str?: any, ...params: Array<any>) => string,
};

export const nullPage: SEOPageType = {
  slug: () => 'unknow-slug',
  title: () => '',
  description: () => null,
  keywords: () => '',
};
 
export const SEOPages: Array<SEOPageType> = [
  {
    slug: () => 'actualites',
    title: (clubName: string = '', ligue: string = '') => `Dernières actualités de ${clubName}`,
    description: (ligue: string = '') => `Toute l'actualité rugby du club : infos, dernières minutes, 
équipes de France, France 7, XV de France, Equipes féminines, arbitrage, règles du jeu, infos joueurs, 
compositions d'équipe… avec la Fédération Française de Rugby.`.replace(/\n/g, ''),
    keywords: (clubName: string = '') => `Actualités ${clubName}`,
  },

  {
    slug: () => `actualites/article`,
    title: (articleTitle: string = '') => `${articleTitle}`,
    description: () => '',
    keywords: (articleTitle: string = '') => `${articleTitle}`,
  },

  {
    slug: () => 'actualites/non-classe',
    title: (ligue: string = '') => `Actualités du club ${ligue} - rubrique non classée`,
    description: (ligue: string = '') => `Les actualités officielles du club qui ne sont pas classées dans les autres catégories -  ${ligue}`, // eslint-disable-line max-len
    keywords: () => '',
  },
   
  {
    slug: (params: Object = {}) => `actualites/${params.category}`,
    title: (articleThematic: string = '', clubName: string = '') => `Actualités du club de rugby ${clubName}`,
    description: (articleThematic: string = '') => `${articleThematic} - Toute l'actualité rugby : 
infos, dernières minutes….`.replace(/\n/g, ''),
    keywords: (club: string = '') => `Actualités ${club}`,
  },

// PageList --> ne conserver que keyword le reste provient du BO
  {
    slug: () => 'club',
    title: () => '',
    description: () => '',
    keywords: (ville: string = '', clubName: string = '') => `${ville} ${clubName}`,
  },

// PageNodeHeader --> ne conserver que keyword le reste provient du BO
  {
    slug: () => 'club/l-histoire-du-club',
    title: () => '',
    description: () => '',
    keywords: (clubName: string = '') => `Histoire du club ${clubName}`,
  },

// PageNodeHeader --> ne conserver que keyword le reste provient du BO
  {
    slug: () => 'club/le-mot-du-president',
    title: () => '',
    description: () => '',
    keywords: (clubName: string = '') => `Président ${clubName}`,
  },

// PageNodeHeader --> ne conserver que keyword le reste provient du BO
  {
    slug: () => 'club/le-mot-de-la-presidente',
    title: () => '',
    description: () => '',
    keywords: (clubName: string = '') => `Présidente ${clubName}`,
  },

// PageNodeHeader --> ne conserver que keyword le reste provient du BO
  {
    slug: () => 'club/nous-rejoindre',
    title: () => '',
    description: () => '',
    keywords: (clubName: string = '') => `Rejoindre ${clubName}`,
  },

// PageNodeHeader --> ne conserver que keyword le reste provient du BO
  {
    slug: () => 'club/organigramme',
    title: () => '',
    description: () => '',
    keywords: (clubName: string = '') => `Organigramme ${clubName}`,
  },

// PageNodeHeader --> ne conserver que keyword le reste provient du BO
  {
    slug: () => 'club/palmares',
    title: () => '',
    description: () => '',
    keywords: (clubName: string = '') => `Palmarès club ${clubName}`,
  },

  {
    slug: () => 'contact',
    title: (clubName: string = '') => `Contact ${clubName}`,
    description: () => `Le club répond à vos questions. À quel sujet pouvons-nous vous aider ?`,
    keywords: (clubName: string = '') => `Contacter ${clubName}`,
  },

// Teams --> ne conserver que keyword le reste provient du BO
  {
    slug: () => 'equipes',
    title: () => '',
    description: () => '',
    keywords: (clubName: string = '') => `Equipes rugby ${clubName}`,
  },

// TeamHeader --> ne conserver que keyword le reste provient du BO
  {
    slug: () => `equipes/equipe`,
    title: () => '',
    description: () => '',
    keywords: (teamName: string = '', clubName: string = '') => `${teamName} - ${clubName}`,
  },

  {
    slug: (params: Object = {}) => `/equipes/${params.slug}/calendrier`,
    title: (teamName: string = '', clubName: string = '') => `calendrier ${teamName} - ${clubName}`,
    description: () => ``,
    keywords: (teamName: string = '', clubName: string = '') => `calendrier ${teamName} - ${clubName}`,
  },

  {
    slug: (params: Object = {}) => `/equipes/${params.slug}/classement`,
    title: (teamName: string = '', clubName: string = '') => `classement ${teamName} - ${clubName}`,
    description: () => ``,
    keywords: (teamName: string = '', clubName: string = '') => `classement ${teamName} - ${clubName}`,
  },

  {
    slug: (params: Object = {}) => `/equipes/${params.slug}/effectif`,
    title: (teamName: string = '', clubName: string = '') => `effectif ${teamName} - ${clubName}`,
    description: () => ``,
    keywords: (teamName: string = '', clubName: string = '') => `effectif ${teamName} - ${clubName}`,
  },

  {
    slug: () => `/equipes/informations-pratiques`,
    title: (teamName: string = '', clubName: string = '') => `Informations pratiques ${teamName} - ${clubName}`,
    description: () => ``,
    keywords: (teamName: string = '', clubName: string = '') => `Informations pratiques ${teamName} - ${clubName}`,
  },

  {
    slug: (params: Object = {}) => `/equipes/${params.slug}/resultats`,
    title: (teamName: string = '', clubName: string = '') => `Résultats ${teamName} - ${clubName}`,
    description: () => ``,
    keywords: (teamName: string = '', clubName: string = '') => `Résultats ${teamName} - ${clubName}`,
  },

  {
    slug: (params: Object = {}) => `/equipes/${params.slug}/statistiques`,
    title: (teamName: string = '', clubName: string = '') => `Statistiques ${teamName} - ${clubName}`,
    description: () => ``,
    keywords: (teamName: string = '', clubName: string = '') => `Statistiques ${teamName} - ${clubName}`,
  },

  {
    slug: () => 'evenements',
    title: (clubName: string = '', ligue: string = '', month?: string, year?: string) =>
      `Derniers évènements ${clubName} ${ligue}${month && year ? ` - ${month} ${year}` : ''}`,
    description: (ligue: string = '', month?: string, year?: string) =>
      `Les évènements officiels du club ${ligue} ${month && year ? ` - ${month} ${year}` : ''}.`,
    keywords: (clubName: string = '') => `Évènements ${clubName}`,
  },

  {
    slug: () => `evenements/evenement`,
    title: (eventName: string = '', clubName: string = '') => `${eventName} - ${clubName}`,
    description: () => '',
    keywords: (eventName: string = '', clubName: string = '') => `${eventName} - ${clubName}`,
  },

  {
    slug: () => 'faq',
    title: (ligue: string = '') => `Foire aux questions - ${ligue}`,
    description: () => `Retrouvez toutes les réponses à vos questions sur notre club 
et contactez-nous si vous celle-ci n'est pas présente dans notre liste.`.replace(/\n/g, ''), 
    keywords: () => '',
  },

  {
    slug: () => 'faq/slugTheme',
    title: (theme: string = '') => `Foire aux questions - rubrique ${theme}`,
    description: (theme: string = '') => `Retrouvez toutes les réponses à vos questions sur notre club
dans la thématique ${theme}`.replace("\n", ''),
    keywords: () => '',
  },

// PageList --> ne conserver que keyword
  {
    slug: () => 'jouer_encadrer',
    title: () => '',
    description: () => '',
    keywords: (clubName: string = '') => `Informations rugby ${clubName}`,
  },

// PageNodeHeader --> ne conserver que keyword
  {
    slug: () => 'jouer_encadrer/cout-de-la-pratique',
    title: () => '',
    description: () => '',
    keywords: (clubName: string = '') => `Coût rugby ${clubName}`,
  },

// PageNodeHeader --> ne conserver que keyword
  {
    slug: () => 'jouer_encadrer/ecole-de-rugby',
    title: () => '',
    description: () => '',
    keywords: (clubName: string = '') => `Ecole de rugby ${clubName}`,
  },

// PageNodeHeader --> ne conserver que keyword le reste provient du BO
  {
    slug: () => 'jouer_encadrer/entrainements',
    title: () => '',
    description: () => '',
    keywords: (clubName: string = '') => `Entrainement ${clubName}`,
  },

// PageNodeHeader --> ne conserver que keyword le reste provient du BO
  {
    slug: () => 'jouer_encadrer/modalites-d-inscription',
    title: () => '',
    description: () => '',
    keywords: (clubName: string = '') => `Modalité inscription ${clubName}`,
  },

// PageNodeHeader --> ne conserver que keyword le reste provient du BO
  {
    slug: () => 'jouer_encadrer/offre-de-pratiques',
    title: () => '',
    description: () => '',
    keywords: (clubName: string = '') => `Pratique rugby ${clubName}`,
  },

  {
    slug: () => 'match-prochain',
    title: (clubName: string = '') => `Prochain match ${clubName}`,
    description: () => '',
    keywords: (clubName: string = '') => `Prochain match ${clubName}`,
  },
  
  {
    slug: () => 'match-resume',
    title: (clubName: string = '') => `Résumé de match ${clubName}`,
    description: () => '',
    keywords: (clubName: string = '') => `Résumé match ${clubName}`,
  },

  {
    slug: () => 'match/avant-match',
    title: (teamA: string = '', teamB: string = '') => `Avant-match ${teamA} - ${teamB}`,
    description: () => ``,
    keywords: (teamA: string = '', teamB: string = '') => `Avant-match ${teamA} - ${teamB}`,
  },

  {
    slug: () => 'match/en-direct',
    title: (teamA: string = '', teamB: string = '') => `Match en direct ${teamA} - ${teamB}`,
    description: () => ``,
    keywords: (teamA: string = '', teamB: string = '') => `Match en direct ${teamA} - ${teamB}`,
  },

  {
    slug: () => 'match/medias',
    title: (teamA: string = '', teamB: string = '') => `Photos ${teamA} - ${teamB}`,
    description: () => '',
    keywords: (teamA: string = '', teamB: string = '') => `Photos ${teamA} - ${teamB}`,
  },

  {
    slug: () => 'match/resume',
    title: (teamA: string = '', teamB: string = '') => `Résumé ${teamA} - ${teamB}`,
    description: () => '',
    keywords: (teamA: string = '', teamB: string = '') => `Résumé ${teamA} - ${teamB}`,
  },

  {
    slug: () => 'match/stats',
    title: (teamA: string = '', teamB: string = '') => `Statistiques ${teamA} - ${teamB}`,
    description: () => '',
    keywords: (teamA: string = '', teamB: string = '') => `Statistiques ${teamA} - ${teamB}`,
  },

// PageList --> ne conserver que keyword le reste provient du BO
  {
    slug: () => 'mediatheque',
    title: () => '',
    description: () => '',
    keywords: (ville: string = '', clubName: string = '') => `Médiathèque ${clubName}`,
  },

  {
    slug: () => 'social-wall',
    title: (clubName: string = '') => `Matchs en direct ${clubName}`,
    description: (ligue: string = '') => `Réseaux sociaux du club ${ligue}.`,
    keywords: (clubName: string = '') => `En direct ${clubName}`,
  },

  {
    slug: () => 'recherche',
    title: (ligue: string = '') => `Recherche - ${ligue}`,
    description: () => null,
    keywords: () => '',
  },
];
