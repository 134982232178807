// https://stackoverflow.com/a/48269720
let Components = {};

Components['CONTACT_FORM_SUCCESS'] = require('./ContactFormSuccessModalContent.js').default;
Components['CONTACT_FORM_FAILED'] = require('./ContactFormFailedModalContent.js').default;
Components['MEDIA_SUBMISSION'] = require('./MediaSubmissionModalContent.js').default;
Components['NEWSLETTER_OPTIN'] = require('./NewsletterOptinModalContent.js').default;
Components['NEWSLETTER_MISSING_OPT'] = require('./NewsletterMissingOptModalContent.js').default;
Components['NEWSLETTER_SUBSCRIPTION_SUCCESS'] = require('./NewsletterSubscriptionSuccessModalContent.js').default;
Components['NEWSLETTER_SUBSCRIPTION_FAILED'] = require('./NewsletterSubscriptionFailedModalContent.js').default;
Components['SHARE_CONTENT_FORM'] = require('./ShareContentModalContent.js').default;
Components['SHARE_CONTENT_SUCCESS'] = require('./ShareContentSuccessModalContent.js').default;

export default Components;
