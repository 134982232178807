// @flow

import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS, STATUS_DEFAULT } from 'constants/statusConstants';
import {
  FETCH_CF7_CONTACT_FORM_START,
  FETCH_CF7_CONTACT_FORM_SUCCESS,
  FETCH_CF7_CONTACT_FORM_FAILURE,
  FETCH_SINGLE_CF7_CONTACT_FORM_START,
  FETCH_SINGLE_CF7_CONTACT_FORM_SUCCESS,
  SEND_CF7_CONTACT_FORM_START,
  FETCH_ALL_CF7_CONTACT_FORM_SUCCESS,
  SEND_CF7_CONTACT_FORM_SUCCESS,
  SEND_CF7_CONTACT_FORM_FAILURE,
  RESET_SEND_TO_CF7_STATUS
} from 'actions/contactForm7Actions';

import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';
import {
  nullContactForm,
  nullSingleContactForm,
  type ContactFormType,
  type SingleContactFormType
} from 'types/ContactForm7';

export type State = {
  sendStatus: Status,
  fetchStatus: Status,
  contactForm: ContactFormType,
  singleContactForm: SingleContactFormType,
  contactFormList: Array<ContactFormType>
};

const initialState: State = {
  sendStatus: STATUS_DEFAULT,
  fetchStatus: STATUS_DEFAULT,
  contactForm: nullContactForm,
  singleContactForm: nullSingleContactForm,
  contactFormList: []
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_CF7_CONTACT_FORM_START:
      return {
        ...state,
        fetchStatus: STATUS_LOADING
      };
    case FETCH_CF7_CONTACT_FORM_SUCCESS:
      return {
        ...state,
        contactForm: action.payload.contactForm,
        fetchStatus: STATUS_SUCCESS
      };
    case FETCH_SINGLE_CF7_CONTACT_FORM_START:
      return {
        ...state,
        fetchStatus: STATUS_LOADING
      };
    case FETCH_SINGLE_CF7_CONTACT_FORM_SUCCESS:
      return {
        ...state,
        singleContactForm: action.payload.singleContactForm,
        fetchStatus: STATUS_SUCCESS
      };
    case FETCH_ALL_CF7_CONTACT_FORM_SUCCESS:
      return {
        ...state,
        contactFormList: action.payload.contactFormList,
        fetchStatus: STATUS_SUCCESS
      };
    case FETCH_CF7_CONTACT_FORM_FAILURE:
      return {
        ...state,
        fetchStatus: STATUS_FAILURE
      };
    case SEND_CF7_CONTACT_FORM_START:
      return {
        ...state,
        sendStatus: STATUS_LOADING
      };
    case SEND_CF7_CONTACT_FORM_SUCCESS:
      return {
        ...state,
        sendStatus: STATUS_SUCCESS
      };
    case SEND_CF7_CONTACT_FORM_FAILURE:
      return {
        ...state,
        sendStatus: STATUS_FAILURE
      };
    case RESET_SEND_TO_CF7_STATUS:
      return {
        ...state,
        sendStatus: STATUS_DEFAULT
      };
    default:
      return state;
  }
}
