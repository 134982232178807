  // @flow

import type { EventType } from 'types/Event';

export const RESET_EVENTS = 'RESET_EVENTS';
export const FETCH_EVENTS_START = 'FETCH_EVENTS_START';
export const FETCH_EVENTS_BY_SEASON_START = 'FETCH_EVENTS_BY_SEASON_START';
export const INITIAL_FETCH_EVENTS_BY_SEASON_START = 'INITIAL_FETCH_EVENTS_BY_SEASON_START';
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_FAILURE = 'FETCH_EVENTS_FAILURE';

export type RESET_EVENTS_ACTION = {
  type: 'RESET_EVENTS',
};

export type FETCH_EVENTS_START_ACTION = {
  type: 'FETCH_EVENTS_START',
  payload: {
    from: Date,
    to: Date,
    clubID: string,
    competitions: number[],
  }
};

export type FETCH_EVENTS_FAILURE_ACTION = {
  type: 'FETCH_EVENTS_FAILURE'
};

export type FETCH_EVENTS_SUCCESS_ACTION = {
  type: 'FETCH_EVENTS_SUCCESS',
  payload: {
    events: Array<EventType>
  }
};

export type Action =
  | RESET_EVENTS_ACTION
  | FETCH_EVENTS_START_ACTION
  | FETCH_EVENTS_FAILURE_ACTION
  | FETCH_EVENTS_SUCCESS_ACTION;

export function fetchEvents(from: Date, to: Date, clubID: string, competitions: number[]): FETCH_EVENTS_START_ACTION {
  return {
    type: FETCH_EVENTS_START,
    payload: {
      from,
      to,
      clubID,
      competitions,
    },
  };
}

export function eventsFetched(events: Array<EventType>): FETCH_EVENTS_SUCCESS_ACTION {
  return {
    type: FETCH_EVENTS_SUCCESS,
    payload: { events }
  };
}

export function eventsFetchError(): FETCH_EVENTS_FAILURE_ACTION {
  return {
    type: FETCH_EVENTS_FAILURE
  };
}

export function resetEvents(): RESET_EVENTS_ACTION {
  return {
    type: RESET_EVENTS,
  };
}
