// @flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import FooterTopContainer from 'containers/footer/FooterTopContainer';
import LazyLoad from 'components/fragments/LazyLoad';
import FooterBottomContainer from 'containers/footer/FooterBottomContainer';

const Footer = () => (
  <footer className="footer">
    <LazyLoad>
      <FooterTopContainer />
      <FooterBottomContainer />
    </LazyLoad>
  </footer>
);

export default withRouter(Footer);
