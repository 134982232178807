// @flow
import { convertInstagramPostsFromApi } from 'services/Social';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import type { SocialContent } from 'types/SocialContent';

function InstagramApi(executor: ApiExecutorType) {
  return { fetchPosts, fetchTmpPosts };

  function fetchPosts(): Promise<Array<SocialContent>> {
    return executor.get(`rs_instagram.json`)
              .then(responses => {
                return responses['data'].map(resp => convertInstagramPostsFromApi(resp));
              });
  }

  function fetchTmpPosts(): Promise<Array<SocialContent>> {
    return executor.get(`/ffr/v1/rs_instagram`)
              .then(responses => {
                return responses['data'].map(resp => convertInstagramPostsFromApi(resp));
              });
  }
}

export default InstagramApi;
