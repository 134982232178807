// @flow
import type { RawPartnerType, PartnerType } from 'types/Partner';
import { nullPartner } from 'types/Partner';
import { mergeIntoDefault } from 'utils/objectUtils';

export function convertPartnerFromApi(rawPartner: RawPartnerType): PartnerType {
  if (!rawPartner) {
    return nullPartner;
  }

  const { title, target, external_link, featured_media } = rawPartner;

  return mergeIntoDefault(nullPartner, {
    title: title.raw,
    target,
    link: external_link,
    illustration: featured_media.src
  });
}
