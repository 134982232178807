// @flow

// services
export type rawContactFormResultType = {
  into: string,
  status: string,
  message: string,
  invalidFields: Array<{ into: string, message: string }>
};

export type ContactFormResultType = {
  into: string,
  status: string,
  message: string
};

export const nullContactFormResult = {
  into: '',
  status: '',
  message: ''
};

//* Contact form List
export type FieldsType = {
  type: string,
  basetype: string,
  name: string,
  options: string[],
  raw_values: string[],
  labels: string[],
  values: string[],
  pipes: string[],
  content: string
};

type MailType = {
  active: boolean,
  subject: string,
  sender: string,
  recipient: string,
  body: string,
  additional_headers: string,
  attachments: string,
  use_html: boolean,
  exclude_blank: boolean
};
export type ContactFormType = {
  id: number,
  slug: string,
  title: string,
  locale: string
};

export type rawContactFormType = {
  id: number,
  slug: string,
  title: string,
  locale: string
};

export const nullContactForm = {
  id: -1,
  slug: '',
  title: '',
  locale: ''
};

//* Single Contact Form
export type SingleContactFormType = {
  id: number,
  slug: string,
  title: string,
  locale: string,
  properties: {
    form: {
      content: string,
      fields: FieldsType[]
    },
    mail: MailType,
    mail_2: MailType,
    messages: {
      mail_sent_ok: string,
      mail_sent_ng: string,
      validation_error: string,
      spam: string,
      accept_terms: string,
      invalid_required: string,
      invalid_too_long: string,
      invalid_too_short: string,
      invalid_date: string,
      date_too_early: string,
      date_too_late: string,
      upload_failed: string,
      upload_file_type_invalid: string,
      upload_file_too_large: string,
      upload_failed_php_error: string,
      invalid_number: string,
      number_too_small: string,
      number_too_large: string,
      quiz_answer_not_correct: string,
      invalid_email: string,
      invalid_url: string,
      invalid_tel: string,
      captcha_not_match: string
    },
    additional_settings: {
      content: string,
      settings: string[]
    }
  }
};

export const nullSingleContactForm = {
  id: -1,
  slug: '',
  title: '',
  locale: '',
  properties: {
    form: {
      content: '',
      fields: []
    },
    mail: {
      active: false,
      subject: '',
      sender: '',
      recipient: '',
      body: '',
      additional_headers: '',
      attachments: '',
      use_html: false,
      exclude_blank: false
    },
    mail_2: {
      active: false,
      subject: '',
      sender: '',
      recipient: '',
      body: '',
      additional_headers: '',
      attachments: '',
      use_html: false,
      exclude_blank: false
    },
    messages: {
      mail_sent_ok: '',
      mail_sent_ng: '',
      validation_error: '',
      spam: '',
      accept_terms: '',
      invalid_required: '',
      invalid_too_long: '',
      invalid_too_short: '',
      invalid_date: '',
      date_too_early: '',
      date_too_late: '',
      upload_failed: '',
      upload_file_type_invalid: '',
      upload_file_too_large: '',
      upload_failed_php_error: '',
      invalid_number: '',
      number_too_small: '',
      number_too_large: '',
      quiz_answer_not_correct: '',
      invalid_email: '',
      invalid_url: '',
      invalid_tel: '',
      captcha_not_match: ''
    },
    additional_settings: {
      content: '',
      settings: []
    }
  }
};

export type rawSingleContactFormType = {
  id: number,
  slug: string,
  title: string,
  locale: string,
  properties: {
    form: {
      content: string,
      fields: FieldsType[]
    },
    mail: MailType,
    mail_2: MailType,
    messages: {
      mail_sent_ok: string,
      mail_sent_ng: string,
      validation_error: string,
      spam: string,
      accept_terms: string,
      invalid_required: string,
      invalid_too_long: string,
      invalid_too_short: string,
      invalid_date: string,
      date_too_early: string,
      date_too_late: string,
      upload_failed: string,
      upload_file_type_invalid: string,
      upload_file_too_large: string,
      upload_failed_php_error: string,
      invalid_number: string,
      number_too_small: string,
      number_too_large: string,
      quiz_answer_not_correct: string,
      invalid_email: string,
      invalid_url: string,
      invalid_tel: string,
      captcha_not_match: string
    },
    additional_settings: {
      content: string,
      settings: string[]
    }
  }
};

//* Field

export type InputValue = string | number | Date | boolean | Array<string>;

export type InputFile = {
  lastModified: number,
  lastModifiedDate: Date,
  name: string,
  size: number,
  type: string
};
export type CustomEvent = {
  currentTarget: { name: string, value: InputValue, files?: InputFile[] },

  preventDefault(): void
};

//* Classes
export type FieldClassesType = {
  form: string[],
  fieldTypeList: string[],
  containerDiv?: string,
  fieldContainerDiv: string,
  label: string,
  input: string,
  p?: string,
  cancelButton?: string
};

export const nullFieldClasses = {
  form: [''],
  fieldTypeList: [''],
  fieldContainerDiv: '',
  label: '',
  input: ''
};
