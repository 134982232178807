// @flow
import { ApolloClient, InMemoryCache, HttpLink, from } from "@apollo/client";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { onError } from "@apollo/client/link/error";
import { sha256 } from 'crypto-hash';
import { GRAPH_URL } from 'constants/api';

const clientBySeason = {};

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all', 
  },
};

const linkError = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`));
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const linkChain = createPersistedQueryLink({
  sha256,
  useGETForHashedQueries: true
}).concat(new HttpLink({ uri: GRAPH_URL }));

export const client = new ApolloClient({
  cache: new InMemoryCache({addTypename: false}),
  link: from([linkError, linkChain]),
  defaultOptions
});

export function getClientForSeason(season: string) {
  if (clientBySeason[season]) {
    return clientBySeason[season];
  }

  const linkChain = createPersistedQueryLink({
    sha256,
    useGETForHashedQueries: true
  }).concat(new HttpLink({ uri: `${GRAPH_URL}?saisonId=${season}` }));

  const newClient = new ApolloClient({
    link: from([linkError, linkChain]),
    cache: new InMemoryCache({ addTypename: false }),
    defaultOptions,
  });

  clientBySeason[season] = newClient;

  return newClient;
}
