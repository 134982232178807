// @flow

import type { PartenaireType } from 'types/Partenaires';

export const FETCH_PARTENAIRE_START = 'FETCH_PARTENAIRE_START';
export const FETCH_PARTENAIRE_SUCCESS = 'FETCH_PARTENAIRE_SUCCESS';
export const FETCH_PARTENAIRE_FAILURE = 'FETCH_PARTENAIRE_FAILURE';

export type FETCH_PARTENAIRE_START_ACTION = {
  type: 'FETCH_PARTENAIRE_START',
  payload: {
    slug: string,
  },
};

export type FETCH_PARTENAIRE_SUCCESS_ACTION = {
  type: 'FETCH_PARTENAIRE_SUCCESS',
  payload: {
    partenaire: PartenaireType,
  },
};

export type FETCH_PARTENAIRE_FAILURE_ACTION = {
  type: 'FETCH_PARTENAIRE_FAILURE',
  payload: {
    error: string,
  },
};

export type Action =
  | FETCH_PARTENAIRE_START_ACTION
  | FETCH_PARTENAIRE_SUCCESS_ACTION
  | FETCH_PARTENAIRE_FAILURE_ACTION;

export const fetchPartenaire = (slug: string): FETCH_PARTENAIRE_START_ACTION => ({
  type: FETCH_PARTENAIRE_START,
  payload: { slug },
});

export const fetchPartenaireSuccess = (partenaire: PartenaireType): FETCH_PARTENAIRE_SUCCESS_ACTION => ({
  type: FETCH_PARTENAIRE_SUCCESS,
  payload: { partenaire },
});

export const fetchPartenaireFailure = (error: string): FETCH_PARTENAIRE_FAILURE_ACTION => ({
  type: FETCH_PARTENAIRE_FAILURE,
  payload: { error },
});
