// @flow
import { connect } from 'react-redux';

import MediaSubmissionFormEmail from 'components/modals/MediaSubmissionFormEmail';

import { displayModal } from 'actions/modalActions';

import type { DispatchType } from 'types/Actions';
import type { DispatchProps } from 'components/modals/MediaSubmissionFormEmail';

const mapDispatchToProps = (dispatch: DispatchType): DispatchProps => ({
  displayModal: (modalObject: string, content: { email: string, title: string }, force?: boolean) =>
    dispatch(displayModal(modalObject, content, true))
});

export default connect(null, mapDispatchToProps)(MediaSubmissionFormEmail);
