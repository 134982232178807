// @flow
import { FETCH_ARTICLE_SUCCESS } from 'actions/articleActions';
import { FETCH_CATEGORIES_SUCCESS } from 'actions/categoriesActions';
import { FETCH_EVENT_SUCCESS } from 'actions/eventActions';
import { FETCH_EVENTS_SUCCESS } from 'actions/eventsActions';
import { FETCH_FAQ_THEME_SUCCESS } from 'actions/faqActions';
import { SEND_VPV } from 'actions/hitTagsActions';
import { FETCH_ARTICLES_CATEGORIES_SUCCESS } from 'actions/newsletterActions';
import { FETCH_ARTICLES_CATEGORY_SUCCESS } from 'actions/newsletterCategoryActions';
import { SUBSCRIBE_NEWSLETTER_SUCCESS } from 'actions/newsletterSubscriptionActions';
import { FETCH_PAGE_LIST_SUCCESS, FETCH_PAGE_NODE_SUCCESS } from 'actions/pageActions';


export const hitTagsArray = [
  FETCH_ARTICLE_SUCCESS,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_EVENT_SUCCESS,
  FETCH_EVENTS_SUCCESS,
  FETCH_ARTICLES_CATEGORIES_SUCCESS,
  FETCH_ARTICLES_CATEGORY_SUCCESS,
  SUBSCRIBE_NEWSLETTER_SUCCESS,
  FETCH_PAGE_LIST_SUCCESS,
  FETCH_PAGE_NODE_SUCCESS,
  SEND_VPV,
  FETCH_FAQ_THEME_SUCCESS,
];
