// @flow

import type { ArticleType, CategoryArticleType } from 'types/Article';

export const FETCH_ARTICLES_START = 'FETCH_ARTICLES_START';
export const FETCH_ARTICLES_SUCCESS = 'FETCH_ARTICLES_SUCCESS';
export const FETCH_ARTICLES_FAILURE = 'FETCH_ARTICLES_FAILURE';
export const FETCH_ARTICLES_BILLBOARD_START = 'FETCH_ARTICLES_BILLBOARD_START';
export const FETCH_ARTICLES_BILLBOARD_SUCCESS = 'FETCH_ARTICLES_BILLBOARD_SUCCESS';
export const FETCH_ARTICLES_BILLBOARD_FAILURE = 'FETCH_ARTICLES_BILLBOARD_FAILURE';
export const FETCH_ARTICLES_CATEGORIES_START = 'FETCH_ARTICLES_CATEGORIES_START';
export const FETCH_ARTICLES_CATEGORIES_SUCCESS = 'FETCH_ARTICLES_CATEGORIES_SUCCESS';
export const FETCH_ARTICLES_CATEGORIES_FAILURE = 'FETCH_ARTICLES_CATEGORIES_FAILURE';
export const FETCH_ARTICLES_STATIC_CATEGORIES_START = 'FETCH_ARTICLES_STATIC_CATEGORIES_START';
export const FETCH_ARTICLES_STATIC_CATEGORIES_SUCCESS = 'FETCH_ARTICLES_STATIC_CATEGORIES_SUCCESS';
export const FETCH_ARTICLES_STATIC_CATEGORIES_FAILURE = 'FETCH_ARTICLES_STATIC_CATEGORIES_FAILURE';

export type FETCH_ARTICLES_START_ACTION = {
  type: 'FETCH_ARTICLES_START',
  payload: {
    page: number,
    per_page: number
  }
};

export type FETCH_ARTICLES_FAILURE_ACTION = {
  type: 'FETCH_ARTICLES_FAILURE'
};

export type FETCH_ARTICLES_SUCCESS_ACTION = {
  type: 'FETCH_ARTICLES_SUCCESS',
  payload: {
    articles: Array<ArticleType>
  }
};

export type FETCH_ARTICLES_BILLBOARD_START_ACTION = {
  type: 'FETCH_ARTICLES_BILLBOARD_START'
};

export type FETCH_ARTICLES_BILLBOARD_FAILURE_ACTION = {
  type: 'FETCH_ARTICLES_BILLBOARD_FAILURE'
};

export type FETCH_ARTICLES_BILLBOARD_SUCCESS_ACTION = {
  type: 'FETCH_ARTICLES_BILLBOARD_SUCCESS',
  payload: {
    articles: Array<ArticleType>
  }
};

export type FETCH_ARTICLES_CATEGORIES_START_ACTION = {
  type: 'FETCH_ARTICLES_CATEGORIES_START',
  payload: {
    page: number,
    per_page: number
  }
};

export type FETCH_ARTICLES_CATEGORIES_FAILURE_ACTION = {
  type: 'FETCH_ARTICLES_CATEGORIES_FAILURE'
};

export type FETCH_ARTICLES_CATEGORIES_SUCCESS_ACTION = {
  type: 'FETCH_ARTICLES_CATEGORIES_SUCCESS',
  payload: {
    categories: Array<CategoryArticleType>
  }
};

export type FETCH_ARTICLES_STATIC_CATEGORIES_START_ACTION = {
  type: 'FETCH_ARTICLES_STATIC_CATEGORIES_START',
};

export type FETCH_ARTICLES_STATIC_CATEGORIES_FAILURE_ACTION = {
  type: 'FETCH_ARTICLES_STATIC_CATEGORIES_FAILURE'
};

export type FETCH_ARTICLES_STATIC_CATEGORIES_SUCCESS_ACTION = {
  type: 'FETCH_ARTICLES_STATIC_CATEGORIES_SUCCESS',
  payload: {
    categories: Array<CategoryArticleType>
  }
};

export type Action = FETCH_ARTICLES_START_ACTION
                  | FETCH_ARTICLES_FAILURE_ACTION
                  | FETCH_ARTICLES_SUCCESS_ACTION
                  | FETCH_ARTICLES_BILLBOARD_START_ACTION
                  | FETCH_ARTICLES_BILLBOARD_FAILURE_ACTION
                  | FETCH_ARTICLES_BILLBOARD_SUCCESS_ACTION
                  | FETCH_ARTICLES_CATEGORIES_START_ACTION
                  | FETCH_ARTICLES_CATEGORIES_FAILURE_ACTION
                  | FETCH_ARTICLES_CATEGORIES_SUCCESS_ACTION
                  | FETCH_ARTICLES_STATIC_CATEGORIES_START_ACTION
                  | FETCH_ARTICLES_STATIC_CATEGORIES_FAILURE_ACTION
                  | FETCH_ARTICLES_STATIC_CATEGORIES_SUCCESS_ACTION;

export function fetchArticlesBillboard(): FETCH_ARTICLES_BILLBOARD_START_ACTION {
  return {
    type: FETCH_ARTICLES_BILLBOARD_START
  };
}

export function articlesBillboardFetched(articles: Array<ArticleType>): FETCH_ARTICLES_BILLBOARD_SUCCESS_ACTION {
  return {
    type: FETCH_ARTICLES_BILLBOARD_SUCCESS,
    payload: { articles }
  };
}

export function articlesBillboardFetchError(error: any): FETCH_ARTICLES_BILLBOARD_FAILURE_ACTION {
  return {
    type: FETCH_ARTICLES_BILLBOARD_FAILURE
  };
}

export function fetchArticles(page: number, per_page: number): FETCH_ARTICLES_START_ACTION {
  return {
    type: FETCH_ARTICLES_START,
    payload: { page, per_page }
  };
}

export function articlesFetched(articles: Array<ArticleType>): FETCH_ARTICLES_SUCCESS_ACTION {  
  return {
    type: FETCH_ARTICLES_SUCCESS,
    payload: { articles }
  };
}

export function articlesFetchError(error: any): FETCH_ARTICLES_FAILURE_ACTION {
  return {
    type: FETCH_ARTICLES_FAILURE
  };
}

export function fetchArticlesCategories(page: number, per_page: number): FETCH_ARTICLES_CATEGORIES_START_ACTION {
  return {
    type: FETCH_ARTICLES_CATEGORIES_START,
    payload: { page, per_page }
  };
}

export function fetchArticlesStaticCategories(): FETCH_ARTICLES_STATIC_CATEGORIES_START_ACTION {
  return {
    type: FETCH_ARTICLES_STATIC_CATEGORIES_START
  };
}

export function articlesCategoriesFetched(
  categories: Array<CategoryArticleType>
): FETCH_ARTICLES_CATEGORIES_SUCCESS_ACTION {
  return {
    type: FETCH_ARTICLES_CATEGORIES_SUCCESS,
    payload: { categories }
  };
}

export function articlesCategoriesFetchError(error: any): FETCH_ARTICLES_CATEGORIES_FAILURE_ACTION {
  return {
    type: FETCH_ARTICLES_CATEGORIES_FAILURE
  };
}
