// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';

import { convertSettingsFromApi } from 'services/Settings';

function SettingsApi(executor: ApiExecutorType) {
  return {
    fetchSettings
  };

function fetchSettings() {
    return executor.get(`/ffr/v1/settings`)
      .then(response => convertSettingsFromApi(response));
  }
}

export default SettingsApi;
