// @flow

import { setWindowApp, getWindowApp } from 'utils/windowUtils';
import defaultImgTeam from 'assets/img/logo/placeholder-club.png';

export function getPublicAssets(link: string) {
  return `${process.env.PUBLIC_URL || ''}${link}`;
}

export function getPlaceholder(): string {
  const { hostname } = window.location;
  let host = hostname.split('.')[0] || undefined;
  if (['dev','pp','localhost'].indexOf(host) > -1) {
    host = 'ffr';
  }
  const filename = ligue => `placeholder-${ligue}.jpg`;
  const path = filename => `/img/placeholder/${filename}`;
  const defaultSource = getPublicAssets(path(filename('ffr')));
  const targetSource = getPublicAssets(path(filename(host || '')));
  setWindowApp('placeholder', defaultSource);

  const image = new Image();
  image.onload = () => setWindowApp('placeholder', targetSource);
  image.src = targetSource;

  return getWindowApp('placeholder') || defaultSource;
}

export const shouldUsePlaceholder = (competition: Object, isLeftScore: boolean) => {
  const { embleme_france, embleme_adversaire, reception } = competition;
  const francePlaysAtHome = reception && reception !== 0;

  if (!isLeftScore) {
    if (francePlaysAtHome) {
      return embleme_adversaire ? embleme_adversaire : defaultImgTeam;
    } else {
      return embleme_france ? embleme_france : defaultImgTeam;
    }
  } else if (isLeftScore) {
    if (francePlaysAtHome) {
      return embleme_france ? embleme_france : defaultImgTeam;
    } else {
      return embleme_adversaire ? embleme_adversaire : defaultImgTeam;
    }
  }
  return defaultImgTeam;
};

export const usePlaceholderOnImageError = (img: HTMLImageElement, path: string) => {
  img.src = getPublicAssets(path);
};
