// @flow
import { connect } from 'react-redux';

import ShareContentForm from 'components/modals/ShareContentForm';

import type { mediaInputsType } from 'types/Media';
import type { DispatchType, StateType } from 'types/Actions';
import type { DispatchProps, StateProps } from 'components/modals/ShareContentForm';
import { displayModal } from 'actions/modalActions';
import { postMedia, mediaReinit } from 'actions/mediaActions';

export default connect(
  (state: StateType): StateProps => ({
    sendStatus: state.mediaState.sendStatus,
    albumid: state.albumState.album.id
  }),
  (dispatch: DispatchType): DispatchProps => ({
    postMedia: (mediaInputs: mediaInputsType) => dispatch(postMedia(mediaInputs)),
    displayModal: (modalObject: string, content: any, force?: boolean) =>
      dispatch(displayModal(modalObject, content, force)),
    mediaReinit: () => dispatch(mediaReinit())
  })
)(ShareContentForm);
