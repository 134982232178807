// @flow
import { gql } from '@apollo/client';
import { client } from 'infrastructure/apollo';

const query = gql`
query($structureId: ID, $classeAge: String, $sexe: String, $saisonId: Int) {
    Personnes(
      Qualites: {
        Qualite:  { nom: "Rugby compétition" }
        saisonId: $saisonId
        ClasseAge: { code: $classeAge, Sexe: { code: $sexe } }
        Structure: { id: $structureId }
      }
    ) {
      id
      nom
      prenom
      numeroAffiliation
    }
}
`;

export function fetchPlayersFromGraph(clubID: number, classeAge: string, sexe: string, saisonId: number) {
    if (!clubID || !classeAge || !sexe) {
        return [];
    }

    return client.query({
        query,
        variables: { structureId: clubID, classeAge, sexe, saisonId },
    });
};
