/// @flow
import {
  KEYCLOAK_CONNECT,
  KEYCLOAK_CONNECT_SUCCESS,
  KEYCLOAK_CONNECT_FAILURE,
} from 'actions/userActions';
import type { Action } from 'actions/userActions';

export type State = {
  keycloakData?: Object,
  connectionInProgress: boolean,
};

const initialState: State = {
  connectionInProgress: false,
  keycloakData: {},
};

export default function (state: State = initialState, action: Action): State {
  switch (action.type) {
    case KEYCLOAK_CONNECT_SUCCESS:
      return {
        ...state,
        connectionInProgress: false,
        keycloakData: {
          ...action.payload.keycloakData,
        },
      };
    case KEYCLOAK_CONNECT_FAILURE:
      return {
        ...state,
        connectionInProgress: false,
      };
    case KEYCLOAK_CONNECT:
      return {
        ...state,
        connectionInProgress: true
      };
    default:
      return state;
  }
}