// @flow

import type { SocialContent } from 'types/SocialContent';

export const FETCH_FACEBOOK_START = 'FETCH_FACEBOOK_START';
export const FETCH_FACEBOOK_SUCCESS = 'FETCH_FACEBOOK_SUCCESS';
export const FETCH_FACEBOOK_FAILURE = 'FETCH_FACEBOOK_FAILURE';

export type FETCH_FACEBOOK_START_ACTION = {
  type: 'FETCH_FACEBOOK_START'
};

export type FETCH_FACEBOOK_FAILURE_ACTION = {
  type: 'FETCH_FACEBOOK_FAILURE'
};

export type FETCH_FACEBOOK_SUCCESS_ACTION = {
  type: 'FETCH_FACEBOOK_SUCCESS',
  payload: {
    posts: Array<SocialContent>
  }
};

export type Action = FETCH_FACEBOOK_START_ACTION | FETCH_FACEBOOK_FAILURE_ACTION | FETCH_FACEBOOK_SUCCESS_ACTION;

export function fetchFacebookPosts(): FETCH_FACEBOOK_START_ACTION {
  return {
    type: FETCH_FACEBOOK_START
  };
}

export function facebookPostsFetched(posts: Array<SocialContent>): FETCH_FACEBOOK_SUCCESS_ACTION {
  return {
    type: FETCH_FACEBOOK_SUCCESS,
    payload: { posts }
  };
}

export function facebookPostsFetchError(): FETCH_FACEBOOK_FAILURE_ACTION {
  return {
    type: FETCH_FACEBOOK_FAILURE
  };
}
