// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';

import { convertThemeFromApi, convertQuestionFromApi } from 'services/Faq';

function FaqApi(executor: ApiExecutorType) {
  return { fetchThemes, fetchQuestions };

  function fetchThemes() {
    return executor
      .get('/wp/v2/theme')
      .then(response => response.map(themes => convertThemeFromApi(themes)));
  }

  function fetchQuestions() {
    return executor
      .get('/ffr/v1/wp_faq')
      .then(response => response.map(questions => convertQuestionFromApi(questions)));
  }
}

export default FaqApi;
