// @flow
import { gql } from '@apollo/client';
import { client } from 'infrastructure/apollo';
import { convertSeasonsFromGraph, convertCompetitionsSeasonsFromGraph } from 'services/Seasons';

const query = gql`
{
  seasons: Saisons {
    id
    nom
  }

  lastSeason: SaisonCourante {
    id
    nom
  }
}
`;

const queryCompetitionsSeasons = gql`
  query Competitions($id: [Int!], $saisonId: [Int]) {
    Competitions(id: $id, saisonId: $saisonId) {
      nom
      id
    }
  }
`;

export const fetchSeasonsFromGraph = async () => {
  const response = await client.query({
    query,
  });

  return convertSeasonsFromGraph(response);
};

export const fetchCompetitionsSeasonsFromGraph = async (id: number[], saisonId: number[]) => {
  const query = queryCompetitionsSeasons;
  const response = await client.query({
    query,
    variables: { id, saisonId },
  });
  return convertCompetitionsSeasonsFromGraph(response);
};
