// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import MediaApi from 'api/media/MediaApi';
import { displayModal } from 'actions/modalActions';
import { SEND_MEDIA_START, mediaPosted, mediaPostError, type SEND_MEDIA_START_ACTION } from 'actions/mediaActions';

export default function(apiExecutor: ApiExecutorType) {
  const mediaApi = new MediaApi(apiExecutor);

  return function* mediaSaga(): GeneratorType {
    yield takeLatest(SEND_MEDIA_START, sendMedia);
  };

  function* sendMedia(action: SEND_MEDIA_START_ACTION): Saga<void> {
    try {
      const { mediaInputs } = action.payload;
      const data = new FormData();

      // eslint-disable-next-line
      for (const key in mediaInputs) {
        if (mediaInputs.hasOwnProperty(key)) {
          data.append(key, mediaInputs[key]);
        }
      }

      yield call(mediaApi.postMedia, data);
      yield put(mediaPosted());
    } catch (e) {
      const data = {};
      if (e.invalidFields && e.invalidFields.length > 0 && e.invalidFields[0].message === 'Doublon adresse email') {
        data.title = `Désolé !`;
        data.text = `Vous ne pouvez participer qu'une seule fois.`;
      } else if (
        e.invalidFields &&
        e.invalidFields.length > 0 &&
        e.invalidFields[0].message === 'Doublon adresse email et video'
      ) {
        data.title = `Désolé !`;
        data.text = `Vous ne pouvez envoyer votre video qu'une seule fois.`;
      }
      yield put(displayModal('CONTACT_FORM_FAILED', data, true));
      yield put(mediaPostError());
    }
  }
}
