// @flow

import type { mediaInputsType } from 'types/Media';

export const SEND_MEDIA_START = 'SEND_MEDIA_START';
export const SEND_MEDIA_SUCCESS = 'SEND_MEDIA_SUCCESS';
export const SEND_MEDIA_FAILURE = 'SEND_MEDIA_FAILURE';
export const REINIT_MEDIA_SEND = 'REINIT_MEDIA_SEND';

export type SEND_MEDIA_START_ACTION = {
  type: 'SEND_MEDIA_START',
  payload: {
    mediaInputs: mediaInputsType
  }
};

export type SEND_MEDIA_FAILURE_ACTION = {
  type: 'SEND_MEDIA_FAILURE'
};

export type SEND_MEDIA_SUCCESS_ACTION = {
  type: 'SEND_MEDIA_SUCCESS'
};

export type REINIT_MEDIA_SEND_ACTION = {
  type: 'REINIT_MEDIA_SEND'
};

export type Action =
  | SEND_MEDIA_START_ACTION
  | SEND_MEDIA_FAILURE_ACTION
  | SEND_MEDIA_SUCCESS_ACTION
  | REINIT_MEDIA_SEND_ACTION;

export function postMedia(mediaInputs: mediaInputsType): SEND_MEDIA_START_ACTION {
  return {
    type: SEND_MEDIA_START,
    payload: {
      mediaInputs,
    }
  };
}

export function mediaPosted(): SEND_MEDIA_SUCCESS_ACTION {
  return {
    type: SEND_MEDIA_SUCCESS
  };
}

export function mediaPostError(error: any): SEND_MEDIA_FAILURE_ACTION {
  return {
    type: SEND_MEDIA_FAILURE
  };
}

export function mediaReinit(): REINIT_MEDIA_SEND_ACTION {
  return {
    type: REINIT_MEDIA_SEND
  };
}
