// @flow

import {
  FETCH_TEAM_RESULT_START,
  FETCH_TEAM_RESULT_SUCCESS,
  FETCH_TEAM_RESULT_FAILURE,
} from 'actions/team/teamResultActions';

import {
  STATUS_DEFAULT,
  STATUS_SUCCESS,
  STATUS_FAILURE,
  STATUS_LOADING,
} from 'constants/statusConstants';

import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';
import { type CompetitionResultType, nullCompetitionResult } from 'types/Result';
import { type TeamStatsType, nullTeamStats } from 'types/TeamStats';

export type State = {
  status: Status,
  competitions: CompetitionResultType[],
  teamStats: TeamStatsType,
  historique: {
    [key: string]: {
      status: Status,
      competitions: CompetitionResultType[],
      teamStats: TeamStatsType,
    }
  }
};

const initialState: State = {
  status: STATUS_DEFAULT,
  competitions: [nullCompetitionResult],
  teamStats: nullTeamStats,
  historique: {},
};

export default function (state: State = initialState, action: ActionType): State {
  let key;

  switch (action.type) {
    case FETCH_TEAM_RESULT_START:
      key = action.payload.season && action.payload.season.toString();
      
      if (key) {
        return {
          ...state,
          historique: {
            ...state.historique,
            [key]: state.historique[key]
              ? { ...state.historique[key], status: STATUS_LOADING }
              : { status: STATUS_LOADING }
          }
        };
      }

      return {
        ...state,
        status: STATUS_LOADING,
      };

    case FETCH_TEAM_RESULT_SUCCESS:
      if (action.payload.season) {
        return {
          ...state,
          historique: {
            ...state.historique,
            [action.payload.season.toString()]: {
              status: STATUS_SUCCESS,
              competitions: action.payload.competitions,
              teamStats: action.payload.teamStats,
            }
          }
        };
      }

      return {
        ...state,
        status: STATUS_SUCCESS,
        competitions: action.payload.competitions,
        teamStats: action.payload.teamStats,
      };

    case FETCH_TEAM_RESULT_FAILURE:
      key = action.payload.season && action.payload.season.toString();

      if (key) {
        return {
          ...state,
          historique: {
            ...state.historique,
            [key]: state.historique[key]
              ? { ...state.historique[key], status: STATUS_FAILURE }
              : { status: STATUS_FAILURE }
          }
        };
      }

      return {
        ...state,
        status: STATUS_FAILURE,
      };

    default:
      return state;
  }
}
