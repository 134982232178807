// @flow

import type { AlbumType } from 'types/Albums';

export const FETCH_ALBUM_START = 'FETCH_ALBUM_START';
export const FETCH_ALBUM_SUCCESS = 'FETCH_ALBUM_SUCCESS';
export const FETCH_ALBUM_FAILURE = 'FETCH_ALBUM_FAILURE';
export const FETCH_ALBUMS_START = 'FETCH_ALBUMS_START';
export const FETCH_ALBUMS_SUCCESS = 'FETCH_ALBUMS_SUCCESS';
export const FETCH_ALBUMS_FAILURE = 'FETCH_ALBUMS_FAILURE';

export type FETCH_ALBUMS_START_ACTION = {
  type: 'FETCH_ALBUMS_START',
};

export type FETCH_ALBUMS_SUCCESS_ACTION = {
  type: 'FETCH_ALBUMS_SUCCESS',
  payload: {
    albums: AlbumType[],
  },
};

export type FETCH_ALBUMS_FAILURE_ACTION = {
  type: 'FETCH_ALBUMS_FAILURE',
  payload: {
    error: string,
  },
};

export type FETCH_ALBUM_START_ACTION = {
  type: 'FETCH_ALBUM_START',
  payload: {
    slug: string,
    args: string,
  },
};

export type FETCH_ALBUM_SUCCESS_ACTION = {
  type: 'FETCH_ALBUM_SUCCESS',
  payload: {
    album: AlbumType,
  },
};

export type FETCH_ALBUM_FAILURE_ACTION = {
  type: 'FETCH_ALBUM_FAILURE',
  payload: {
    error: string,
  },
};

export type Action =
  | FETCH_ALBUMS_START_ACTION
  | FETCH_ALBUMS_SUCCESS_ACTION
  | FETCH_ALBUMS_FAILURE_ACTION
  | FETCH_ALBUM_START_ACTION
  | FETCH_ALBUM_SUCCESS_ACTION
  | FETCH_ALBUM_FAILURE_ACTION;

export const fetchAlbum = (slug: string, args: string): FETCH_ALBUM_START_ACTION => ({
  type: FETCH_ALBUM_START,
  payload: { slug, args },
});

export const fetchAlbumSuccess = (album: AlbumType): FETCH_ALBUM_SUCCESS_ACTION => ({
  type: FETCH_ALBUM_SUCCESS,
  payload: { album },
});

export const fetchAlbumFailure = (error: string): FETCH_ALBUM_FAILURE_ACTION => ({
  type: FETCH_ALBUM_FAILURE,
  payload: { error },
});

export const fetchAlbums = () => ({
  type: FETCH_ALBUMS_START,
});

export const fetchAlbumsSuccess = (albums: AlbumType[]): FETCH_ALBUMS_SUCCESS_ACTION => ({
  type: FETCH_ALBUMS_SUCCESS,
  payload: { albums },
});

export const fetchAlbumsFailure = (error: string): FETCH_ALBUMS_FAILURE_ACTION => ({
  type: FETCH_ALBUMS_FAILURE,
  payload: { error },
});
