// @flow

import {
  FETCH_TEAM_CALENDAR_START,
  FETCH_TEAM_CALENDAR_SUCCESS,
  FETCH_TEAM_CALENDAR_FAILURE,
} from 'actions/team/teamCalendarActions';

import {
  STATUS_DEFAULT,
  STATUS_SUCCESS,
  STATUS_FAILURE,
  STATUS_LOADING,
} from 'constants/statusConstants';

import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';
import type { ResultCalendarContainerType } from 'types/Result';

export type State = {
  status: Status,
  nextMatches: ResultCalendarContainerType,
};

const initialState: State = {
  status: STATUS_DEFAULT,
  nextMatches: {},
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_TEAM_CALENDAR_START:
      return {
        ...state,
        status: STATUS_LOADING,
      };
    case FETCH_TEAM_CALENDAR_SUCCESS:
      return {
        status: STATUS_SUCCESS,
        nextMatches: action.payload.nextMatches,
      };
    case FETCH_TEAM_CALENDAR_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE,
      };
    default:
      return state;
  }
}
