// @flow
import { type RawClassicType, nullClassic } from 'types/GraphQL';
import {
  RESET_SEASON,
  FETCH_SEASON_START,
  FETCH_SEASON_SUCCESS,
  FETCH_SEASON_FAILURE
} from 'actions/seasonsActions';
import type { ActionType } from 'types/Actions';

export type State = {
  seasons: RawClassicType[],
  lastSeason: RawClassicType,
};

const initialState: State = {
  seasons: [nullClassic],
  lastSeason: nullClassic,
};

export default (state: State = initialState, action: ActionType) => {
  switch (action.type) {
    case RESET_SEASON:
      return initialState;
    case FETCH_SEASON_START:
      return { ...state };
    case FETCH_SEASON_SUCCESS:
      return {
        ...state,
        ...action.payload.seasons,
      };
    case FETCH_SEASON_FAILURE:
      return { ...state };
    default:
      return state;
  }
};
