// @flow
import type { CardType, NewsNunType } from 'types/PageList';
import type { PageChildType } from 'types/PageNode';
import type { BlocContent } from 'types/NewsletterBloc';
import type { SEOMetaType } from 'types/SEOType';
import type { Pratique } from 'types/Club';
import { nullSeoMeta } from 'types/SEOType';
import { nullPratique } from 'types/Club';

export type DocumentType = {
  id: number,
  title: string,
  mime_type: string,
  src: string,
  date: string,
  tags: string
};

export type PageDetailType = SEOMetaType & {
  title: string,
  content: BlocContent[],
  slug: string,
  link: string,
  rencontre_background: string,
  featured_media: {
    src: string,
    legend: string
  },
  template: string,
  label: Object,
  niveau: number,
  sidebar_content: BlocContent[],
  redirection: string,
};

export type PageDetailPreviewType = SEOMetaType & PageDetailType & {
  title_parent: string,
  slug_parent: string,
  description: string,
  liste_enfants: PageChildType[],
  rencontre_background: string,
  pratiques_structure: Pratique[],
  pratique: Pratique,
  news: NewsNunType[],
};

export type RawPageDetailType = SEOMetaType & {
  title: string,
  description: string,
  slug: string,
  link: string,
  content: {
    data: BlocContent[]
  },
  title: {
    raw: string
  },
  parent: string,
  label: Object,
  url: string,
  status: string,
  timezone: string,
  date_format: string,
  time_format: string,
  start_of_week: number,
  language: string,
  use_smilies: boolean,
  default_category: number,
  default_post_format: string,
  posts_per_page: number,
  default_ping_status: string,
  default_comment_status: string,
  logo: string,
  titre_liste_cards: string,
  liste_cards: CardType[],
  rencontre_background: string,
  featured_media: {
    src: string,
    legend: string
  },
  template: string,
  niveau: number,
  sidebar_content: {
    data: BlocContent[]
  },
  redirect: {
    type: string,
    postid: string,
    title: string,
    url: string,
  },
};

export type RawPageDetailPreviewType = SEOMetaType & RawPageDetailType & {
  id: number,
  title_parent: string,
  slug_parent: string,
  excerpt: {
    raw: string
  },
  liste_enfants: PageChildType[],
  pratiques_structure: any[],
  pratique: any,
  news: NewsNunType[],
}

export const nullPageDetail: PageDetailType = {
  ...nullSeoMeta,
  title: '',
  content: [],
  slug: '',
  link: '',
  label: {},
  rencontre_background: '',
  featured_media: {
    src: '',
    legend: ''
  },
  template: '',
  niveau: 0,
  sidebar_content: [],
  redirection: '',
};

export const nullPageDetailPreview: PageDetailPreviewType = {
  ...nullSeoMeta,
  id: 0,
  title: '',
  content: [],
  description: '',
  slug: '',
  link: '',
  label: {},
  rencontre_background: '',
  featured_media: {
    src: '',
    legend: ''
  },
  template: '',
  niveau: 0,
  sidebar_content: [],
  redirection: '',
  title_parent: '',
  slug_parent: '',
  liste_enfants: [
    {
      menu_order: 0,
      object_id: 0,
      type_name: '',
      object_slug: '',
      title: '',
      description: '',
      featured_media: {
        src: ''
      },
      texte_cta: '',
      liste_enfants: [],
      types_public: [],
    }
  ],
  pratiques_structure: [],
  pratique: nullPratique,
  news: [],
};