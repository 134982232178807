// @flow

import { STATUS_DEFAULT, STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import { FETCH_CATEGORIES_START, FETCH_CATEGORIES_SUCCESS, FETCH_CATEGORIES_FAILURE } from 'actions/categoriesActions';

import type { CategoryType } from 'types/Article';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  categories: Array<CategoryType>,
  status: Status
};

const initialState: State = {
  categories: [],
  status: STATUS_DEFAULT
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_CATEGORIES_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload.categories,
        status: STATUS_SUCCESS
      };
    case FETCH_CATEGORIES_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
