// @flow
import type { ArticleType } from 'types/Article';

export const FETCH_TAG_ARTICLE_START = 'FETCH_TAG_ARTICLE_START';
export const FETCH_TAG_ARTICLE_SUCCESS = 'FETCH_TAG_ARTICLE_SUCCESS';
export const FETCH_TAG_ARTICLE_FAILURE = 'FETCH_TAG_ARTICLE_FAILURE';

export type FETCH_TAG_ARTICLE_START_ACTION = {
  type: 'FETCH_TAG_ARTICLE_START',
  payload: {
    slug: string,
    page: number,
    per_page: number
  }
};

export type FETCH_TAG_ARTICLE_SUCCESS_ACTION = {
  type: 'FETCH_TAG_ARTICLE_SUCCESS',
  payload: {
    tagArticles : ArticleType[],
    tagArticleNumber: number,
    tag: {
      id: number,
      name: string,
      slug: string,
      source: string,
    }
  },
};

export type FETCH_TAG_ARTICLE_FAILURE_ACTION = {
  type: 'FETCH_TAG_ARTICLE_FAILURE'
};

export type Action = FETCH_TAG_ARTICLE_START_ACTION
                  | FETCH_TAG_ARTICLE_SUCCESS_ACTION
                  | FETCH_TAG_ARTICLE_FAILURE_ACTION;
                  
export function fetchTagArticle(slug: string, page: number, per_page: number):
  FETCH_TAG_ARTICLE_START_ACTION {
  return {
    type: FETCH_TAG_ARTICLE_START,
    payload: { slug, page, per_page }
  };
}

export function tagArticleFetched(tagArticles: ArticleType[], tagArticleNumber: number, tag: {id: number, name: string, slug: string, source: string}):
  FETCH_TAG_ARTICLE_SUCCESS_ACTION {
  return {
    type: FETCH_TAG_ARTICLE_SUCCESS,
    payload: { tagArticles, tagArticleNumber, tag }
  };
}

export function tagArticleFetchError(error: any): FETCH_TAG_ARTICLE_FAILURE_ACTION {
  return {
    type: FETCH_TAG_ARTICLE_FAILURE
  };
}
