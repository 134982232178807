export function setBodyStyle(styles) {
  if (!document.body) {
    return;
  }

  for (const i in styles) { // eslint-disable-line
    document.body.style[i] = styles[i];
  }
}

export function setBodyClassName(className) {
  if (!document.body) {
    return;
  }
  document.body.className = className;
}

/**
 * Supprime tous les styles sur le body
 *
 * Attention, usage de cssText, sinon bug dans Edge cf :
 * https://devtidbits.com/2016/06/12/assignment-to-read-only-properties-is-not-allowed-in-strict-mode/
 */
export function resetBodyStyle() {
  if (!document.body) {
    return;
  }

  document.body.style.cssText = null;
}
