// @flow

import {
  FILTER_OPTIONS_SET_QUERY,
  FILTER_OPTIONS_SET_COMPETITON,
  FILTER_OPTIONS_SET_PRACTICE,
  FILTER_OPTIONS_SET_DISTANCE,
  FILTER_OPTIONS_SET_ANNEE,
  FILTER_OPTIONS_SET_EQUIPE,
  FILTER_OPTIONS_SET_NIVEAU,
  REINITIALISE_SEARCH,
  FILTER_OPTIONS_ACTIVATE_DISTANCE,
  FILTER_OPTIONS_SET_DEFAULT_SEASON,
  FILTER_OPTIONS_SET_POSTES,
} from 'actions/filterOptionsActions';

import type { ActionType } from 'types/Actions';

export type State = {
  query: string,
  competitions: Array<string>,
  practices: Array<string>,
  annees: Array<string>,
  niveaux: Array<string>,
  equipes: Array<string>,
  distance: string,
  activateDistance: boolean,
  selectedPostes: Array<string>,
  defaultSeason: string,
};

const initialState: State = {
  query: '',
  competitions: [],
  practices: [],
  annees: [],
  niveaux: [],
  equipes: [],
  distance: '',
  activateDistance: false,
  selectedPostes: [],
  defaultSeason: '',
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FILTER_OPTIONS_SET_QUERY:
      return {
        ...state,
        query: action.payload.query
      };
    case FILTER_OPTIONS_SET_COMPETITON:
      return {
        ...state,
        competitions: action.payload.competitions
      };
    case FILTER_OPTIONS_SET_PRACTICE:
      return {
        ...state,
        practices: action.payload.practices
      };
    case FILTER_OPTIONS_SET_DISTANCE:
      return {
        ...state,
        distance: action.payload.distance
      };
    case FILTER_OPTIONS_SET_ANNEE:
      return {
        ...state,
        annees: action.payload.annees
      };
    case FILTER_OPTIONS_SET_NIVEAU:
      return {
        ...state,
        niveaux: action.payload.niveaux
      };
    case FILTER_OPTIONS_SET_EQUIPE:
      return {
        ...state,
        equipes: action.payload.equipes
      };
    case REINITIALISE_SEARCH:
      return {
        ...state,
        query: '',
        competitions: [],
        practices: [],
        distance: '',
        activateDistance: false
      };
    case FILTER_OPTIONS_ACTIVATE_DISTANCE:
      return {
        ...state,
        activateDistance: action.payload.activateDistance
      };
    case FILTER_OPTIONS_SET_DEFAULT_SEASON:
      return {
        ...state,
        defaultSeason: action.payload.defaultSeason
      };
    case FILTER_OPTIONS_SET_POSTES:
      return {
        ...state,
        selectedPostes: action.payload.poste
      };
    default:
      return state;
  }
}
