// @flow
import { SEOPages, nullPage } from 'constants/seo';
import type { SEOPageType, paramsType } from 'constants/seo';

export function getMetaBySlug(slug: string, params?: paramsType): SEOPageType {
  
  const page = params ? 
    SEOPages.find(p => [p.slug(params), `/${p.slug(params)}`, `/${p.slug(params)}/`].includes(slug.toLowerCase())) : 
    SEOPages.find(p => [p.slug(), `/${p.slug()}`, `/${p.slug()}/`].includes(slug.toLowerCase()));
  return page || nullPage;
}
