// @flow
import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import ArticleApi from 'api/article/ArticleApi';
import {
  FETCH_ARTICLE_START,
  FETCH_ARTICLE_START_PREVIEW,
  articleFetched,
  articleFetchError,
  articleFetchNotFound,
} from 'actions/articleActions';
import type {
  FETCH_ARTICLE_START_ACTION,
  FETCH_ARTICLE_START_PREVIEW_ACTION,
} from 'actions/articleActions';

import {
  FETCH_ALL_ARTICLES_START,
  articlesFetched,
  articlesFetchError,
} from 'actions/articlesActions';
import type { FETCH_ALL_ARTICLES_START_ACTION } from 'actions/articlesActions';

import {
  FETCH_CATEGORIES_START,
  categoriesFetched,
  categoriesFetchError
} from 'actions/categoriesActions';
import type { FETCH_CATEGORIES_START_ACTION } from 'actions/categoriesActions';

export default function(apiExecutor: ApiExecutorType, ffrStaticApiExecutor: ApiExecutorType) {
  const articleApi = new ArticleApi(apiExecutor);
  // const articleApiStatic = new ArticleApi(ffrStaticApiExecutor);

  return function* articleSaga(): GeneratorType {
    yield takeLatest(FETCH_ARTICLE_START, fetchArticle);
    yield takeLatest(FETCH_ALL_ARTICLES_START, fetchArticles);
    yield takeLatest(FETCH_ARTICLE_START_PREVIEW, fetchArticlePreview);
    yield takeLatest(FETCH_CATEGORIES_START, fetchArticleCategories);
  };

  function* fetchArticle(action: FETCH_ARTICLE_START_ACTION): Saga<void> {
    try {
      const { slug } = action.payload;
      const article = yield call(articleApi.fetchArticleBySlug, slug);
      yield put(articleFetched(article, true));
    } catch (e) {
      if (e.message === 'NOT FOUND') {
        yield put(articleFetchNotFound());
      } else {
        yield put(articleFetchError(e));
      }
    }
  }

  function* fetchArticles(action: FETCH_ALL_ARTICLES_START_ACTION): Saga<void> {
    try {
      const { category } = action.payload;
      const articles = yield call(articleApi.fetchArticlesByCategory, 1, 4, category);
      yield put(articlesFetched(articles));
    } catch (e) {
      yield put(articlesFetchError(e));
    }
  }

  function* fetchArticlePreview(action: FETCH_ARTICLE_START_PREVIEW_ACTION): Saga<void> {
    try {
      const { id } = action.payload;
      const article = yield call(articleApi.fetchArticlePreviewById, id);
      yield put(articleFetched(article, true));
    } catch (e) {
      yield put(articleFetchError(e));
    }
  }

  function* fetchArticleCategories(action: FETCH_CATEGORIES_START_ACTION): Saga<void> {
    try {
      const categories = yield call(articleApi.fetchTmpCategories);
      yield put(categoriesFetched(categories));
    } catch (e) {
      yield put(categoriesFetchError(e));
    }
  }
}
