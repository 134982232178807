// @flow
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import TeamHeader from 'components/team/TeamHeader';

import type { StateProps } from 'components/team/TeamHeader';
import type { StateType } from 'types/Actions';

export default withRouter(connect(
    (state: StateType): StateProps => ({
      adresse: state.settingsState.adresse,
      banniere: state.settingsState.banniere,
      calendarResults: state.teamCalendarState.nextMatches,
      calendarStatus: state.teamCalendarState.status,
      clubName: state.settingsState.structure,
      compte_joueurs: state.pageListState.compte_joueurs,
      description: state.pageListState.description,
      excerpt: state.pageListState.excerpt,
      featured_media: state.pageListState.featured_media,
      label: state.pageListState.label,
      liens_rs: state.settingsState.liens_rs,
      liste_enfants: state.pageListState.liste_enfants,
      logo: state.settingsState.logo,
      meta_description: state.pageListState.meta_description,
      meta_title: state.pageListState.meta_title,
      placeholder: state.settingsState.placeholder,
      pratique: state.pageListState.pratique,
      rencontresLive: state.rencontreLiveState.rencontres ? Object.keys(state.rencontreLiveState.rencontres).length : 0,

      status: state.pageListState.status,
      teamlist: state.pageListState.liste_freres,
      telephone: state.settingsState.telephone,
      template: state.pageListState.template,
      title: state.pageListState.title,
      historique: state.pageListState.competitions_saisons,
      currentSeason: state.seasonsState.lastSeason,
    }),
    null
  )(TeamHeader));
