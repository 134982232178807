// @flow

import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS, STATUS_DEFAULT } from 'constants/statusConstants';
import { FETCH_PARTNER_START, FETCH_PARTNER_SUCCESS, FETCH_PARTNER_FAILURE } from 'actions/partnerActions';

import type { PartnerType } from 'types/Partner';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  partners: PartnerType[],
  status: Status
};

const initialState: State = {
  partners: [],
  status: STATUS_DEFAULT
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_PARTNER_START:
      return {
        ...state,
        partners: action.payload.partners,
        status: STATUS_LOADING
      };
    case FETCH_PARTNER_SUCCESS:
      return {
        ...state,
        partners: action.payload.partners,
        status: STATUS_SUCCESS
      };
    case FETCH_PARTNER_FAILURE:
      return {
        ...state,
        partners: action.payload.partners,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
