// @flow
import React from 'react';

import Button from 'components/fragments/button/Button';

type Props = {
  content: any,
  hideModal: () => void
};

function ShareContentSuccessModalContent({ content, hideModal }: Props) {
  let message = `Votre document sera publié prochainement si son contenu est conforme.
    Vous serez alors averti par email de sa mise en ligne.`;
  if (content && content.nbFiles && content.nbFiles > 1) {
    message = `Vos documents seront publiés prochainement si leur contenu est conforme.
    Vous serez alors averti par email de leur mise en ligne.`;
  }

  return (
    <div className="modal-content box modal-share-content modal-share-content--with-bg modal-share-content--end ">
      <div className="modal__header">
        <i role="button" tabIndex={0} className="icon icon-close js-closeModal" onClick={hideModal} />
        <h3 className="ft-h2 mb-2">Merci de votre participation</h3>
        <p>
          {message}
          <br />À bientôt !
        </p>
        <Button label="Revenir aux photos" onClick={hideModal} isFull />
      </div>
    </div>
  );
}

export default ShareContentSuccessModalContent;
