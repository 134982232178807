// @flow
import type { BlocContent } from 'types/NewsletterBloc';
import type { SEOMetaType } from 'types/SEOType';
import type { Pratique } from 'types/Club';
import { nullSeoMeta } from 'types/SEOType';
import { nullPratique } from 'types/Club';

export type CardType = {
  menu_order: number,
  object_id: number,
  type_name: string,
  object_slug: string,
  title: string,
  description: string,
  featured_media: {
    src: string
  },
  texte_cta: string,
  types_public: string[],
  liste_enfants?: CardType[]
};

export type NewsNunType = {
  title: string,
  date: Date | string,
  link: string,
  slug: string,
  illustration: string,
  label: {
    id: number,
    name: string,
    slug: string
  }
};

export type YoutubeVideoType = {
  kind: string,
  etag: string,
  id: string,
  snippet: {
    publishedAt: string,
    channelId: string,
    title: string,
    description: string,
    thumbnails: {
      standard: {
        url: string,
        width: number,
        height: number
      },
      maxres: {
        url: string,
        width: number,
        height: number
      }
    },
    channelTitle: string,
    tags: string[],
    categoryId: string,
    liveBroadcastContent: string,
    localized: {
      title: string,
      description: string
    },
    defaultAudioLanguage: string
  },
  contentDetails: {
    duration: string,
    dimension: string,
    definition: string,
    caption: string,
    licensedContent: boolean,
    projection: string
  }
};

type PhotosType = {
  id: string,
  urlPhoto: string,
  urlPhotoSmall: string,
  agreementSiteClub: boolean,
  agreementEcosystem: boolean,
  source: string
};
export type PageListType = SEOMetaType & {
  title: string,
  description: BlocContent[],
  excerpt: string,
  slug: string,
  typeFlow: string,
  link: string,
  label: {
    id: number,
    name: string,
    slug: string
  },
  featured_media: {
    src: string,
    legend: string
  },
  rencontre_background: string,
  liste_enfants: CardType[],
  liste_freres: CardType[],
  parent: number,
  template: string,
  sidebar_content: BlocContent[],
  niveau: number,
  classe_age: string,
  redirect_resultats: string,
  redirect_competitions: string,
  genre: string,
  news: NewsNunType[],
  redirection: string,
  competitions: number[],
  compte_joueurs: number,
  effectif: {
    id: string,
    numeroAffiliation: string,
    nom: string,
    nom_f: string,
    poste: string,
    photo: PhotosType
  }[],
  pratique: Pratique,
  effectifMoins: string | any[] | string[],
  competitions_saisons: {
    [key: string]: {
      competitions?: { id: string, identifiant: string, nom: string }[],
      effectifMoins: string | number[] | string[]
    }
  },
  albums_saisons: {
    term_id: number,
    name: string,
    slug: string
  }[],
  albums_filtres: {
    term_id: number,
    name: string,
    slug: string
  }[]
};

export type RawPageListType = SEOMetaType & {
  title: {
    raw: string
  },
  link: string,
  content: {
    data: BlocContent[]
  },
  excerpt: {
    raw: string
  },
  slug: string,
  label: {
    id: number,
    name: string,
    slug: string
  },
  rencontre_background: string,
  featured_media: {
    src: string,
    legend: string
  },
  liste_enfants: CardType[],
  liste_freres: CardType[],
  meta: {
    competitions_ovale: number[],
    classe_age: string,
    genre: string,
    compte_joueurs: number,
    effectifMoins: string | number[] | string[]
  },
  competitions_saisons: {
    saisonId: number | string,
    competitions: { id: string, identifiant: string }[],
    effectifMoins: string | string[] | number[]
  }[],
  effectif: {
    id: string,
    numeroAffiliation: string,
    nom: string,
    nom_f: string,
    poste: string,
    photos: PhotosType
  }[],
  parent: number,
  template: string,
  sidebar_content: {
    data: BlocContent[]
  },
  niveau: number,
  redirect: {
    type: string,
    postid: string,
    title: string,
    url: string
  },
  news: NewsNunType[],
  pratique: Pratique,
  albums_saisons: {
    term_id: number,
    name: string,
    slug: string
  }[],
  albums_filtres: {
    term_id: number,
    name: string,
    slug: string
  }[]
};

export const nullPageList: PageListType = {
  ...nullSeoMeta,
  title: '',
  link: '',
  typeFlow: 'PageListType',
  description: [
    {
      tagName: 'p',
      type: 'element',
      attributes: [],
      children: [
        {
          type: 'text',
          text: 'Chargement du contenu de la page...'
        }
      ]
    }
  ],
  excerpt: '',
  slug: '',
  label: {
    id: 0,
    name: '',
    slug: ''
  },
  rencontre_background: '',
  featured_media: {
    src: '',
    legend: ''
  },
  liste_enfants: [
    {
      menu_order: 0,
      object_id: 0,
      type_name: '',
      object_slug: '',
      title: '',
      description: '',
      featured_media: {
        src: ''
      },
      texte_cta: '',
      types_public: [],
      liste_enfants: []
    }
  ],
  liste_freres: [
    {
      menu_order: 0,
      object_id: 0,
      type_name: '',
      object_slug: '',
      title: '',
      description: '',
      featured_media: {
        src: ''
      },
      texte_cta: '',
      types_public: [],
      liste_enfants: []
    }
  ],
  genre: '',
  template: '',
  classe_age: '',
  redirect_resultats: '',
  redirect_competitions: '',
  parent: 1,
  sidebar_content: [],
  niveau: 0,
  news: [],
  redirection: '',
  competitions: [],
  compte_joueurs: 0,
  effectif: [],
  pratique: nullPratique,
  competitions_saisons: {},
  effectifMoins: ([]: number[]),
  albums_saisons: [],
  albums_filtres: []
};
