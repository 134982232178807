// @flow
import type { RawSeasonsListType, SeasonListType } from 'types/Seasons';
import { nullSeasonsList } from 'types/Seasons';
import { nullClassic } from 'types/GraphQL';
import { mergeIntoDefault } from 'utils/objectUtils';
import type { ApolloResponseType } from 'types/ApolloType';

export function convertSeasonsListFromApi(rawSeasons: RawSeasonsListType): SeasonListType {
  if (!rawSeasons) {
    return nullSeasonsList;
  }

  const { id, slug, name, rencontres, competitions, resultats } = rawSeasons;

  return mergeIntoDefault(nullSeasonsList, {
    id,
    slug,
    name,
    rencontres,
    competitions,
    resultats,
  });
}

export const convertSeasonsFromGraph = (rawGraphResponseType: ApolloResponseType) => {
  const nullSeasons = [nullClassic];

  if (!rawGraphResponseType || !rawGraphResponseType.data) {
    return { nullSeasons, lastSeason: nullClassic };
  }
  const { seasons, lastSeason } = rawGraphResponseType.data;

  return {
    seasons: seasons.filter(({ id }) => parseInt(id, 10) > 22).sort((a, b) => parseInt(b.id, 10) - parseInt(a.id, 10)),
    lastSeason,
  };
};

export const convertCompetitionsSeasonsFromGraph = (rawGraphResponseType: ApolloResponseType) => {
  const nullCompetitionsSeasons = [nullClassic];

  if (!rawGraphResponseType || !rawGraphResponseType.data) {
    return nullCompetitionsSeasons;
  }

  return rawGraphResponseType.data.Competitions;
};