// @flow
import type { RawTagType, TagType } from 'types/Tag';
import { mergeIntoDefault } from 'utils/objectUtils';

const nullTag: TagType = {
  id: -1,
  name: ''
};

export function convertTagFromApi(rawTag: RawTagType): TagType {
  if (!rawTag) {
    return nullTag;
  }

  const { id, name } = rawTag;

  return mergeIntoDefault(nullTag, {
    id,
    name
  });
}
