// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';

import { convertTagFromApi } from 'services/Tag';

function TagApi(executor: ApiExecutorType) {
  return { fetchTags };

  function fetchTags() {
    return executor.get('/wp-json/tribe/events/v1/tags').then(response => response.tags.map(a => convertTagFromApi(a)));
  }
}

export default TagApi;
