// @flow
import React from 'react';

import ShareContentForm from 'containers/modals/ShareContentFormContainer';

type Props = {
  content: any,
  hideModal: () => void
};

function ShareContentModalContent({ content, hideModal }: Props) {
  return (
    <div className="modal-content box modal-share-content modal-share-content--form">
      <div className="modal__header">
        <i role="button" tabIndex={0} className="icon icon-close js-closeModal" onClick={hideModal} />
        <h3 className="ft-700">Partager vos contenus</h3>
      </div>
      <div className="modal__wrapper">
        <ShareContentForm email={content && content.email} title={content && content.title ? content.title : ''} />
      </div>
    </div>
  );
}

export default ShareContentModalContent;
