// @flow
import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import SidebarApi from 'api/sidebar/SidebarApi';
import { FETCH_SIDEBAR_START, sidebarGenericFetched, sidebarGenericFetchError } from 'actions/sidebarActions';

export default function(apiExecutor: ApiExecutorType) {
  const sidebarApi = new SidebarApi(apiExecutor);

  function* sidebarSaga(): GeneratorType {
    yield takeLatest(FETCH_SIDEBAR_START, fetchGenericSidebar);
  };

  function* fetchGenericSidebar(): Saga<void> {
    try {
      const sidebar = yield call(sidebarApi.fetchGenericSidebar);
      yield put(sidebarGenericFetched(sidebar));
    } catch (e) {
      yield put(sidebarGenericFetchError(e));
    }
  }

  return { sidebarSaga, fetchGenericSidebar };
}
