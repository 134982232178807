// @flow

import {
  STATUS_DEFAULT,
  STATUS_LOADING,
  STATUS_FAILURE,
  STATUS_SUCCESS
} from 'constants/statusConstants';
import {
  FETCH_RANKING_START,
  FETCH_RANKING_SUCCESS,
  FETCH_RANKING_FAILURE
} from 'actions/team/rankingActions';
import { nullRanking } from 'types/Ranking';

import type { CompetitionRankingType } from 'types/Ranking';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  ranking: CompetitionRankingType[],
  status: Status
};

const initialState: State = {
  ranking: [nullRanking],
  status: STATUS_DEFAULT
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_RANKING_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_RANKING_SUCCESS:
      return {
        ...state,
        ranking: action.payload.ranking,
        status: STATUS_SUCCESS
      };
    case FETCH_RANKING_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
