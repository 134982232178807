// @flow
import React, { PureComponent } from 'react';

import { getMetaBySlug } from 'utils/seoUtils';

import Breadcrumb from 'components/fragments/Breadcrumb';
import TeamMenuDropdownMenu from 'containers/teammenu/TeamMenuDropDownMenuContainer';
import SubMenuHeader from 'components/header/SubMenuHeader';
import BackgroundImage from 'containers/fragments/BackgroundImageContainer';
import Loading from 'components/fragments/Loading';
import LiveScore from 'containers/fragments/LiveScoreContainer';

import { withHelmet } from 'infrastructure/seo/Helmet';

import { teamSubmenuHeaders } from 'constants/menuConstants';
import { STATUS_SUCCESS, STATUS_FAILURE, STATUS_LOADING, STATUS_DEFAULT } from 'constants/statusConstants';

import type { BlocContent } from 'types/NewsletterBloc';
import type { Status } from 'types/Status';
import type { CardType } from 'types/PageList';
import type { Pratique } from 'types/Club';
import type { ResultCalendarContainerType } from 'types/Result';
import type { liensRsType } from 'types/Settings';
import type { RouterHistory } from 'react-router-dom';
import { MIN_EFFECTIF } from 'constants/TeamMenuConstants';

export type StateProps = {
  adresse: {
    complement: string,
    localite: string,
    codePostal: string,
    voie: string,
    lieuDitBoitePostale: string,
    paysNom: string,
    immeuble: string,
    codeInsee: string,
    position: {
      type: string,
      coordinates: number[]
    }
  },
  calendarResults: ResultCalendarContainerType,
  calendarStatus: Status,
  banniere: string,
  clubName: string,
  compte_joueurs: number,
  description: BlocContent[],
  excerpt: string,
  featured_media: {
    src: string,
    legend: string
  },
  label: {
    id: number,
    name: string,
    slug: string
  },
  liens_rs: liensRsType,
  liste_enfants: CardType[],
  logo: string,
  meta_description: string,
  meta_title: string,
  placeholder: string,
  pratique: Pratique,
  rencontresLive: number,
  status: Status,
  teamlist: CardType[],
  telephone: string,
  template: string,
  title: string,
  currentSeason: { id: string, nom: string },

  historique: {
    [key: string]: {
      competitions?: { id: string, identifiant: string, nom: string }[],
      effectifMoins: string | string[] | number[]
    }
  }
};

type Props = { slug: string, history: RouterHistory } & StateProps;

class TeamHeader extends PureComponent<Props> {
  _isLoadingStatus = (status: Status): boolean => status === STATUS_DEFAULT || status === STATUS_LOADING;

  _isReadyStatus = (status: Status): boolean => status === STATUS_SUCCESS || status === STATUS_FAILURE;

  teamChange = (team: string) => {
    const { history } = this.props;
    history.push(`/equipes/${team}`);
  };

  _renderSubHeader = () => {
    const {
      slug,
      liste_enfants,
      pratique,
      template,
      historique,
      compte_joueurs,
      currentSeason,
      calendarStatus,
      calendarResults
    } = this.props;

    let menu =
      template !== 'equipe' && Object.keys(historique).length === 0
        ? []
        : teamSubmenuHeaders.filter(item => {
            if (item.object_slug === 'calendrier') {
              return !!historique[currentSeason.id] && this._isReadyStatus(calendarStatus) && Object.keys(calendarResults).length > 0;
            }

            if (item.object_slug === 'effectif' && (!compte_joueurs || compte_joueurs < MIN_EFFECTIF)) {
              return false;
            }

            return true;
          });

    let enfant_pratique_done = false;
    if (liste_enfants.length > 0) {
      liste_enfants.map(enfant => {
        if (!enfant_pratique_done && enfant.object_id !== 0 && enfant.object_slug.match('ratique')) {
          enfant_pratique_done = true;
          menu = menu.concat([enfant]);
        }
        return '';
      });
    }

    if (!enfant_pratique_done && pratique && pratique.id !== '') {
      const enfant_ok = liste_enfants.filter(enfant => {
        return enfant.object_slug.match('ratique');
      });

      if (enfant_ok.length === 0) {
        const infos_pratiques = [
          {
            menu_order: 100,
            object_slug: 'informations-pratiques',
            title: 'Informations pratiques',
            description: '',
            featured_media: { src: '' },
            texte_cta: '',
            object_id: 0,
            type_name: 'endur',
            types_public: []
          }
        ];
        menu = menu.concat(infos_pratiques);
      }
    }

    return <SubMenuHeader subMenus={menu} subMenuPath={`/equipes/${slug}`} status={STATUS_SUCCESS} niveauMenu={3} />;
  };

  render() {
    const { title, featured_media, excerpt, slug, teamlist, banniere, status, rencontresLive } = this.props;
    let selectedTeam = false;

    if (teamlist && teamlist.length > 0) {
      selectedTeam = teamlist.filter(team => team.object_slug === slug);
    }

    if (this._isLoadingStatus(status)) {
      return <Loading />;
    }

    let classBanner = 'banner';
    if (rencontresLive) {
      classBanner = 'banner banner--live-score';
    }
    return (
      <section className={classBanner}>
        <BackgroundImage className="banner__thumbnail img-blue-dark" illustration={featured_media.src || banniere} baliseImg={false} />
        <div className="container">
          <Breadcrumb elements={[{ name: 'accueil', to: '/' }, { name: 'Équipes', to: '/equipes' }, title]} isDark />
          {teamlist && teamlist.length > 0 && selectedTeam && selectedTeam.length > 0 && (
            <TeamMenuDropdownMenu
              hiddenMenuElements={teamlist}
              isMobile={false}
              id={6}
              level={'h1'}
              activeTeam={selectedTeam[0]}
              switchTeam={this.teamChange}
            />
          )}
          {(!teamlist || teamlist.length === 0) && <h1 className="ft-h2">{title}</h1>}
          {excerpt && <p className="banner__desc">{excerpt}</p>}
        </div>
        {this._renderSubHeader()}
        <LiveScore />
      </section>
    );
  }
}

export default withHelmet(TeamHeader)((Helmet, pageProps) => {
  // @TODO: Check if meta is Hydrated
  const {
    adresse: { voie, lieuDitBoitePostale, complement, codePostal, localite, position },
    banniere,
    clubName,
    liens_rs,
    logo,
    meta_description,
    meta_title,
    placeholder,
    telephone,
    title
  } = pageProps;

  const meta = getMetaBySlug(`equipes/equipe`);
  const ogimage = pageProps.featured_media.src;
  const ogimagetitle = pageProps.featured_media.title;

  const sameAs = Object.keys(liens_rs)
    .map(key => {
      return liens_rs[key];
    })
    .filter(value => {
      return typeof value === 'string' && value !== '';
    })
    .map(link => {
      return typeof link === 'string' && `"${link}"`;
    })
    .join(', ');

  const internationalPhoneNumber = telephone.slice(0, 1) === '0' ? `+33${telephone.substr(1)}` : telephone;

  return (
    <Helmet>
      <title>{meta_title}</title>
      <meta name={'description'} content={meta_description} />
      <meta name={'keywords'} content={meta.keywords(title, clubName)} />
      <meta property={'og:url'} content={window.location.href} />
      <meta property={'og:title'} content={meta_title} />
      <meta property={'og:description'} content={meta_description} />
      {ogimage && <meta property={'og:image'} content={ogimage} />}
      {ogimage && <meta property={'og:image:url'} content={ogimage} />}
      {ogimage && ogimagetitle && <meta property={'og:image:title'} content={ogimagetitle} />}
      <link rel={'canonical'} href={window.location.href} />
      <script type="application/ld+json">
        {`[
            {
              "@context": "https://schema.org",
              "@type": "SportsActivityLocation",
              "name": "${clubName || 'Mon site club - FFR'}",
              "url": "https://${window.location.host}",
              "image": "${placeholder ?? banniere}",
              "logo": "${logo}",
              "sameAs": [${sameAs}],
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "${voie ?? ''} ${complement ?? ''} ${lieuDitBoitePostale ?? ''}",
                "addressLocality": "${localite ?? ''}",
                "postalCode": "${codePostal ?? ''}",
                "addressCountry": "FR"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": ${position && position.coordinates && position.coordinates[1] ? position.coordinates[1] : ''},
                "longitude": ${position && position.coordinates && position.coordinates[0] ? position.coordinates[0] : ''}
              },
              "telephone": "${internationalPhoneNumber}"
            },  
            {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "url": "https://${window.location.host}",
              "potentialAction": {
                "@type": "SearchAction",
                "target": "https://${window.location.host}/recherche?s={search_term_string}",
                "query-input": "required name=search_term_string"
              }
            }
          ]`}
      </script>
    </Helmet>
  );
});
