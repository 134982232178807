// @flow
import { takeLatest, put, call, select, take } from 'redux-saga/effects';

import type { Saga } from 'redux-saga';

import type { GeneratorType } from 'sagas/root';
import type { FETCH_RENCONTRE_LIVE_START_ACTION } from 'actions/rencontreLiveActions';

import { FETCH_SETTINGS_SUCCESS } from 'actions/appActions';

import {
  FETCH_RENCONTRE_LIVE_START,
  rencontreLiveFetched,
  rencontreLiveFetchError,
} from 'actions/rencontreLiveActions';

import { STATUS_SUCCESS } from 'constants/statusConstants';

import { fetchLiveRencontresFromGraph, fetchRencontreLiveFull } from 'api/graphApi/RencontreApi';

export default function() {
  return function* rencontreSaga(): GeneratorType {
    yield takeLatest(FETCH_RENCONTRE_LIVE_START, fetchLiveRencontre);
  };

  function* fetchLiveRencontre(action: FETCH_RENCONTRE_LIVE_START_ACTION): Saga<void> {
    try {
      const { settingsState: { status } } = yield select();

      if (status !== STATUS_SUCCESS) {
        yield take(FETCH_SETTINGS_SUCCESS);
      }

      const { settingsState: { club_id } } = yield select();

      const allRencontreIds = yield call(fetchLiveRencontresFromGraph, parseInt(club_id, 10));

      if (allRencontreIds && allRencontreIds.length > 0) {
        const { rencontreLiveState: { rencontres: rencontreReducers } } = yield select();

        const rencontreIds = allRencontreIds
          .filter((rencontre) => !Object.prototype.hasOwnProperty.call(rencontreReducers, rencontre));

        if (rencontreIds.length > 0) {
          const rencontres = yield call(fetchRencontreLiveFull, rencontreIds);

          yield put(rencontreLiveFetched(rencontres));
        }
      }
    } catch (e) {
      yield put(rencontreLiveFetchError(e.message));
    }
  }
}
