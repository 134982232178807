export function getConfSso(){
  const conf = {
    urlEspacePrive: 'https://dev.website.ffr.nextmap.io/tableau-de-bord'
  };

  if (process.env.NODE_ENV === 'production') {
    let hostname = 'site-temoin.ffr.fr';
    if (typeof window !== "undefined" && window && window.location && window.location.hostname !== 'localhost') {
      hostname = window.location.hostname;
    }
    
    const clubId = hostname.split('.')[0];
    const source = hostname.split('.').splice(1).join('.');

    if (source !== 'ffr.nextmap.io' && clubId !== 'site-temoin' && clubId !== 'liguetest') {
      conf.urlEspacePrive = 'https://www.ffr.fr/tableau-de-bord';
    }
  }
  return conf;
} 

export function getInitSsoOptions() {
  let ssoOptions =  {
    url: "https://connexion.ffr.fr/auth",
    realm: "FFRpp",
    clientId: "ffr_ligues_pp",
    silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
  };
  
  if (process.env.NODE_ENV === 'production') {
    let hostname = 'site-temoin.ffr.fr';
    if (typeof window !== "undefined" && window && window.location && window.location.hostname !== 'localhost') {
      hostname = window.location.hostname;
    }
    
    const clubId = hostname.split('.')[0];
    const source = hostname.split('.').splice(1).join('.');

    if (source !== 'ffr.nextmap.io' && clubId !== 'site-temoin' && clubId !== 'liguetest') {
      ssoOptions.realm = 'FFR';
      ssoOptions.clientId = 'ffr_clubs';
    }
  }

  return ssoOptions;
}