// @flow

import type { ResultCalendarContainerType } from 'types/Result';

export const FETCH_TEAM_CALENDAR_START = 'FETCH_TEAM_CALENDAR_START';
export const FETCH_TEAM_CALENDAR_SUCCESS = 'FETCH_TEAM_CALENDAR_SUCCESS';
export const FETCH_TEAM_CALENDAR_FAILURE = 'FETCH_TEAM_CALENDAR_FAILURE';

export type FETCH_TEAM_CALENDAR_START_ACTION = {
  type: 'FETCH_TEAM_CALENDAR_START',
  payload: {
    clubID: string,
    competitions: string[],
  }
};

export type FETCH_TEAM_CALENDAR_FAILURE_ACTION = {
  type: 'FETCH_TEAM_CALENDAR_FAILURE',
};

export type FETCH_TEAM_CALENDAR_SUCCESS_ACTION = {
  type: 'FETCH_TEAM_CALENDAR_SUCCESS',
  payload: {
    nextMatches: ResultCalendarContainerType,
  },
};

export type Action = FETCH_TEAM_CALENDAR_START_ACTION
                  | FETCH_TEAM_CALENDAR_FAILURE_ACTION
                  | FETCH_TEAM_CALENDAR_SUCCESS_ACTION;


export function fetchTeamCalendarStart(clubID: string, competitions: string[]): FETCH_TEAM_CALENDAR_START_ACTION {
  return {
    type: FETCH_TEAM_CALENDAR_START,
    payload: {
      clubID,
      competitions,
    }
  };
}

export function fetchTeamCalendarSuccess(nextMatches: ResultCalendarContainerType): FETCH_TEAM_CALENDAR_SUCCESS_ACTION {
  return {
    type: FETCH_TEAM_CALENDAR_SUCCESS,
    payload: { nextMatches },
  };
}

export function fetchTeamCalendarError(): FETCH_TEAM_CALENDAR_FAILURE_ACTION {
  return {
    type: FETCH_TEAM_CALENDAR_FAILURE
  };
}
