// @flow

import { getCSS, MANIFEST_URL } from 'constants/api';

const key = '__app';

type WindowAppType = {
  placeholder: string,
};

const nullWindowApp: WindowAppType = {
  placeholder: '',
};

export function initWindowApp() {
  window[key] = window[key] || nullWindowApp;
}

export function initCss() {
  const stylesheet = getCSS();
  const cssLink = document.createElement('link');
  cssLink.setAttribute("rel", "stylesheet");
  cssLink.setAttribute("type", "text/css");
  cssLink.setAttribute("href", stylesheet);

  document.head && document.head.appendChild(cssLink);
}

export function initManifest() {
  const manifest = MANIFEST_URL;
  const manifestLink = document.createElement('link');
  manifestLink.setAttribute("rel", "manifest");
  manifestLink.setAttribute("href", manifest);

  document.head && document.head.appendChild(manifestLink);
}

export function getWindowApp(subkey: ?string) {
  try {
    if (subkey) {
      return window[key][subkey];
    }

    return window[key];
  } catch (e) {
    return false;
  }
}

export function setWindowApp(subkey: string, value: any): void {
  try {
    window[key][subkey] = value;
  } catch (e) {}
}
