// @flow

import { APP_SET_SHOW_ALERT_INFO, APP_USER_SUBSCRIBE_NEWSLETTER } from 'actions/appActions';

import type { ActionType } from 'types/Actions';

export type State = {
  showAlertInfo: string,
  newsletterSubscribed: boolean
};

const initialState: State = {
  showAlertInfo: '',
  newsletterSubscribed: false
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case APP_SET_SHOW_ALERT_INFO:
      return {
        ...state,
        showAlertInfo: action.payload.showAlertInfo
      };
    case APP_USER_SUBSCRIBE_NEWSLETTER:
      return {
        ...state,
        newsletterSubscribed: action.payload.newsletterSubscribed
      };
    default:
      return state;
  }
}
