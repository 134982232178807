// @flow

import type { TagType } from 'types/Tag';

export const FETCH_TAGS_START = 'FETCH_TAGS_START';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
export const FETCH_TAGS_FAILURE = 'FETCH_TAGS_FAILURE';

export type FETCH_TAGS_START_ACTION = {
  type: 'FETCH_TAGS_START'
};

export type FETCH_TAGS_FAILURE_ACTION = {
  type: 'FETCH_TAGS_FAILURE'
};

export type FETCH_TAGS_SUCCESS_ACTION = {
  type: 'FETCH_TAGS_SUCCESS',
  payload: {
    tags: Array<TagType>
  }
};

export type Action = FETCH_TAGS_START_ACTION | FETCH_TAGS_FAILURE_ACTION | FETCH_TAGS_SUCCESS_ACTION;

export function fetchTags(): FETCH_TAGS_START_ACTION {
  return {
    type: FETCH_TAGS_START
  };
}

export function tagsFetched(tags: Array<TagType>): FETCH_TAGS_SUCCESS_ACTION {
  return {
    type: FETCH_TAGS_SUCCESS,
    payload: { tags }
  };
}

export function tagsFetchError(): FETCH_TAGS_FAILURE_ACTION {
  return {
    type: FETCH_TAGS_FAILURE
  };
}
