// @flow
import type { RawEventType, EventPreviewType, EventType } from 'types/Event';
import { mergeIntoDefault, convertToJsDate } from 'utils/objectUtils';
import { getEventDate } from 'utils/eventUtils';
import { nullEvent, nullEventPreview } from 'types/Event';

export function convertEventPreviewFromApi(rawEvent: RawEventType): EventPreviewType {
  if (!rawEvent) {
    return nullEventPreview;
  }

  const {
    id,
    start_date,
    end_date,
    title,
    slug,
    venue: { venue, address, city, zip },
    sidebar_content,
    tags,
    equipe,
    categories
  } = rawEvent;

  return mergeIntoDefault(nullEventPreview, {
    eventid: id,
    startDate: new Date(convertToJsDate(start_date)),
    endDate: new Date(convertToJsDate(end_date)),
    title: title.raw,
    label: categories[0] ? categories[0].name : '',
    tag: tags && tags[0] ? tags[0].name : '',
    categories,
    team: equipe && equipe.name ? equipe.name : '',
    categoryId: categories && categories[0] ? categories[0].id : 0,
    slug,
    venue,
    address,
    city,
    zip,
    sidebar: sidebar_content ? sidebar_content.data : []
  });
}

export function convertEventFromApi(rawEvent: RawEventType): EventType {
  if (!rawEvent) {
    return nullEvent;
  }
  const {
    id,
    start_date_details,
    end_date_details,
    start_date,
    end_date,
    title,
    slug,
    image,
    equipe,
    description: { data, raw },
    excerpt,
    venue: { venue, address, city, zip, geo_lat, geo_lng },
    all_day,
    organizer,
    categories,
    sidebar_content
  } = rawEvent;

  return mergeIntoDefault(nullEvent, {
    eventid: id,
    startDateDetail: getEventDate(start_date_details),
    endDateDetail: getEventDate(end_date_details),
    startDate: new Date(convertToJsDate(start_date)),
    endDate: new Date(convertToJsDate(end_date)),
    title: title.raw,
    team: equipe && equipe.name ? equipe.name : '',
    label: categories && categories.length > 0 ? categories[0].name : '',
    tag: rawEvent.tags && rawEvent.tags[0] ? rawEvent.tags[0].name : '',
    categories,
    categoryId: categories && categories[0] ? categories[0].id : 0,
    slug,
    image: image !== false ? image.url : '',
    description: data,
    excerpt: excerpt.data,
    raw_description: raw,
    venue,
    address,
    zip,
    city,
    organizer: organizer.length > 0 && organizer[0].organizer.replace('&rsquo;', "'"),
    phone: organizer.length > 0 && organizer[0].phone,
    email: organizer.length > 0 && organizer[0].email,
    website: organizer.length > 0 && organizer[0].website,
    all_day,
    lat: geo_lat,
    long: geo_lng,
    sidebar: sidebar_content ? sidebar_content.data : []
  });
}
