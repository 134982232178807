// @flow

import type { PartnerType } from 'types/Partner';
import {nullFailurePartner, nullLoadingPartner} from 'types/Partner';

export const FETCH_PARTNER_START = 'FETCH_PARTNER_START';
export const FETCH_PARTNER_SUCCESS = 'FETCH_PARTNER_SUCCESS';
export const FETCH_PARTNER_FAILURE = 'FETCH_PARTNER_FAILURE';

export type FETCH_PARTNER_START_ACTION = {
  type: 'FETCH_PARTNER_START',
  payload: {
    partners: Array<PartnerType>
  }
};

export type FETCH_PARTNER_FAILURE_ACTION = {
  type: 'FETCH_PARTNER_FAILURE',
  payload: {
    partners: Array<PartnerType>
  }
};

export type FETCH_PARTNER_SUCCESS_ACTION = {
  type: 'FETCH_PARTNER_SUCCESS',
  payload: {
    partners: Array<PartnerType>
  }
};

export type Action = FETCH_PARTNER_START_ACTION | FETCH_PARTNER_FAILURE_ACTION | FETCH_PARTNER_SUCCESS_ACTION;

export function fetchPartners(): FETCH_PARTNER_START_ACTION {
  return {
    type: FETCH_PARTNER_START,
    payload: {
      partners: nullLoadingPartner
    }
  };
}

export function partnersFetched(partners: Array<PartnerType>): FETCH_PARTNER_SUCCESS_ACTION {
  return {
    type: FETCH_PARTNER_SUCCESS,
    payload: { partners }
  };
}

export function partnersFetchError(error: any): FETCH_PARTNER_FAILURE_ACTION {
  return {
    type: FETCH_PARTNER_FAILURE,
    payload: {
      partners: nullFailurePartner
    }
  };
}
