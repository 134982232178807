// @flow

export type mediaInputsType = {
  'ffr-caption': string,
  'ffr-email': string,
  'ffr-event': string,
  'ffr-files': string,
  'ffr-title': string
};

export type rawContactFormResultType = {
  into: string,
  status: string,
  message: string,
  invalidFields: Array<{ into: string, message: string }>
};

export type ContactFormResultType = {
  into: string,
  status: string,
  message: string
};

export const nullContactFormResult = {
  into: '',
  status: '',
  message: ''
};
