// @flow
import { nullHome, type HomeType, type RawHomeType } from 'types/Home';
import { mergeIntoDefault } from 'utils/objectUtils';

export function convertHomeFromApi(rawHome: RawHomeType): HomeType {
  if (!rawHome) {
    return nullHome;
  }

  return mergeIntoDefault(nullHome, {
    ...rawHome,
    content: rawHome.content.data
  });
}
