// @flow
import type { BlocContent } from 'types/NewsletterBloc';

export type EventType = {
  compTag: string,
  eventid: number,
  startDate: Date,
  endDate: Date,
  startDateDetail: Date,
  endDateDetail: Date,
  title: string,
  label: string,
  categoryId: number,
  categories: any[],
  tag: string,
  team: string,
  slug: string,
  image: string,
  description: BlocContent[],
  excerpt: BlocContent[],
  raw_description: string,
  venue: string,
  address: string,
  zip: string,
  city: string,
  organizer: string,
  email: string,
  phone: string,
  website: string,
  lat: number,
  long: number,
  all_day: boolean,
  sidebar: BlocContent[]
};

export type EventPreviewType = {
  eventid: number,
  slug: string,
  startDate: Date,
  endDate: Date,
  title: string,
  team: string,
  categories: any[],
  label: string,
  categoryId: number,
  tag: string,
  venue: string,
  address: string,
  zip: string,
  city: string,
  sidebar: BlocContent[]
};

export type EventMatchType = EventType & {
  matchID: string,
  compTag: string,
  etat: string,
  journee: string,
  localClub: string,
  localEmbleme: string,
  visiteurClub: string,
  visiteurEmbleme: string,
  compID: string
};

export const nullEventPreview: EventPreviewType = {
  eventid: 1,
  startDate: new Date(),
  endDate: new Date(),
  startDateDetail: new Date(),
  endDateDetail: new Date(),
  title: '',
  label: '',
  tag: '',
  team: '',
  categories: [],
  categoryId: 0,
  venue: '',
  address: '',
  zip: '',
  city: '',
  slug: '',
  sidebar: []
};

export type RawEventType = {
  id: number,
  global_id: string,
  global_id_lineage: string[],
  author: string,
  status: string,
  date: string,
  equipe: {
    name: string
  },
  date_utc: string,
  modified: string,
  modified_utc: string,
  url: string,
  rest_url: string,
  title: {
    rendered: string,
    raw: string,
    data: {
      type: string,
      text: string
    }[]
  },
  description: {
    rendered: string,
    raw: string,
    data: BlocContent[]
  },
  excerpt: {
    rendered: string,
    raw: string,
    data: BlocContent[]
  },
  slug: string,
  image: {
    url: string,
    id: string,
    extension: string,
    width: number,
    height: number,
    sizes: {
      thumbnail: {
        width: number,
        height: number,
        'mime-type': string,
        url: string
      },
      medium: {
        width: number,
        height: number,
        'mime-type': string,
        url: string
      }
    }
  },
  all_day: boolean,
  start_date: string,
  start_date_details: {
    year: string,
    month: string,
    day: string,
    hour: string,
    minutes: string,
    seconds: string
  },
  end_date: string,
  end_date_details: {
    year: string,
    month: string,
    day: string,
    hour: string,
    minutes: string,
    seconds: string
  },
  utc_start_date: string,
  utc_start_date_details: {
    year: string,
    month: string,
    day: string,
    hour: string,
    minutes: string,
    seconds: string
  },
  utc_end_date: string,
  utc_end_date_details: {
    year: string,
    month: string,
    day: string,
    hour: string,
    minutes: string,
    seconds: string
  },
  timezone: string,
  timezone_abbr: string,
  cost: string,
  cost_details: {
    currency_symbol: string,
    currency_position: string,
    values: any[]
  },
  website: string,
  show_map: boolean,
  show_map_link: boolean,
  hide_from_listings: boolean,
  sticky: boolean,
  featured: boolean,
  categories: any[],
  tags: [
    {
      name: string,
      slug: string,
      term_group: number,
      term_taxonomy_id: number,
      taxonomy: string,
      description: string,
      parent: number,
      count: number,
      filter: string,
      id: number,
      urls: {
        self: string,
        collection: string
      }
    }
  ],
  venue: {
    id: number,
    author: string,
    status: string,
    date: string,
    date_utc: string,
    modified: string,
    modified_utc: string,
    url: string,
    venue: string,
    slug: string,
    address: string,
    city: string,
    country: string,
    zip: string,
    show_map: boolean,
    show_map_link: boolean,
    global_id: string,
    global_id_lineage: string[],
    geo_lat: number,
    geo_lng: number
  },
  organizer: [
    {
      id: number,
      author: string,
      status: string,
      date: string,
      date_utc: string,
      modified: string,
      modified_utc: string,
      url: string,
      organizer: string,
      slug: string,
      phone: string,
      website: string,
      email: string
    }
  ],
  sidebar_content: {
    data: BlocContent[]
  }
};

export type EventDetailDateType = {
  year: string,
  month: string,
  day: string,
  hour: string,
  minutes: string,
  seconds: string
};

export const nullEvent: EventType = {
  compTag: '',
  eventid: 1,
  startDate: new Date(),
  endDate: new Date(),
  startDateDetail: new Date(),
  endDateDetail: new Date(),
  title: '',
  label: '',
  tag: '',
  categoryId: 0,
  slug: '',
  image: '',
  description: [],
  excerpt: [],
  raw_description: '',
  venue: '',
  team: '',
  address: '',
  zip: '',
  city: '',
  organizer: '',
  email: '',
  phone: '',
  website: '',
  categories: [],
  lat: 0,
  long: 0,
  all_day: false,
  sidebar: []
};

export const nullEventMatch: EventMatchType = {
  ...nullEvent,
  matchID: '',
  compTag: '',
  etat: '',
  journee: '',
  localClub: '',
  localEmbleme: '',
  visiteurClub: '',
  visiteurEmbleme: '',
  compID: ''
};
