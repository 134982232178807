// @flow

import { type BlocContent } from './NewsletterBloc';

export type HomeType = {
  content: BlocContent[]
};

export type RawHomeType = {
  content: { data: BlocContent }
};

export const nullHome: HomeType = {
  content: []
};
