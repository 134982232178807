// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import HomeApi from 'api/home/HomeApi';
import { FETCH_HOME_START, homeFetched, homeFetchError } from 'actions/homeActions';

export default function saga(apiExecutor: ApiExecutorType) {
  const homeApi = new HomeApi(apiExecutor);

  return function* homeSaga(): GeneratorType {
    yield takeLatest(FETCH_HOME_START, fetchHome);
  };

  function* fetchHome(action): Saga<void> {
    try {
      const home = yield call(homeApi.fetchHome);
      yield put(homeFetched(home));
    } catch (e) {
      yield put(homeFetchError());
    }
  }
}
