// @flow

import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import { FETCH_TWITTER_START, FETCH_TWITTER_SUCCESS, FETCH_TWITTER_FAILURE } from 'actions/twitterActions';

import type { SocialContent } from 'types/SocialContent';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  posts: Array<SocialContent>,
  status: Status
};

const initialState: State = {
  posts: [],
  status: STATUS_LOADING
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_TWITTER_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_TWITTER_SUCCESS:
      return {
        ...state,
        posts: action.payload.posts,
        status: STATUS_SUCCESS
      };
    case FETCH_TWITTER_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
