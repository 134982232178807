// @flow
import { FacebookProvider, InstagramProvider, TwitterProvider, YoutubeProvider } from 'constants/socialConstants';

export type SocialProvider = string;

export type SocialContent = {
  id: string,
  type: string,
  date: Date | string,
  backgroundImage?: string,
  backgroundImageStandard?: string,
  description: string,
  comments: number,
  likes: number,
  share?: number,
  duration?: string,
  player?: string,
  link?: string,
  tags: string[]
};

export const nullTwitterPosts: SocialContent = {
    id: '',
    type: TwitterProvider,
    date: '',
    backgroundImage: '',
    description: '',
    comments: 0,
    likes: 0,
    share: 0,
    tags:[]
};

export const nullYoutubePosts: SocialContent = {
    id: '',
    type: YoutubeProvider,
    date: '',
    backgroundImage: '',
    description: '',
    comments: 0,
    likes: 0,
    share: 0,
    player: '',
	  tags:[]
};

export const nullInstagramPosts: SocialContent = {
  id: '',
  type: InstagramProvider,
  date: '',
  backgroundImage: '',
  description: '',
  comments: 0,
  likes: 0,
  tags:[],
  link: '',
};

export const nullFacebookPosts: SocialContent = {
    id: '',
    type: FacebookProvider,
    backgroundImage: '',
    description: '',
    date: '',
    comments: 0,
    likes: 0,
    share: 0,
    tags:[]
};

export type RawTwitterPostsType = {
  id_str: string,
  full_text: string,
  retweet_count: number,
  favorite_count: number,
  created_at: Date | string,
  entities: {
    media: Array<{
      url: string,
      media_url_https: string
    }>
  }
}

export type RawYoutubePostsType = {
  id: string,
  player: {
    embedHtml: string
  },
  snippet: {
    description: string,
    publishedAt: Date | string,
    title: string,
    thumbnails: {
      medium: {
        url: string,
      }
    }
  },
  statistics: {
    likeCount: number
  }
}

export type RawFacebookPostsType = {
  id: string,
  created_time: string,
  description: string,
  message: string,
  full_picture: string,
  shares: {
    count: number
  },
  likes: {
    data: string[]
  },
  comments: {
    data: string[]
  }
}

export type RawInstagramPostsType = {
  id: string,
  created_time: string,
  caption: {
    text: string
  },
  images: {
    standard_resolution: {
      url: string
    }
  },
  likes: {
    count: number
  },
  comments: {
    count: number
  },
  link: string,
  tags: string[]
}
