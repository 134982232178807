/// @flow
import React, { PureComponent } from 'react';
import { dynamicClassName } from 'utils/dynamicClassName';
import { Link } from 'react-router-dom';

type Props = {
  label?: string,
  className?: string,
  url?: string,
  title?: string,
  isDisabled?: boolean,
  isFull?: boolean,
  children?: ?React$Element<any>[],
  onClick?: Function
};

class Button extends PureComponent<Props> {
  static defaultProps = {
    label: '',
    className: '',
    title: '',
    url: '',
    isFull: false,
    isDisabled: false,
    children: null,
    onClick: () => {},
  };

  render() {
    const {
      isDisabled,
      isFull,
      onClick,
      url,
      label,
      title,
      className,
      children
    } = this.props;

    const classNames = dynamicClassName('btn btn--primary');
    isDisabled && classNames.add('is-disabled');
    isFull && classNames.add('btn--full');
    className && classNames.add(className);
    /* @apsulis TBD si ça ouvre un lien, on peut le décrire,
    sinon, retirons le title (genre si c'est un button avec action JS) */

    if (url) {
      return (
        <Link
          title={title}
          onClick={onClick}
          to={url}
          className={classNames.build()}>
          {children || label}
        </Link>
      );
    }
    return (
      <button
        type='button'
        title={title}
        onClick={onClick}
        className={classNames.build()}>
        {children || label}
      </button>
    );
  }
}

export default Button;
