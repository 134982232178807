// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import {
  FETCH_FAQ_THEME_START,
  FETCH_FAQ_QUESTIONS_START,
  ThemesFetched,
  ThemesFetchError,
  QuestionsFetched,
  QuestionsFetchError
} from 'actions/faqActions';
import FaqApi from 'api/contact/FaqApi';
import type { FETCH_FAQ_THEME_START_ACTION, FETCH_FAQ_QUESTIONS_START_ACTION } from 'actions/faqActions';

export default function(apiExecutor: ApiExecutorType) {
  const faqApi = new FaqApi(apiExecutor);

  return function* faqSaga(): GeneratorType {
    yield takeLatest(FETCH_FAQ_THEME_START, fetchFaqThemes);
    yield takeLatest(FETCH_FAQ_QUESTIONS_START, fetchFaqQuestions);
  };

  function* fetchFaqThemes(action: FETCH_FAQ_THEME_START_ACTION): Saga<void> {
    try {
      const themes = yield call(faqApi.fetchThemes);
      yield put(ThemesFetched(themes));
    } catch (e) {
      yield put(ThemesFetchError());
    }
  }

  function* fetchFaqQuestions(action: FETCH_FAQ_QUESTIONS_START_ACTION): Saga<void> {
    try {
      const questions = yield call(faqApi.fetchQuestions);
      yield put(QuestionsFetched(questions));
    } catch (e) {
      yield put(QuestionsFetchError());
    }
  }
}
