// @flow

import { FETCH_HOME_START, FETCH_HOME_SUCCESS, FETCH_HOME_FAILURE } from 'actions/homeActions';
import { nullHome, type HomeType } from 'types/Home';

import { STATUS_DEFAULT, STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';

import type { ActionType } from 'types/Actions';
import type { Status } from 'types/Status';

export type State = { home: HomeType, status: Status };

const initialState: State = {
  home: nullHome,
  status: STATUS_DEFAULT
};

export default function homeReducer(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_HOME_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_HOME_SUCCESS:
      return {
        ...state,
        home: action.payload.home,
        status: STATUS_SUCCESS
      };
    case FETCH_HOME_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
