// @flow
import App from 'components/app/App';
import { connect } from 'react-redux';

import { fetchSettings } from 'actions/appActions';
import { fetchMenu } from 'actions/menuActions';
import { fetchPartners } from 'actions/partnerActions';
import { fetchCategories } from 'actions/categoriesActions';
import { fetchPageDetailPreview } from 'actions/pageActions';
import { fetchRencontreLive } from 'actions/rencontreLiveActions';
import { keycloakConnect } from 'actions/userActions';

import type { StateProps, DispatchProps } from 'components/app/App';
import type { StateType, DispatchType } from 'types/Actions';
import { withRouter } from 'react-router-dom';

export default withRouter(
  connect(
    (state: StateType): StateProps => ({
      favicons: state.settingsState.favicons,
      titleClub: state.settingsState.title,
      linkPreview: state.pageDetailPreviewState.link,
      partnersStatus: state.partnersState.status,
      partners: state.partnersState.partners,
      mode_travaux: state.settingsState.mode_travaux,
      user_id: state.settingsState.user_id
    }),
    (dispatch: DispatchType): DispatchProps => ({
      fetchSettings: () => dispatch(fetchSettings()),
      fetchMenu: () => dispatch(fetchMenu()),
      fetchCategories: () => dispatch(fetchCategories()),
      fetchPartners: () => dispatch(fetchPartners()),
      fetchPageDetailPreview: (id: number) => dispatch(fetchPageDetailPreview(id)),
      fetchRencontreLive: () => dispatch(fetchRencontreLive()),
      keycloakConnect: () => dispatch(keycloakConnect()),
    })
  )(App)
);
