// @flow
import React, { Component } from 'react';
import { baseMenuItemWidth, letterWidth, logoWidth } from 'constants/menuConstants';
import { dynamicClassName } from 'utils/dynamicClassName';
import { convertEncodedHTML } from 'utils/convertUtils';
import { Link } from 'react-router-dom';

type Props = {
  url: string,
  urlType: string,
  title: string,
  titleAll?: string,
  children?: React$Element<*> | null,
  isMobile: boolean,
  isWideScreen?: boolean,
  isActive?: boolean,
  col?: boolean,
  id: number,
  activateMenu?: Function,
  isDisplayed: (id: number) => boolean,
  onCloseMenu?: Function,
  menuX: number
};

type ComponentState = {
  isOpen: boolean,
  dropdownTargetX: number,
  updated: boolean
};

class MenuElement extends Component<Props, ComponentState> {
  _container: ?HTMLElement;
  _dropdownmenu: ?HTMLElement;
  _title: ?HTMLElement;
  state: ComponentState = {
    isOpen: false,
    dropdownTargetX: 0,
    updated: false
  };

  updateDimensions() {
    const { isWideScreen, menuX } = this.props;
    const { dropdownTargetX: dropdownTargetState } = this.state;
    const dropdownmenu = this._dropdownmenu;
    const title = this._title;
    if (dropdownmenu && title) {
      const titleBounds = title.getBoundingClientRect();
      const titleCenterX = titleBounds.left + title.offsetWidth;
      const extraMargin = isWideScreen ? 30 : 0;
      let dropdownTargetX =
        Math.round(titleCenterX - dropdownmenu.offsetWidth / 2 + logoWidth - menuX) + extraMargin;
      if (dropdownTargetX < 0) {
        dropdownTargetX = 0;
      }
      let diff = 0;
      if (dropdownTargetX > dropdownTargetState) {
        diff = dropdownTargetX - dropdownTargetState;
      } else if (dropdownTargetX < dropdownTargetState) {
        diff = dropdownTargetState - dropdownTargetX;
      }
      if (diff > 5) {
        this.setState({ dropdownTargetX });
      }
    }
  }

  componentDidMount() {
      this.updateDimensions();
  }

  componentDidUpdate() {
    this.updateDimensions();
  }

  toggleIsOpen = (e: MouseEvent) => {
    const { isMobile, children, onCloseMenu, activateMenu, id } = this.props;

    if (isMobile && children) {
      e.preventDefault();
      this.setState(prevState => ({ isOpen: !prevState.isOpen }));
    } else if (onCloseMenu) {
      onCloseMenu();
    }
    if (activateMenu) {
      activateMenu(id);
    }
  };

  getSize = () => {
    const { title } = this.props;
    return baseMenuItemWidth + letterWidth * title.length;
  };

  renderDropdown = (
    children: any,
    classNamesChildrenContainer: any,
    classNamesChildrenList: any,
    titleAll?: string,
    url?: string
  ) => {
    if (children){
      const { onCloseMenu } = this.props;
      const { dropdownTargetX } = this.state;
      
      return (
        <ul
          style={{ left: dropdownTargetX }}
          ref={c => (this._dropdownmenu = c)}
          className={classNamesChildrenList.build()}>
          {url !== '#' &&
          <li className="menu__link-all">
            {url && url.match('^http') !== null &&
              <a href={url || '/'} 
                onClick={onCloseMenu}
                target="_blank"
                rel="noopener noreferrer">
                  {convertEncodedHTML(titleAll)}
              </a>
            }
            {url && url.match('^http') === null && 
              <Link to={url || '/'} onClick={onCloseMenu}>{convertEncodedHTML(titleAll)}</Link>
            }
          </li>
          }
          {children}
        </ul>
      );
    }
    return null;
  };

  render() {
    const {
      url,
      urlType,
      title,
      titleAll,
      children,
      isMobile,
      col,
      isDisplayed,
      id,
      isActive
    } = this.props;
    const { isOpen } = this.state;

    const classNamesTitle = dynamicClassName('');
    children && classNamesTitle.add('accordion-trigger');
    isMobile && isOpen && classNamesTitle.add('is-selected');

    const classNameLi = dynamicClassName('');

    isActive && classNameLi.add('is-active');
    isActive && classNamesTitle.add('is-active');

    const classNamesChildrenContainer = dynamicClassName('');
    col && classNamesChildrenContainer.add('menu__sub__selection');

    const classNamesChildrenList = dynamicClassName('menu__sub accordion-panel');
    col && classNamesChildrenList.add('menu__col');
    !isOpen && isMobile && classNamesChildrenList.add('is-hidden');

    if (isDisplayed(id)) {
      return (
        <li className={classNameLi.build()} ref={c => (this._container = c)}>
          {urlType !== 'external' && url && url.match('^http') === null && url !== '#' && 
          <Link
            innerRef={c => (this._title = c)} 
            to={url}
            className={classNamesTitle.build()}
            onClick={this.toggleIsOpen}
          >
            {convertEncodedHTML(title)}
          </Link>
          }
          {(urlType === 'external' || (urlType === 'custom' && url && url.match('^http') !== null && url !== '#')) && 
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className={classNamesTitle.build()}
            >
            {convertEncodedHTML(title)}
            </a>
          }
          {urlType !== 'external' && !(urlType === 'custom' && url && url.match('^http') !== null) && url && url.match('^http') !== null && url !== '#'  && 
            <a
              href={url}
              className={classNamesTitle.build()}
            >
            {convertEncodedHTML(title)}
            </a>
          }
          {url === '#' && 
          <span className={classNamesTitle.build()} ref={c => (this._title = c)} onClick={this.toggleIsOpen}>
            {convertEncodedHTML(title)}
          </span>
          }
          {children &&
            this.renderDropdown(
              children,
              classNamesChildrenContainer,
              classNamesChildrenList,
              titleAll,
              url)}
        </li>
      );
    }

    return null;
  }
}

export default MenuElement;
