// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import { convertArticleFromApi } from 'services/Article';

function TagArticleApi(executor: ApiExecutorType) {
  return {
    fetchTagArticleByTag,
  };
  function fetchTagArticleByTag(page: number, per_page: number, slug: string) {
    return executor
      .getWithHeader(`/ffr/v1/posts?page=${page}&per_page=${per_page}&tag_name=${slug}`)
      .then(response => ({
        tagArticles: response.body.map(a => convertArticleFromApi(a)),
        tagArticleNumber: response.headers['x-wp-total'],
        tag: response.body && response.body[0] ? response.body[0].tags_list.filter(tag => tag.slug === slug)[0] : {}
      }));
  }
}

export default TagArticleApi;
