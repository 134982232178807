// @flow

import type { YoutubeContent } from 'types/YoutubeContent';

export const FETCH_YOUTUBE_START = 'FETCH_YOUTUBE_START';
export const FETCH_YOUTUBE_SUCCESS = 'FETCH_YOUTUBE_SUCCESS';
export const FETCH_YOUTUBE_FAILURE = 'FETCH_YOUTUBE_FAILURE';

export type FETCH_YOUTUBE_START_ACTION = {
  type: 'FETCH_YOUTUBE_START'
};

export type FETCH_YOUTUBE_FAILURE_ACTION = {
  type: 'FETCH_YOUTUBE_FAILURE'
};

export type FETCH_YOUTUBE_SUCCESS_ACTION = {
  type: 'FETCH_YOUTUBE_SUCCESS',
  payload: {
    posts: YoutubeContent[]
  }
};

export type Action = FETCH_YOUTUBE_START_ACTION | FETCH_YOUTUBE_FAILURE_ACTION | FETCH_YOUTUBE_SUCCESS_ACTION;

export function fetchYoutubePosts(): FETCH_YOUTUBE_START_ACTION {
  return {
    type: FETCH_YOUTUBE_START
  };
}

export function youtubePostsFetched(posts: YoutubeContent[]): FETCH_YOUTUBE_SUCCESS_ACTION {
  return {
    type: FETCH_YOUTUBE_SUCCESS,
    payload: { posts }
  };
}

export function youtubePostsFetchError(): FETCH_YOUTUBE_FAILURE_ACTION {
  return {
    type: FETCH_YOUTUBE_FAILURE
  };
}
