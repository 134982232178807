/* eslint-disable max-len */
// @flow
import React, { useEffect, useRef, useState } from 'react';

import userAvatar from './placeholder-profil.png';
import { getConfSso } from 'constants/sso';
import { dynamicClassName } from 'utils/dynamicClassName';

type StateProps = {
  connectionInProgress: boolean,
  keycloakData: any
};

type Props = StateProps & {
  handleUserAccordionOpen: (isOpen: boolean) => void,
  isMobile?: boolean
};

function TopBarUserConnection({ connectionInProgress, handleUserAccordionOpen, isMobile, keycloakData }: Props) {
  const userDropdownRef: any = useRef(null);
  const [authenticated, setIsAuthenticated] = useState(false);
  const [loggedIn, setIsLoggedIn] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  useEffect(() => {
    if (keycloakData && keycloakData.authenticated) {
      setIsAuthenticated(true);
      setIsLoggedIn(keycloakData.authenticated);
    }
  }, [keycloakData]);

  const liClassName = dynamicClassName('topbar__right');

  const { keycloak } = keycloakData;
  const confSso = getConfSso();

  if (!connectionInProgress && !loggedIn && !authenticated) {
    return (
      <>
        <li className={liClassName.build()}>
          <a className="topbar__login" href={keycloak && keycloak.createLoginUrl({ redirectUri: confSso.urlEspacePrive })}>
            Connexion / inscription
          </a>
        </li>
      </>
    );
  }

  if (!connectionInProgress) {
    const accordionTriggerDynamicClasses = dynamicClassName('accordion-trigger topbar__user');
    const accordionDynamicClasses = dynamicClassName('topbar__sub accordion-panel');

    if (isAccordionOpen) {
      accordionDynamicClasses.remove('is-hidden');
      accordionTriggerDynamicClasses.add('is-selected');
    } else {
      accordionDynamicClasses.add('is-hidden');
      accordionTriggerDynamicClasses.remove('is-selected');
    }

    const { keycloak } = keycloakData;
    const keycloakLogo = !!keycloak && keycloak.tokenParsed && keycloak.tokenParsed.logo ? keycloak.tokenParsed.logo : '';
    const prenom = !!keycloak && keycloak.tokenParsed ? keycloak.tokenParsed.given_name : '';
    const nom = !!keycloak && keycloak.tokenParsed ? keycloak.tokenParsed.family_name : '';

    const handleClickOutside = (event: MouseEvent) => {
      if (userDropdownRef.current && !userDropdownRef.current.contains(event.target)) {
        setIsAccordionOpen(false);
        handleUserAccordionOpen(false);
      }
    };

    const handleRefClick = (e: MouseEvent) => {
      if (!isAccordionOpen) {
        document.addEventListener('mousedown', handleClickOutside, { once: true });
        setIsAccordionOpen(true);
        handleUserAccordionOpen(true);
      }

      setIsAccordionOpen(!isAccordionOpen);
      handleUserAccordionOpen(!isAccordionOpen);
    };

    return (
      <>
        <li className={liClassName.build()} ref={userDropdownRef}>
          <a onClick={handleRefClick} className={accordionTriggerDynamicClasses.build()} role="button" tabIndex="0">
            {!isMobile && keycloakLogo && <img src={keycloakLogo} alt="" style={{ width: '24px', height: '24px' }} />}
            {!isMobile && !keycloakLogo && <img src={userAvatar} alt="" style={{ width: '24px', height: '24px' }} />}
            {`${prenom ?? ''} ${nom ?? ''}`}
          </a>
          <ul className={accordionDynamicClasses.build()} id="accordion-user">
            <li>
              <a
                href={confSso.urlEspacePrive}
                className="accordion-user-not-close"
                onClick={() => this.setState({ isAccordionOpen: false })}
              >
                <i className="icon icon-account is-inline" />
                Espace personnel
              </a>
            </li>

            <li>
              <a href={keycloak.createLogoutUrl()} className="accordion-user-not-close">
                <i className="icon icon-logout is-inline" />
                Se déconnecter
              </a>
            </li>
          </ul>
        </li>
      </>
    );
  }

  return null;
}

export default TopBarUserConnection;
