// @flow
import { MINUTE, HOUR, DAY, MONTH, YEAR } from 'constants/timeConstants';

const monthList: string[] = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre'
];

const monthListAbbv: string[] = [
  'Janv.',
  'Févr.',
  'Mars',
  'Avr.',
  'Mai',
  'Juin',
  'Juill.',
  'Août',
  'Sept.',
  'Oct.',
  'Nov.',
  'Déc.'
];

const dayList: string[] = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
const daysAbbvList: string[] = ['Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.'];

export function getMonthName(month: number): string {
  return monthList[month];
}

export function getMonthNumber(month: string): number {
  let index = monthList.findIndex(m => m.toLowerCase() === month.toLowerCase());
  if (index !== -1) {
    return index;
  }

  return monthListAbbv.findIndex(m => m.toLowerCase() === month.toLowerCase());
}

export function getMonthAbbv(month: number): string {
  return monthListAbbv[month];
}

export function getJsDate(month: string, year: string): Date {
  const lowerCaseMonth = month.toLowerCase();
  let curMonth;

  // https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Objets_globaux/decodeURI
  curMonth = decodeURI(lowerCaseMonth);
  curMonth =
    // eslint-disable-next-line max-len
    'janvier___février___mars______avril_____mai_______juin______juillet___août______septembre_octobre___novembre__décembre__'.indexOf(
      curMonth
    ) /
      10 +
    1;
  // transform date into JS Date
  curMonth = new Date(`${year}-${curMonth < 10 ? `0${curMonth}` : curMonth}-01`);

  return curMonth;
}

export function getDayName(day: number): string {
  return dayList[day];
}

export function getDayAbbv(day: number): string {
  return daysAbbvList[day];
}

export function getHourAndMinutesStr(date: Date): string {
  const UTCHour = date.getUTCHours();
  const UTCMinutes = date.getUTCMinutes();
  const hour = UTCHour === 0 ? '00' : `${UTCHour}`;
  const minutes = UTCMinutes === 0 ? '00' : `${UTCMinutes}`;

  return `${hour}H${minutes}`;
}

/**
 * Formate une date JS en horaire lisible sous la forme hh:mm
 * @param date date
 */
export function formatHours(date: Date): string {
  return `${date.getUTCHours()}h${date.getUTCMinutes() < 10 ? '0' : ''}${date.getUTCMinutes()}`;
}

/**
 * Formate une date JS en date lisible sous la forme dd/mm/yyyy
 * @param date date
 */
export function formatFullDate(date: Date): string {
  const month = parseInt(date.getMonth(), 0) + 1;
  return `${date.getUTCDate() < 10 ? '0' : ''}${date.getUTCDate()}/${
    month < 10 ? '0' : ''
  }${month}/${date.getUTCFullYear()}`; // eslint-disable-line max-len
}

export function formatFullDateString(date: Date): string {
  const month = parseInt(date.getUTCMonth(), 0) + 1;
  return `${date.getUTCDate()} ${getMonthName(month - 1)} ${date.getUTCFullYear()}`;
}

export function formatDateWithAbbvStr(date: Date): string {
  const day = date.getUTCDay();
  const month = parseInt(date.getUTCMonth(), 0) + 1;
  return `${getDayAbbv(day)} ${date.getUTCDate()}  ${getMonthAbbv(month - 1)}`;
}

export function formatDateWithMonthYear(date: Date): string {
  const month = parseInt(date.getUTCMonth(), 10);
  return `${getMonthName(month)} ${date.getUTCFullYear()}`;
}

export function getHoursDifference(date1: Date, date2: Date) {
  return Math.floor(Math.abs(date1 - date2) / 36e5);
}

export function getTimeDifference(date1: Date, date2: Date) {
  const difference = Math.floor(Math.abs(date1 - date2) / 1000);
  let differenceText = '';
  if (Math.floor(difference / YEAR) > 0) {
    const since = Math.floor(difference / YEAR);
    differenceText = `Il y a ${since}a`;
  } else if (Math.floor(difference / MONTH) > 0) {
    const since = Math.floor(difference / MONTH);
    differenceText = `Il y a ${since}m`;
  } else if (Math.floor(difference / DAY) > 0) {
    const since = Math.floor(difference / DAY);
    differenceText = `Il y a ${since}j`;
  } else if (Math.floor(difference / HOUR) > 0) {
    const since = Math.floor(difference / HOUR);
    differenceText = `Il y a ${since}h`;
  } else if (Math.floor(difference / MINUTE) > 0) {
    const since = Math.floor(difference / MINUTE);
    differenceText = `Il y a ${since}min`;
  }

  return differenceText;
}

/**
 * S'assurer que la date est une date JS et non une string
 *
 * @param date date
 */
export function getDateFromString(date: Date | string) {
  return date instanceof Date ? date : new Date(date);
}

export function formatYoutubeDuration(duration: string) {
  let a: any = duration.match(/\d+/g);

  if (duration.indexOf('M') >= 0 && duration.indexOf('H') === -1 && duration.indexOf('S') === -1) {
    a = [0, a[0], 0];
  }

  if (duration.indexOf('H') >= 0 && duration.indexOf('M') === -1) {
    a = [a[0], 0, a[1]];
  }
  if (duration.indexOf('H') >= 0 && duration.indexOf('M') === -1 && duration.indexOf('S') === -1) {
    a = [a[0], 0, 0];
  }

  let durationInSecond = -1;

  if (a.length === 3) {
    durationInSecond += parseInt(a[0], 10) * 3600;
    durationInSecond += parseInt(a[1], 10) * 60;
    durationInSecond += parseInt(a[2], 10);
  }

  if (a.length === 2) {
    durationInSecond += parseInt(a[0], 10) * 60;
    durationInSecond += parseInt(a[1], 10);
  }

  if (a.length === 1) {
    durationInSecond += parseInt(a[0], 10);
  }

  const seconds = (durationInSecond % 60) + 1;

  return `${Math.floor(durationInSecond / 60)}:${seconds < 10 ? '0' : ''}${seconds}`;
}

export function convertSeasonToDate(saison: string) {
  const annees = saison.split('/');
  return {
    startDate: annees[0].trim() + '-08-01 00:00:00',
    endDate: annees[1].trim() + '-07-31 23:59:59'
  };
}

export function getFullDate(start: Date, year: boolean = true, day: boolean = true) {
  let date = `${start.getDate()} ${getMonthName(start.getMonth())}`;

  if (day) {
    date = `${getDayName(start.getDay())} ${date}`;
  }

  if (year) {
    date += ` ${start.getFullYear()}`;
  }

  return date;
}
