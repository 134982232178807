// @flow
import type { 
  RawTwitterPostsType,
  RawFacebookPostsType,
  RawInstagramPostsType,
  SocialContent
} from 'types/SocialContent';
import { nullTwitterPosts, nullFacebookPosts, nullInstagramPosts } from 'types/SocialContent';
import { mergeIntoDefault } from 'utils/objectUtils';

export function convertFacebookPostsFromApi(rawFacebookPosts: RawFacebookPostsType): SocialContent {
  if (!rawFacebookPosts) {
    return nullFacebookPosts;
  }

  const {
    id,
    message,
    full_picture,
    created_time,
    comments,
    likes,
    shares,
  } = rawFacebookPosts;

  return mergeIntoDefault(nullFacebookPosts, {
    id,
    description: message ? message : ' ',
    date: created_time,
    backgroundImage: full_picture ? full_picture : '',
    comments: comments ? comments.data.length : 0,
    likes: likes ? likes.data.length : 0,
    share: shares ? shares.count : 0,
  });
}

export function convertTwitterPostsFromApi(rawTwitterPosts: RawTwitterPostsType): SocialContent {
  if (!rawTwitterPosts) {
    return nullTwitterPosts;
  }

  const {
    id_str,
    full_text,
    retweet_count,
    favorite_count,
    created_at,
    entities
  } = rawTwitterPosts;

  return mergeIntoDefault(nullTwitterPosts, {
    id: id_str,
    description: full_text,
    share: retweet_count,
    likes: favorite_count,
    date: created_at,
    backgroundImage: entities.media ? entities.media[0].media_url_https : ''
  });
}

export function convertInstagramPostsFromApi(rawInstagramPosts: RawInstagramPostsType): SocialContent {
   if (!rawInstagramPosts) {
     return nullInstagramPosts;
   }

   const {
    id,
    created_time,
    caption,
    images,
    likes,
    comments,
    link,
    tags
   } = rawInstagramPosts;

   const date = new Date(parseInt(created_time, 10) * 1000);
   return mergeIntoDefault(nullInstagramPosts, {
      id: id,
      date: date.toString(),
      backgroundImage: images ? images.standard_resolution.url : '',
      description: (caption && caption.text) ? caption.text : caption ? caption : '',
      comments: comments ? comments.count : 0,
      likes: likes ? likes.count : 0,
      tags,
      link
   });
}
