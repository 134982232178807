// @flow
import { getPlaceholder } from 'utils/assetsUtils';

export const nullPartner = {
    title: '',
    illustration: '',
    target: '',
    link: ''
  };

export const nullLoadingPartner = [];

export const nullFailurePartner = [
  {
    title: 'Oups',
    illustration: getPlaceholder(),
    target: '_blank',
    link: '#'
  }
];

export type PartnerType = {
  title: string,
  target: string,
  illustration: string,
  link: string,
};

export type RawPartnerType = {
  id:	number,
  date:	string,
  date_gmt:	string,
  modified:	string,
  modified_gmt:	string,
  slug:	string,
  status:	string,
  title:	{
    rendered:	string,
    raw:	string,
    data:	Array<{
      type:	string,
      text:	string,
    }>
  },
  featured_media: {
    id:	number,
    src:	string,
    legend:	string,
    alt:	string,
    width: number,
    height:	number,
    sizes:{
      thumbnail:{
        file:	string,
        width:	number,
        height:	number,
      }
    }
  },
  menu_order: number,
  external_link:	string,
  target:	string,
  description: string,
  tags_list: Array<Object>,
};
