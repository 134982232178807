// @flow
import React from 'react';
import { Redirect } from 'react-router-dom';

type Props = {
  content: any,
  hideModal: () => void
};

function ContactFormSuccessModalContent({ content, hideModal }: Props) {
  return (
    <div className="modal-content box keep-in-touch-done modal-content--stripe">
      <i className="icon icon-close js-closeModal" role="button" tabIndex={0} onClick={hideModal}></i>
      <div className="modal__wrapper">
        <h3 className="mb-2">Votre demande a bien été envoyée</h3>
        <div className="modal__body modal__body--medium narrow-2">
          <div className="circle-icon circle-icon--check"></div>
          <p>Merci !</p>
          {content && content.redirection && <Redirect to={content.redirection} />}
        </div>
      </div>
    </div>
  );
}

export default ContactFormSuccessModalContent;
