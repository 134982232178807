// @flow

import type { PageChildType } from 'types/PageNode';

export const baseMenuItemWidth: number = 48;
export const baseHeaderCategorySearchWidth: number = 58;
export const letterWidth: number = 5;
export const letterWidthSearch: number = 9;
export const logoWidth: number = 100;
export const footerBlacklistPaths: string[] = [];
export const headerBlacklistWithSlugPaths: string[] = [];
export const footerBlacklistWithSlugPaths: string[] = [];
export const headerFilterClubWithlistPaths: string[] = [];
export const topBarMenuApiName: string = 'topnav';
export const headerMenuApiName: string = 'header';
export const footerMenuApiName: string = 'footer';
export const bottomNavApiName: string = 'bottomnav';

export const TYPE_NORMAL: string = 'normal';
export const TYPE_MOBILE: string = 'mobile';

export const teamSubmenuHeaders: PageChildType[] = [
  {
    menu_order: 5,
    object_slug: '',
    title: 'Général',
    description: '',
    featured_media: { src: '' },
    texte_cta: '',
    object_id: 0,
    type_name: 'endur',
    types_public: [],
  },
  {
    menu_order: 5,
    object_slug: 'resultats',
    title: 'Résultats',
    description: '',
    featured_media: { src: '' },
    texte_cta: '',
    object_id: 0,
    type_name: 'endur',
    types_public: [],
  },
  {
    menu_order: 10,
    object_slug: 'calendrier',
    title: 'Calendrier',
    description: '',
    featured_media: { src: '' },
    texte_cta: '',
    object_id: 0,
    type_name: 'endur',
    types_public: [],
  },
  {
    menu_order: 15,
    object_slug: 'classement',
    title: 'Classement',
    description: '',
    featured_media: { src: '' },
    texte_cta: '',
    object_id: 0,
    type_name: 'endur',
    types_public: [],
  },
  {
    menu_order: 20,
    object_slug: 'effectif',
    title: 'Effectif',
    description: '',
    featured_media: { src: '' },
    texte_cta: '',
    object_id: 0,
    type_name: 'endur',
    types_public: [],
  },
  {
    menu_order: 25,
    object_slug: 'statistiques',
    title: "Statistiques",
    description: '',
    featured_media: { src: '' },
    texte_cta: '',
    object_id: 0,
    type_name: 'endur',
    types_public: [],
  },
];
