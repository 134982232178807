// @flow
export const FacebookProvider: string = 'Facebook';
export const InstagramProvider: string = 'Instagram';
export const TwitterProvider: string = 'Twitter';
export const YoutubeProvider: string = 'Youtube';
export const AllProvider: string = 'All';
export const FFR_FACEBOOK: string = 'https://www.facebook.com/FFRugby/';
export const FFR_INSTAGRAM: string= 'https://www.instagram.com/ffrugby/';
export const FFR_TWITTER: string = 'https://twitter.com/ffrugby';
export const FFR_YOUTUBE: string = 'https://www.youtube.com/channel/UCnH0bSmQqDBfNj9kZzCaINw';
