// @flow
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import TeamMenuDropDownMenu from 'components/teammenu/TeamMenuDropdownMenu';

import type { StateProps } from 'components/teammenu/TeamMenuDropdownMenu';
import type { StateType } from 'types/Actions';

export default withRouter(connect(
    (state: StateType): StateProps => ({
      clubName: state.settingsState.title,
    }),
    null
  )(TeamMenuDropDownMenu));
