// @flow

import {
  STATUS_LOADING,
  STATUS_FAILURE,
  STATUS_SUCCESS,
  STATUS_DEFAULT,
  STATUS_UNKNOWN
} from 'constants/statusConstants';
import {
  FETCH_PAGE_DETAIL_START,
  FETCH_PAGE_DETAIL_SUCCESS,
  FETCH_PAGE_DETAIL_FAILURE,
  FETCH_CUSTOM_PAGE_DETAIL_START,
  FETCH_CUSTOM_PAGE_DETAIL_SUCCESS,
  FETCH_CUSTOM_PAGE_DETAIL_FAILURE,
  FETCH_UNKNOWN
} from 'actions/pageActions';

import type { ActionType } from 'types/Actions';
import type { Status } from 'types/Status';
import type { BlocContent } from 'types/NewsletterBloc';
import type { SEOMetaType } from 'types/SEOType';
import { nullSeoMeta } from 'types/SEOType';

export type State = SEOMetaType & {
  title: string,
  content: BlocContent[],
  slug: string,
  label: Object,
  featured_media: {
    src: string,
    legend: string
  },
  status: Status,
  sidebar_content: BlocContent[],
  template: string,
  redirection: string,
};

const initialState: State = {
  ...nullSeoMeta,
  title: '',
  content: [],
  slug: '',
  featured_media: {
    src: '',
    legend: ''
  },
  status: STATUS_DEFAULT,
  sidebar_content: [],
  template: '',
  label: {},
  redirection: '',
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_PAGE_DETAIL_START:
    case FETCH_CUSTOM_PAGE_DETAIL_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_PAGE_DETAIL_SUCCESS:
    case FETCH_CUSTOM_PAGE_DETAIL_SUCCESS:
      return {
        ...state,
        title: action.payload.pageDetail.title,
        label: action.payload.pageDetail.label || {},
        content: action.payload.pageDetail.content,
        slug: action.payload.pageDetail.slug,
        featured_media: action.payload.pageDetail.featured_media,
        sidebar_content: action.payload.pageDetail.sidebar_content,
        meta_description: action.payload.pageDetail.meta_description,
        meta_title: action.payload.pageDetail.meta_title,
        template: action.payload.pageDetail.template,
        redirection: action.payload.pageDetail.redirection,
        status: STATUS_SUCCESS
      };
    case FETCH_PAGE_DETAIL_FAILURE:
    case FETCH_CUSTOM_PAGE_DETAIL_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    case FETCH_UNKNOWN:
      return {
        ...state,
        status: STATUS_UNKNOWN,
      };
    default:
      return state;
  }
}
