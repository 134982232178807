// @flow

import type { SocialContent } from 'types/SocialContent';

export const FETCH_TWITTER_START = 'FETCH_TWITTER_START';
export const FETCH_TWITTER_SUCCESS = 'FETCH_TWITTER_SUCCESS';
export const FETCH_TWITTER_FAILURE = 'FETCH_TWITTER_FAILURE';

export type FETCH_TWITTER_START_ACTION = {
  type: 'FETCH_TWITTER_START'
};

export type FETCH_TWITTER_FAILURE_ACTION = {
  type: 'FETCH_TWITTER_FAILURE'
};

export type FETCH_TWITTER_SUCCESS_ACTION = {
  type: 'FETCH_TWITTER_SUCCESS',
  payload: {
    posts: SocialContent[]
  }
};

export type Action = FETCH_TWITTER_START_ACTION | FETCH_TWITTER_FAILURE_ACTION | FETCH_TWITTER_SUCCESS_ACTION;

export function fetchTwitterPosts(): FETCH_TWITTER_START_ACTION {
  return {
    type: FETCH_TWITTER_START
  };
}

export function twitterPostsFetched(posts: SocialContent[]): FETCH_TWITTER_SUCCESS_ACTION {
  return {
    type: FETCH_TWITTER_SUCCESS,
    payload: { posts }
  };
}

export function twitterPostsFetchError(): FETCH_TWITTER_FAILURE_ACTION {
  return {
    type: FETCH_TWITTER_FAILURE
  };
}
