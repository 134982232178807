// @flow

import { FETCH_SETTINGS_START, FETCH_SETTINGS_SUCCESS, FETCH_SETTINGS_FAILURE } from 'actions/appActions';
import type { liensRsType, PartnerType, CardMenuElementType, NextMatchType, TopNewsType } from 'types/Settings';

import { STATUS_DEFAULT, STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';

import { nullHighlightedTeams } from 'types/Settings';
import type { ActionType } from 'types/Actions';
import type { CompetitionEquipeType } from 'types/Settings';
import type { Status } from 'types/Status';

export type State = {
  status: Status,
  adresse: {
    complement: string,
    localite: string,
    codePostal: string,
    voie: string,
    lieuDitBoitePostale: string,
    paysNom: string,
    immeuble: string,
    codeInsee: string,
    position: {
      type: string,
      coordinates: number[]
    }
  },
  telephone: string,
  ligue: string,
  title: string,
  description: string,
  meta_title_home: string,
  meta_description_home: string,
  url: string,
  logo: string,
  saison_en_cours: number,
  titre_liste_cards: string,
  competitionsEquipes: CompetitionEquipeType,
  contacts: {
    adresse: string,
    adresse_map: string,
    telephone: string,
    email: string,
    horaires: string
  },
  favicons: {
    'android-192': string,
    'android-256': string,
    'apple-touch-icon-152': string,
    'apple-touch-icon-180': string,
    'favicon-16': string,
    'favicon-32': string,
    'mstile-150': string
  },
  liens_rs: liensRsType,
  newsletter: {
    type: false | 'campaign_monitor' | 'lien' | 'mailjet' | 'mailchimp',
    lien?: string,
    key?: string
  },
  cookies: number,
  top_news: TopNewsType,
  actualite_une: TopNewsType,
  partenaires: PartnerType[],
  partenaires_page: string,
  partenaires_une_titre: string,
  partenaires_une_description: string,
  partenaires_une_cta: string,
  banniere: string,
  rejoignez_nous_background: string,
  club_id: string,
  structure_parente: {
    id: string,
    nom: string,
    code: string,
    siteInternet: string
  },
  actualites_une_titre: string,
  actualites_une_description: string,
  equipes_une: CardMenuElementType[],
  equipes_une_titre: string,
  equipes_une_description: string,
  equipes_une_cta: string,
  rejoignez_nous: string,
  equipes_une_lien: string,
  prochaine_rencontre: ?NextMatchType,
  prochaine_rencontre_lien: string,
  prochainesRencontres: ?(NextMatchType[]),
  structure: string,
  mode_travaux: boolean,
  user_id: number,
  home_bloc_boutique: boolean,
  liste_blocs_un: CardMenuElementType[],
  liste_blocs_deux: CardMenuElementType[],
  titre_liste_blocs_un: string,
  titre_liste_blocs_deux: string,
  placeholder: string,
  actualite_ffr_vers_ecosysteme: {
    featured_media: Object,
    link: string,
    title: {
      rendered: string,
      raw: string
    },
    date: string,
    label: {
      name: string,
      slug: string
    }
  },
  cgus: string,
  cgu: number,
  google_verification: string,
  ville: string,
  acronyme: string,
  message_alerte: string
};

const initialState: State = {
  status: STATUS_DEFAULT,
  adresse: {
    complement: '',
    localite: '',
    codePostal: '',
    voie: '',
    lieuDitBoitePostale: '',
    paysNom: '',
    immeuble: '',
    codeInsee: '',
    position: {
      type: '',
      coordinates: []
    }
  },
  telephone: '',
  ligue: '',
  title: '',
  description: '',
  meta_title_home: '',
  meta_description_home: '',
  url: '',
  logo: '',
  saison_en_cours: 0,
  titre_liste_cards: '',
  competitionsEquipes: {},
  contacts: {
    adresse: '',
    adresse_map: '',
    telephone: '',
    email: '',
    horaires: ''
  },
  favicons: {
    'android-192': '',
    'android-256': '',
    'apple-touch-icon-152': '',
    'apple-touch-icon-180': '',
    'favicon-16': '',
    'favicon-32': '',
    'mstile-150': ''
  },
  liens_rs: {
    fb_lien: '',
    instagram_lien: '',
    twitter_lien: '',
    youtube_lien: '',
    flickr_lien: '',
    linkedin_lien: '',
    twitch_lien: '',
    snapchat_lien: '',
    tik_tok_lien: ''
  },
  newsletter: { type: false },
  cookies: 0,
  top_news: {
    title: '',
    link: '',
    label: '',
    featured_image: ''
  },
  actualite_une: {
    title: '',
    link: '',
    label: '',
    featured_image: ''
  },
  partenaires: [
    {
      cta: '',
      description: '',
      facebook: '',
      instagram: '',
      linkedin: '',
      lien: '',
      lien_type: '',
      logo: '',
      titre: '',
      twitter: '',
      youtube: ''
    }
  ],
  partenaires_page: '',
  partenaires_une_titre: '',
  partenaires_une_description: '',
  partenaires_une_cta: 'Voir tous les partenaires',
  banniere: '',
  rejoignez_nous_background: '',
  club_id: '',
  structure_parente: {
    id: '',
    nom: '',
    code: '',
    siteInternet: ''
  },
  actualites_une_titre: '',
  actualites_une_description: '',
  equipes_une: nullHighlightedTeams,
  equipes_une_titre: '',
  equipes_une_description: '',
  equipes_une_cta: 'Voir toutes les équipes',
  rejoignez_nous: '',
  equipes_une_lien: '',
  prochaine_rencontre: null,
  prochaine_rencontre_lien: '',
  prochainesRencontres: [],
  structure: '',
  mode_travaux: false,
  user_id: -1,
  home_bloc_boutique: false,
  liste_blocs_un: [],
  liste_blocs_deux: [],
  titre_liste_blocs_un: '',
  titre_liste_blocs_deux: '',
  placeholder: '',
  actualite_ffr_vers_ecosysteme: {
    featured_media: {},
    link: '',
    title: {
      rendered: '',
      raw: ''
    },
    date: '',
    label: {
      name: '',
      slug: ''
    }
  },
  cgus: '',
  cgu: 0,
  google_verification: '',
  ville: '',
  acronyme: '',
  message_alerte: ''
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_SETTINGS_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_SETTINGS_SUCCESS:
      return {
        ...state,
        status: STATUS_SUCCESS,
        adresse: action.payload.settings.adresse,
        telephone: action.payload.settings.telephone,
        ligue: action.payload.settings.ligue,
        title: action.payload.settings.title,
        description: action.payload.settings.description,
        meta_title_home: action.payload.settings.meta_title_home,
        meta_description_home: action.payload.settings.meta_description_home,
        url: action.payload.settings.url,
        logo: action.payload.settings.logo,
        saison_en_cours: action.payload.settings.saison_en_cours,
        contacts: action.payload.settings.contacts,
        favicons: action.payload.settings.favicons,
        liens_rs: action.payload.settings.liens_rs,
        newsletter: action.payload.settings.newsletter,
        cookies: action.payload.settings.cookies,
        top_news: action.payload.settings.top_news,
        actualite_une: action.payload.settings.actualite_une,
        partenaires: action.payload.settings.partenaires,
        partenaires_page: action.payload.settings.partenaires_page,
        partenaires_une_titre: action.payload.settings.partenaires_une_titre,
        partenaires_une_description: action.payload.settings.partenaires_une_description,
        partenaires_une_cta: action.payload.settings.partenaires_une_cta,
        banniere: action.payload.settings.banniere,
        rejoignez_nous_background: action.payload.settings.rejoignez_nous_background,
        club_id: action.payload.settings.club_id,
        structure_parente: action.payload.settings.structure_parente,
        rejoignez_nous: action.payload.settings.rejoignez_nous,
        actualites_une_titre: action.payload.settings.actualites_une_titre,
        actualites_une_description: action.payload.settings.actualites_une_description,
        equipes_une: action.payload.settings.equipes_une,
        equipes_une_titre: action.payload.settings.equipes_une_titre,
        equipes_une_description: action.payload.settings.equipes_une_description,
        equipes_une_cta: action.payload.settings.equipes_une_cta,
        equipes_une_lien: action.payload.settings.equipes_une_lien,
        prochaine_rencontre: action.payload.settings.prochaine_rencontre,
        prochaine_rencontre_lien: action.payload.settings.prochaine_rencontre_lien,
        prochainesRencontres: action.payload.settings.prochainesRencontres,
        structure: action.payload.settings.structure,
        competitionsEquipes: action.payload.settings.competitionsEquipes,
        mode_travaux: action.payload.settings.mode_travaux,
        user_id: action.payload.settings.user_id,
        home_bloc_boutique: action.payload.settings.home_bloc_boutique,
        liste_blocs_un: action.payload.settings.liste_blocs_un,
        liste_blocs_deux: action.payload.settings.liste_blocs_deux,
        titre_liste_blocs_un: action.payload.settings.titre_liste_blocs_un,
        titre_liste_blocs_deux: action.payload.settings.titre_liste_blocs_deux,
        placeholder: action.payload.settings.placeholder,
        actualite_ffr_vers_ecosysteme: action.payload.settings.actualite_ffr_vers_ecosysteme,
        cgus: action.payload.settings.cgus,
        cgu: action.payload.settings.cgu,
        google_verification: action.payload.settings.google_verification,
        ville: action.payload.settings.ville,
        acronyme: action.payload.settings.acronyme,
        message_alerte: action.payload.settings.message_alerte
      };
    case FETCH_SETTINGS_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
