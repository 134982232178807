// @flow
import type { RawMenuResponse, MenuElementType } from 'types/MenuElement';
import { nullMenuElement } from 'types/MenuElement';
import {
  topBarMenuApiName,
  headerMenuApiName,
  footerMenuApiName,
  bottomNavApiName,
} from 'constants/menuConstants';
import { mergeIntoDefault } from 'utils/objectUtils';

export function convertMenusFromApi(
  response: RawMenuResponse
): { topBar: MenuElementType[], header: MenuElementType[], footer: MenuElementType[] } {
  const topBarFiltered = response.find(element => element.position === topBarMenuApiName) || nullMenuElement;
  const headerFiltered = response.find(element => element.position === headerMenuApiName) || nullMenuElement;
  const footerFiltered = response.find(element => element.position === footerMenuApiName) || nullMenuElement;
  const bottomNavFiltered = response.find(element => element.position === bottomNavApiName) || nullMenuElement;

  const topBar = topBarFiltered.items.map(item => (mergeIntoDefault(nullMenuElement, item))) || [];
  const header = headerFiltered.items.map(item => (mergeIntoDefault(nullMenuElement, item))) || [];
  const footer = footerFiltered.items.map(item => (mergeIntoDefault(nullMenuElement, item))) || [];
  const bottomNav = bottomNavFiltered.items.map(item => (mergeIntoDefault(nullMenuElement, item))) || [];

  return {
    topBar,
    header,
    footer,
    bottomNav,
  };
}
