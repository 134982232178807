// @flow
import React from 'react';

import { withHelmet } from 'infrastructure/seo/Helmet';

import imageNotFound from 'assets/img/404/not-found.svg';

const AppOffline = () => (
  <>
    <section className={`banner banner--big banner--center`}>
      <div className="banner__thumbnail img-blue-100" />
    </section>
    <div className="container">
      <div className="row">
        <div className="col col--center col-md-6 col-lg-6">
          <div className="box not-found">
            <img src={imageNotFound} alt="Site indisponible " />
            <h1 className="ft-h1">Site indisponible !</h1>
            <p className="ft-secondary">Revenez plus tard.</p>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default withHelmet(AppOffline)((Helmet) => (
  <Helmet>
    <meta name="prerender-status-code" content="404" />
  </Helmet>
)) ;
