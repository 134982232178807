// @flow

import React, { Component, Fragment } from 'react';
import TextInput from 'components/fragments/input/material/TextInputMaterial';
import CheckboxInput from 'components/fragments/input/CheckboxInput';
import { validateEmail } from 'utils/validatorUtils';
import SelectInput from 'components/fragments/input/SelectInput';
import type { DisplayModalFunctionType } from 'types/Modal';
import type { NewsletterSubscriptionEntryType, NewsletterSubscriptionType } from 'types/NewsletterSubscription';

export type DispatchProps = {
  fetchOptions: (campaignMonitorKey: string) => void,
  subscribeNewsletter: (subscription: NewsletterSubscriptionEntryType, campaignMonitorKey: string) => void,
  userHasSubscribedNewsletter: (newsletterSubscribed: boolean) => void,
  displayModal: DisplayModalFunctionType
};

export type StateProps = {
  options: NewsletterSubscriptionType,
  newsletterSetting: {
    type: false | 'campaign_monitor' | 'lien' | 'mailjet' | 'mailchimp',
    lien?: string,
    key?: string
  },
  titleClub: string
};

type Props = {
  content: {}
} & DispatchProps &
  StateProps;

type State = {
  profil: string | null,
  email: string | null,
  consentOpt1: boolean,
  consentOpt2: boolean,
  emailValide: boolean
};

export default class NewsletterOptinModal extends Component<Props, State> {
  state: State = {
    profil: null,
    email: null,
    consentOpt1: false,
    consentOpt2: false,
    emailValide: false
  };

  componentDidMount = () => {
    const { email } = this.state;
    const { newsletterSetting, fetchOptions } = this.props;

    if (email !== null && validateEmail(email)) {
      this.setState({ emailValide: true });
    }

    if (newsletterSetting.type === 'campaign_monitor') {
      fetchOptions(newsletterSetting.key || '');
    }
  };

  handleSubmitClicked = (e: SyntheticInputEvent<EventTarget>) => {
    const { profil, email, consentOpt1, consentOpt2, emailValide } = this.state;
    const {
      displayModal,
      subscribeNewsletter,
      userHasSubscribedNewsletter,
      newsletterSetting: { key },
      titleClub
    } = this.props;
    const campaignMonitorKey = !key ? '' : key;

    e.preventDefault();
    if (profil === null || email === null) {
      this.setState({
        profil: profil === null ? '' : profil,
        email: email === null ? '' : email
      });
    } else if (!consentOpt1) {
      displayModal('NEWSLETTER_MISSING_OPT', { titleClub }, true);
    } else if (profil !== '' && emailValide) {
      const options = {
        ConsentToTrack: consentOpt1 ? 'yes' : 'no',
        CustomFields: [
          {
            Key: 'Jesuis',
            Value: profil
          },
          {
            Key: 'Optin1',
            Value: consentOpt1 ? 'yes' : 'no'
          },
          {
            Key: 'Optin2',
            Value: consentOpt2 ? 'yes' : 'no'
          }
        ],
        EmailAddress: email,
        Name: '',
        action: 'subscribe'
      };
      subscribeNewsletter(options, campaignMonitorKey);
      userHasSubscribedNewsletter(true);
    }
  };

  handleChange = (email: string) => {
    if (validateEmail(email)) {
      this.setState({
        email,
        emailValide: true
      });
    } else {
      this.setState({ emailValide: false });
    }
  };

  setProfil = (profil: string) => {
    this.setState({ profil });
  };

  setConsent = (value: boolean, checkboxType: string) => {
    this.setState({ [checkboxType]: value });
  };

  render() {
    const {
      options: { options }
    } = this.props;
    const { email, emailValide, profil } = this.state;
    const labels = {
      optin1: `J’autorise la FFR à utiliser mes données personnelles 
      (e-mail, adresse postale) à des fins promotionnelles 
      ou commerciales pour ses propres activités (billetterie, produits dérivés).`,
      optin2: `J’autorise la FFR à transmettre certaines de mes données personnelles 
      (nom, prénom, e-mail, adresse postale) à des tiers, et notamment ses 
      partenaires et fournisseurs officiels, à des fins commerciales, 
      associatives ou humanitaires.`
    };

    return (
      <Fragment>
        <div className="input input--material mb-3">
          <TextInput
            id="MqTXPkQDuFNR"
            label="Votre adresse email *"
            type="text"
            hasError={email !== null && !validateEmail(email)}
            onChange={this.handleChange}
          />
          <SelectInput
            placeholder="Je suis..."
            options={options.map(option => ({ label: option, value: option }))}
            onChange={this.setProfil}
            hasError={profil === ''}
          />
        </div>
        <CheckboxInput
          className={'checkbox'}
          id="hgsdfsddf1dsds23"
          label={labels['optin1']}
          onChange={value => this.setConsent(value, 'consentOpt1')}
        />
        <CheckboxInput
          className={'checkbox'}
          id="zgsdfsdsfedzddf123"
          label={labels['optin2']}
          onChange={value => this.setConsent(value, 'consentOpt2')}
        />
        <button
          className="btn btn--primary btn--full"
          onClick={this.handleSubmitClicked}
          type="submit"
          disabled={!emailValide}
        >
          {'S’inscrire'}
        </button>
      </Fragment>
    );
  }
}
