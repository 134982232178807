// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import type { GeneratorType } from 'sagas/root';
import type { FETCH_TEAM_CALENDAR_START_ACTION } from 'actions/team/teamCalendarActions';
import { type ResultCalendarContainerType } from 'types/Result';

import {
  FETCH_TEAM_CALENDAR_START,
  fetchTeamCalendarSuccess,
} from 'actions/team/teamCalendarActions';

import { fetchResultCalendarFromGraph } from 'api/graphApi/CalendarApi';

export default function() {
  return function* teamCalendarSaga(): GeneratorType {
    yield takeLatest(FETCH_TEAM_CALENDAR_START, fetchResultTeam);
  };

  function* fetchResultTeam(action: FETCH_TEAM_CALENDAR_START_ACTION): Saga<void> {
    try {
      const { payload: { clubID, competitions } } = action;

      const competitionsResponse: ResultCalendarContainerType = yield call(
        fetchResultCalendarFromGraph,
        competitions.map((c) => parseInt(c, 10)),
        parseInt(clubID, 10)
      );
      yield put(fetchTeamCalendarSuccess(competitionsResponse));

    } catch (err) {
      console.log(err);
      // @TODO: Catch error
    }
  }
};
