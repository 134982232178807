// @flow

import { FETCH_SETTINGS_SUCCESS } from 'actions/appActions';
import { FETCH_PAGE_LIST_SUCCESS } from 'actions/pageActions';

import type { ActionType } from 'types/Actions';
import type { ListHomeCardType } from 'types/Settings';
import type { CardType } from 'types/PageList';

export type State = {
  liste_cards_home: Array<ListHomeCardType>,
  liste_cards: Array<CardType>,
  titre_liste_cards: string,
};

const initialState: State = {
  liste_cards_home: [
    {
      menu_order: 0,
      object_id: 0,
      type_name: '',
      object_slug: '',
      parent_slug: '',
      title: '',
      description: '',
      featured_image: {},
      texte_cta: ''
    }
  ],
  liste_cards: [
    {
      menu_order: 0,
      object_id: 0,
      type_name: '',
      object_slug: '',
      title: '',
      description: '',
      featured_media: {
        src: ''
      },
      texte_cta: '',
      types_public: []
    }
  ],
  titre_liste_cards: '',
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_SETTINGS_SUCCESS:
      return {
        ...state,
        liste_cards_home: action.payload.settings.liste_cards,
        titre_liste_cards: action.payload.settings.titre_liste_cards,
      };
    case FETCH_PAGE_LIST_SUCCESS:
      return {
        ...state,
        liste_cards: action.payload.pageList.liste_enfants,
      };
    default:
      return state;
  }
}
