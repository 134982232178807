// @flow
import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import PartnerApi from 'api/partner/PartnerApi';
import { FETCH_PARTNER_START, partnersFetched, partnersFetchError } from 'actions/partnerActions';
import type { FETCH_PARTNER_START_ACTION } from 'actions/partnerActions';

export default function(apiExecutor: ApiExecutorType) {
  const partnerApi = new PartnerApi(apiExecutor);

  return function* articleSaga(): GeneratorType {
    yield takeLatest(FETCH_PARTNER_START, fetchPartners);
  };

  function* fetchPartners(action: FETCH_PARTNER_START_ACTION): Saga<void> {
    try {
      // @TODO when static
      // const partners = yield call(partnerApi.fetchPartners);
      const partners = yield call(partnerApi.fetchTmpPartners);
      yield put(partnersFetched(partners));
    } catch (e) {
      yield put(partnersFetchError(e));
    }
  }
}
