// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import { FETCH_YOUTUBE_START, youtubePostsFetched, youtubePostsFetchError } from 'actions/youtubeActions';
import YoutubeApi from 'api/social/YoutubeApi';
import type { FETCH_YOUTUBE_START_ACTION } from 'actions/youtubeActions';

export default function(apiExecutor: ApiExecutorType) {
  const youtubeApi = new YoutubeApi(apiExecutor);

  return function* youtubeSaga(): GeneratorType {
    yield takeLatest(FETCH_YOUTUBE_START, fetchYoutubePosts);
  };

  function* fetchYoutubePosts(action: FETCH_YOUTUBE_START_ACTION): Saga<void> {
    try {
      const posts = yield call(youtubeApi.fetchTmpPosts);
      yield put(youtubePostsFetched(posts));
    } catch (e) {
      yield put(youtubePostsFetchError());
    }
  }
}
