// @flow

import type { CategoryType } from 'types/Article';

export const FETCH_CATEGORIES_START = 'FETCH_CATEGORIES_START';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';

export type FETCH_CATEGORIES_START_ACTION = {
  type: 'FETCH_CATEGORIES_START'
};

export type FETCH_CATEGORIES_FAILURE_ACTION = {
  type: 'FETCH_CATEGORIES_FAILURE'
};

export type FETCH_CATEGORIES_SUCCESS_ACTION = {
  type: 'FETCH_CATEGORIES_SUCCESS',
  payload: {
    categories: Array<CategoryType>
  }
};

export type Action = FETCH_CATEGORIES_START_ACTION | FETCH_CATEGORIES_FAILURE_ACTION | FETCH_CATEGORIES_SUCCESS_ACTION;

export function fetchCategories(): FETCH_CATEGORIES_START_ACTION {
  return {
    type: FETCH_CATEGORIES_START
  };
}

export function categoriesFetched(categories: Array<CategoryType>): FETCH_CATEGORIES_SUCCESS_ACTION {
  return {
    type: FETCH_CATEGORIES_SUCCESS,
    payload: { categories }
  };
}

export function categoriesFetchError(error: any): FETCH_CATEGORIES_FAILURE_ACTION {
  return {
    type: FETCH_CATEGORIES_FAILURE
  };
}
