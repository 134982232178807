// @flow
import React from 'react';
import { Link } from 'react-router-dom';

import { ffrName } from 'utils/ffrNameUtils';
import type { StructureType} from 'types/Result';

type Props = {
  url: string,
  competition: string,
  localStructure: StructureType,
  visiteurStructure: StructureType,
  localScore: string,
  visiteurScore: string,
  minutes: string,
};

const CardLiveMatch = ({
  localStructure,
  visiteurStructure,
  visiteurScore,
  localScore,
  minutes,
  competition,
  url,
}: Props) => (
  <Link to={url} className="match match--widget ">
    <span className="match__division ft-h6 ft-up">{competition}</span>
    <div>
      <div className="match__club">
        <p>
          <img src={localStructure.embleme} alt={`Logo ${localStructure.nom}`} width="24" height="24" />
          {ffrName(localStructure.nom)}
        </p>
      </div>
      <div className="match__club">
        <p className="ft-700">
          <img src={visiteurStructure.embleme} alt={`Logo ${visiteurStructure.nom}`} width="24" height="24"/>
          {ffrName(visiteurStructure.nom)}
        </p>
      </div>
      <div className="match__info">
        <p className="match__progress">
          {minutes}
          &apos;
        </p>
        <p className="match__score">
          <span>{localScore}</span>
          <span className="match__colon">-</span>
          <span className="ft-700">{visiteurScore}</span>
        </p>
      </div>
    </div>
  </Link>
);

export default CardLiveMatch;
