export const install = (trackingId, additionalConfigInfo = {}) => {
  const scriptId = `didomi-${trackingId}`;

  if (document.getElementById(scriptId)) return;

  const { head } = document;
  const script = document.createElement('script');
  script.id = scriptId;
  script.type = 'text/javascript';

  script.innerHTML = `
  window.gdprAppliesGlobally=true;
  (function(){(function(e,r){var t=document.createElement("link");t.rel="preconnect";t.as="script";var n=document.createElement("link");n.rel="dns-prefetch";n.as="script";var i=document.createElement("link");i.rel="preload";i.as="script";var o=document.createElement("script");o.id="spcloader";o.type="text/javascript";o["async"]=true;o.charset="utf-8";var a="https://sdk.privacy-center.org/"+e+"/loader.js?target_type=notice&target="+r;if(window.didomiConfig&&window.didomiConfig.user){var c=window.didomiConfig.user;var d=c.country;var s=c.region;if(d){a=a+"&country="+d;if(s){a=a+"&region="+s}}}t.href="https://sdk.privacy-center.org/";n.href="https://sdk.privacy-center.org/";i.href=a;o.src=a;var l=document.getElementsByTagName("script")[0];l.parentNode.insertBefore(t,l);l.parentNode.insertBefore(n,l);l.parentNode.insertBefore(i,l);l.parentNode.insertBefore(o,l)})("eaced2d8-e3d6-4491-a178-e6fbd6138906","LRe98eW8")})();`;

  head.insertBefore(script, head.firstChild);
};

export default {
  install
};
