// @flow
import React from 'react';
import Loadable from 'react-loadable';
import { Route, Switch } from 'react-router-dom';

import TeamRouter from 'containers/app/TeamRoutesContainer';
import Loading from 'components/fragments/Loading';

import type { ComponentType } from 'react';
import type { RouterProps } from 'types/Router';
import { getBaseUrl } from 'constants/api';

const makeAsyncComponent = (componentImport: Function): $ReadOnly<Object> => {
  return Loadable({
    loader: componentImport,
    loading() {
      return <Loading />;
    }
  });
};

const makeAsyncComponentWhite = (componentImport: Function): $ReadOnly<Object> => {
  return Loadable({
    loader: componentImport,
    loading() {
      return <Loading isWhite />;
    }
  });
};

const TagArticle = makeAsyncComponent(() => import('containers/tagarticle/TagArticleContainer'));
const Newsletter = makeAsyncComponent(() => import('containers/newsletter/NewsletterContainer'));
const NewsletterCategoryContainer = makeAsyncComponent(() =>
  import('containers/newsletter/NewsletterCategoryContainer')
);
const Article = makeAsyncComponentWhite(() => import('containers/newsletter/ArticleContainer'));
const Billboard = makeAsyncComponent(() => import('containers/billboard/BillboardContainer'));
const PageListContainer = makeAsyncComponent(() => import('containers/page/PageListContainer'));
const PageNodeContainer = makeAsyncComponent(() => import('containers/page/PageNodeContainer'));
const PageNotFound = makeAsyncComponent(() => import('components/app/PageNotFound'));
const SocialWallContainer = makeAsyncComponent(() => import('containers/social/SocialWallContainer'));
const TeamsContainer = makeAsyncComponent(() => import('containers/team/TeamsContainer'));
const ContactWrapperContainer = makeAsyncComponent(() => import('containers/contact/ContactWrapperContainer'));
const FaqWrapperContainer = makeAsyncComponent(() => import('containers/contact/FaqWrapperContainer'));
const EventDetailContainer = makeAsyncComponentWhite(() => import('containers/event/EventDetailContainer'));
const EventListContainer = makeAsyncComponent(() => import('containers/event/EventListContainer'));
const SearchWrapper = makeAsyncComponent(() => import('components/search/SearchWrapper'));
const RencontreContainer = makeAsyncComponent(() => import('containers/rencontre/RencontreContainer'));
const PageMediaDetailContainer = makeAsyncComponent(() => import('containers/page/PageMediaDetailContainer'));
const PageNewsletterSuccessContainer = 
  makeAsyncComponent(() => import('containers/page/PageNewsletterSuccessContainer'));

Billboard.preload();
Article.preload();

export function withProps(externalProps: Object) {
  return (WrapperComponent: ComponentType<Object>) => {
    return class HOSWrapperComponent extends React.PureComponent<Object> {
      render() {
        return <WrapperComponent {...this.props} {...externalProps} />;
      }
    };
  };
}

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Billboard} />
    <Route exact path='/admin' component={() => { 
        window.location.replace(getBaseUrl()+'/wp-admin/');
        return null;
    }}/>
    <Route exact path="/newbloguser/:slugUser/" component={(props: RouterProps) => {
        const { 
          match: {
              params: {
                slugUser
              }
        } } = props;
        if (slugUser !== null && slugUser !== undefined) {
          window.location.replace(getBaseUrl()+'/newbloguser/'+slugUser+'/');
        } else {
          return (<PageNotFound />);
        }
        return null;
    }}/>
    <Route path="/actualites/:category/:slug" component={Article} />
    <Route exact path="/tags/:slug" component={TagArticle} />
    <Route exact path="/actualites" component={Newsletter} />
    <Route exact path="/actualites/:category" component={NewsletterCategoryContainer} />
    <Route exact path="/equipes" component={TeamsContainer} />
    <Route exact path={`/equipes/:slug/match/:rencontre_id/:tab?`} component={RencontreContainer} />
    <Route path="/equipes/:slug" component={TeamRouter} />
    <Route path="/match/:rencontre_id/:tab?" component={RencontreContainer} />
    <Route exact path="/social-wall" component={SocialWallContainer} />
    <Route exact path="/contact" component={ContactWrapperContainer} />
    <Route exact path="/contact/nous-contacter" component={withProps({ form: true })(ContactWrapperContainer)} />
    <Route exact path="/contact/:slugTheme" component={ContactWrapperContainer} />
    <Route exact path="/faq" component={FaqWrapperContainer} />
    <Route exact path="/faq/:slugTheme" component={FaqWrapperContainer} />
    <Route exact path="/faq/:slugTheme/:slugQuestion" component={FaqWrapperContainer} />
    <Route exact path="/recherche" component={SearchWrapper} />
    <Route exact path="/evenements/:slug" component={EventDetailContainer} />
    <Route path="/evenements/:year/:month" component={EventListContainer} />
    <Route path="/evenements" component={EventListContainer} />
    <Route exact path="/inscription-newsletter" component={PageNewsletterSuccessContainer} />
    <Route exact path="/mediatheque/:slug" component={PageMediaDetailContainer} />
    <Route exact path="/:slug" component={PageListContainer} />
    <Route path="/:slug/:slugNode" component={PageNodeContainer} />
    <Route path="/404" component={PageNotFound} />
    <Route component={PageNotFound} />
  </Switch>
);

export default Routes;
