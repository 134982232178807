// @flow
export const MOBILE_SIZE: number = 768;
export const FB_API_KEY: string = '502114410252527';
export const MEDIA_UPLOADING_LIMIT: number = 3;
export const MEDIA_UPLOADING_MAX_SIZE: number = 1600;
/*export const VIDEO_FORMAT_WHITELIST = [
  'video/quicktime',
  'video/ogg',
  'video/3gpp',
  'video/mpeg',
  'video/mp4',
  'video/x-ms-wmv',
];*/
export const VIDEO_FORMAT_WHITELIST = ['video/quicktime', 'video/mp4'];
