// @flow

import { takeLatest, put, call } from 'redux-saga/effects';

import PartenaireApi from 'api/partenaire/PartenaireApi';

import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';

import {
  FETCH_PARTENAIRE_START,
  fetchPartenaireSuccess,
  fetchPartenaireFailure,
} from 'actions/partenaireActions';
import type {
  FETCH_PARTENAIRE_START_ACTION,
} from 'actions/partenaireActions';

export default function(apiExecutor: ApiExecutorType) {
  const partenaireApi = new PartenaireApi(apiExecutor);

  function* fetchPartenaire(action: FETCH_PARTENAIRE_START_ACTION): Saga<void> {
    const { slug } = action.payload;
    try {
      const partenaire = yield call(partenaireApi.fetchPartenaire, slug);
      if (partenaire && partenaire.id && partenaire.id > 0) {
        yield put(fetchPartenaireSuccess(partenaire));
      } else {
        yield put(fetchPartenaireFailure('0 partenaire'));
      }
    } catch (error) {
      yield put(fetchPartenaireFailure(error));
    }
  }

  return function* partenaireSaga(): GeneratorType {
    yield takeLatest(FETCH_PARTENAIRE_START, fetchPartenaire);
  };
}
