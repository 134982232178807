// @flow

import type { ContactFormType, InputValue, SingleContactFormType } from 'types/ContactForm7';

export const FETCH_CF7_CONTACT_FORM_START = 'FETCH_CF7_CONTACT_FORM_START';
export const FETCH_CF7_CONTACT_FORM_SUCCESS = 'FETCH_CF7_CONTACT_FORM_SUCCESS';
export const FETCH_CF7_CONTACT_FORM_FAILURE = 'FETCH_CF7_CONTACT_FORM_FAILURE';
export const FETCH_SINGLE_CF7_CONTACT_FORM_START = 'FETCH_SINGLE_CF7_CONTACT_FORM_START';
export const FETCH_SINGLE_CF7_CONTACT_FORM_SUCCESS = 'FETCH_SINGLE_CF7_CONTACT_FORM_SUCCESS';
export const FETCH_ALL_CFA7_CONTACT_FORM_START = 'FETCH_ALL_CFA7_CONTACT_FORM_START';
export const FETCH_ALL_CF7_CONTACT_FORM_SUCCESS = 'FETCH_ALL_CF7_CONTACT_FORM_SUCCESS';

export const SEND_CF7_CONTACT_FORM_START = 'SEND_CF7_CONTACT_FORM_START';
export const SEND_CF7_CONTACT_FORM_SUCCESS = 'SEND_CF7_CONTACT_FORM_SUCCESS';
export const SEND_CF7_CONTACT_FORM_FAILURE = 'SEND_CF7_CONTACT_FORM_FAILURE';

export const RESET_SEND_TO_CF7_STATUS = 'RESET_SEND_TO_CF7_STATUS';

export type FETCH_CONTACT_FORM_7_START_ACTION = {
  type: 'FETCH_CF7_CONTACT_FORM_START'
};

export type FETCH_CONTACT_FORM_7_FAILURE_ACTION = {
  type: 'FETCH_CF7_CONTACT_FORM_FAILURE'
};

export type FETCH_CONTACT_FORM_7_SUCCESS_ACTION = {
  type: 'FETCH_CF7_CONTACT_FORM_SUCCESS',
  payload: {
    contactForm: ContactFormType
  }
};

export type FETCH_SINGLE_CONTACT_FORM_7_START_ACTION = {
  type: 'FETCH_SINGLE_CF7_CONTACT_FORM_START',
  payload: {
    id: number
  }
};

export type FETCH_SINGLE_CONTACT_FORM_7_SUCCESS_ACTION = {
  type: 'FETCH_SINGLE_CF7_CONTACT_FORM_SUCCESS',
  payload: {
    singleContactForm: SingleContactFormType
  }
};

export type FETCH_ALL_CONTACT_FORM_7_START_ACTION = {
  type: 'FETCH_ALL_CFA7_CONTACT_FORM_START'
};

export type FETCH_ALL_CONTACT_FORM_7_SUCCESS_ACTION = {
  type: 'FETCH_ALL_CF7_CONTACT_FORM_SUCCESS',
  payload: {
    contactFormList: Array<ContactFormType>
  }
};

export type SEND_CONTACT_FORM_7_START_ACTION = {
  type: 'SEND_CF7_CONTACT_FORM_START',
  payload: {
    formInputs: { [key: string]: InputValue },
    id: number
  }
};

export type SEND_CONTACT_FORM_7_FAILURE_ACTION = {
  type: 'SEND_CF7_CONTACT_FORM_FAILURE'
};

export type SEND_CONTACT_FORM_7_SUCCESS_ACTION = {
  type: 'SEND_CF7_CONTACT_FORM_SUCCESS'
};

export type RESET_SEND_TO_CF7_STATUS_ACTION = {
  type: 'RESET_SEND_TO_CF7_STATUS'
};

export type Action =
  | FETCH_CONTACT_FORM_7_START_ACTION
  | FETCH_CONTACT_FORM_7_FAILURE_ACTION
  | FETCH_CONTACT_FORM_7_SUCCESS_ACTION
  | FETCH_SINGLE_CONTACT_FORM_7_START_ACTION
  | FETCH_SINGLE_CONTACT_FORM_7_SUCCESS_ACTION
  | FETCH_ALL_CONTACT_FORM_7_START_ACTION
  | FETCH_ALL_CONTACT_FORM_7_SUCCESS_ACTION
  | SEND_CONTACT_FORM_7_START_ACTION
  | SEND_CONTACT_FORM_7_FAILURE_ACTION
  | SEND_CONTACT_FORM_7_SUCCESS_ACTION
  | RESET_SEND_TO_CF7_STATUS_ACTION;

export function fetchCF7ContactForm(): FETCH_CONTACT_FORM_7_START_ACTION {
  return {
    type: FETCH_CF7_CONTACT_FORM_START
  };
}

export function fetchSingleCF7FormContact(id: number): FETCH_SINGLE_CONTACT_FORM_7_START_ACTION {
  return {
    type: FETCH_SINGLE_CF7_CONTACT_FORM_START,
    payload: {
      id
    }
  };
}

export function fetchAllContactForm7Forms(): FETCH_ALL_CONTACT_FORM_7_START_ACTION {
  return {
    type: FETCH_ALL_CFA7_CONTACT_FORM_START
  };
}

export function cf7FormFetched(contactForm: ContactFormType): FETCH_CONTACT_FORM_7_SUCCESS_ACTION {
  return {
    type: FETCH_CF7_CONTACT_FORM_SUCCESS,
    payload: { contactForm }
  };
}

export function singlecf7FormFetched(
  singleContactForm: SingleContactFormType
): FETCH_SINGLE_CONTACT_FORM_7_SUCCESS_ACTION {
  return {
    type: FETCH_SINGLE_CF7_CONTACT_FORM_SUCCESS,
    payload: { singleContactForm }
  };
}

export function cf7FormListFetched(contactFormList: Array<ContactFormType>): FETCH_ALL_CONTACT_FORM_7_SUCCESS_ACTION {
  return {
    type: FETCH_ALL_CF7_CONTACT_FORM_SUCCESS,
    payload: { contactFormList }
  };
}

export function cf7FormFetchedError(error: any): FETCH_CONTACT_FORM_7_FAILURE_ACTION {
  return {
    type: FETCH_CF7_CONTACT_FORM_FAILURE
  };
}

export function postContactForm7(
  formInputs: { [key: string]: InputValue },
  id: number
): SEND_CONTACT_FORM_7_START_ACTION {
  return {
    type: SEND_CF7_CONTACT_FORM_START,
    payload: {
      formInputs,
      id
    }
  };
}

export function cf7FormPosted(): SEND_CONTACT_FORM_7_SUCCESS_ACTION {
  return {
    type: SEND_CF7_CONTACT_FORM_SUCCESS
  };
}

export function cf7FormPostError(error: any): SEND_CONTACT_FORM_7_FAILURE_ACTION {
  return {
    type: SEND_CF7_CONTACT_FORM_FAILURE
  };
}

export const resetCF7ContactFormStatus = (): RESET_SEND_TO_CF7_STATUS_ACTION => ({
  type: RESET_SEND_TO_CF7_STATUS
});
