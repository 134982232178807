// @flow
import type { rawContactFormResultType, ContactFormResultType } from 'types/Media';
import { nullContactFormResult } from 'types/Media';
import { mergeIntoDefault } from 'utils/objectUtils';

class ErreurForm extends Error {
  invalidFields: Array<{ into: string, message: string }>;
  constructor(invalidFields, ...params) {
    // Passer les arguments restants (incluant ceux spécifiques au vendeur) au constructeur parent
    super(...params);

    // Maintenir dans la pile une trace adéquate de l'endroit où l'erreur a été déclenchée (disponible seulement en V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ErreurForm);
    }
    this.name = 'Error form';
    // Informations de déboguage personnalisées
    this.invalidFields = invalidFields;
  }
}

export function convertMediaResponseFromApi(rawContactFormResult: rawContactFormResultType): ContactFormResultType {
  if (!rawContactFormResult) {
    return nullContactFormResult;
  }

  const { into, status, message, invalidFields } = rawContactFormResult;

  if (status === 'validation_failed') {
    const error = new ErreurForm(invalidFields, 'Validation failed');
    throw error;
  }

  return mergeIntoDefault(nullContactFormResult, {
    into,
    status,
    message
  });
}
