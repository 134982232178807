// @flow

export const FETCH_TEAM_PLAYER_START = 'FETCH_TEAM_PLAYER_START';
export const FETCH_TEAM_PLAYER_SUCCESS = 'FETCH_TEAM_PLAYER_SUCCESS';
export const FETCH_TEAM_PLAYER_FAILURE = 'FETCH_TEAM_PLAYER_FAILURE';

export type FETCH_TEAM_PLAYER_START_ACTION = {
    type: 'FETCH_TEAM_PLAYER_START',
    payload: {
        classeAge: string,
        clubID: number | string,
        sexe: string,
        season: number | string,
    }
};

export type FETCH_TEAM_PLAYER_FAILURE_ACTION = {
    type: 'FETCH_TEAM_PLAYER_FAILURE',
    payload: {
        season: number | string,
    },
};

export type FETCH_TEAM_PLAYER_SUCCESS_ACTION = {
    type: 'FETCH_TEAM_PLAYER_SUCCESS',
    payload: {
        players: {
            fdm: boolean,
            id: string,
            nom: string,
            nom_f: string,
            numeroAffiliation?: string,
            photo: any,
            poste: string
        }[],
        season: number | string,
    },
};

export type Action = FETCH_TEAM_PLAYER_START_ACTION
    | FETCH_TEAM_PLAYER_FAILURE_ACTION
    | FETCH_TEAM_PLAYER_SUCCESS_ACTION;


export function fetchTeamPlayerStart(
    classeAge: string,
    clubID: number | string,
    sexe: string,
    season: number | string,
): FETCH_TEAM_PLAYER_START_ACTION {
    return {
        type: FETCH_TEAM_PLAYER_START,
        payload: {
            classeAge,
            clubID,
            sexe,
            season,
        }
    };
}

export function fetchTeamPlayerSuccess(
    players: any[],
    season: number | string,
): FETCH_TEAM_PLAYER_SUCCESS_ACTION {
    return {
        type: FETCH_TEAM_PLAYER_SUCCESS,
        payload: {
            players,
            season,
        },
    };
}

export function fetchTeamPlayerError(season: number): FETCH_TEAM_PLAYER_FAILURE_ACTION {
    return {
        type: FETCH_TEAM_PLAYER_FAILURE,
        payload: {
            season,
        },
    };
}
