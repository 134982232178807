// @flow
import React from 'react';

type Props = {
  content: any,
  hideModal: () => void
};

function NewsletterSubscriptionSuccessModalContent({ content, hideModal }: Props) {
  return (
    <div className="modal-content box newsletter-valid">
      <i className="icon icon-close js-closeModal" role="button" tabIndex={0} onClick={hideModal}></i>
      <div className="modal__wrapper">
        <div className="modal__body modal__body--medium narrow-2">
          <div className="circle-icon circle-icon--blue"></div>
          <h3 className="mb-1 mt-2 ft-h2">Inscription validée</h3>
          <p className="mb-1 ft-center">Nous avons bien pris en compte votre inscription à la newsletter du club.</p>
        </div>
      </div>
    </div>
  );
}

export default NewsletterSubscriptionSuccessModalContent;
