// @flow

import type { SearchType } from 'types/Search';

export const FETCH_SEARCH_START = 'FETCH_SEARCH_START';
export const FETCH_SEARCH_SUCCESS = 'FETCH_SEARCH_SUCCESS';
export const FETCH_SEARCH_FAILURE = 'FETCH_SEARCH_FAILURE';

export type FETCH_SEARCH_START_ACTION = {
  type: 'FETCH_SEARCH_START',
  payload: {
    input: string
  }
};

export type FETCH_SEARCH_FAILURE_ACTION = {
  type: 'FETCH_SEARCH_FAILURE'
};

export type FETCH_SEARCH_SUCCESS_ACTION = {
  type: 'FETCH_SEARCH_SUCCESS',
  payload: {
    search: SearchType
  }
};

export type Action = FETCH_SEARCH_START_ACTION | FETCH_SEARCH_FAILURE_ACTION | FETCH_SEARCH_SUCCESS_ACTION;

export function fetchSearch(input: string): FETCH_SEARCH_START_ACTION {
  return {
    type: FETCH_SEARCH_START,
    payload: { input }
  };
}

export function searchFetched(search: SearchType): FETCH_SEARCH_SUCCESS_ACTION {
  return {
    type: FETCH_SEARCH_SUCCESS,
    payload: { search }
  };
}

export function searchFetchError(error: any): FETCH_SEARCH_FAILURE_ACTION {
  return {
    type: FETCH_SEARCH_FAILURE
  };
}
