// @flow

import { fork, spawn, all } from 'redux-saga/effects';
import { BASE_URL, STATIC_BASE_URL } from 'constants/api';
import api from 'infrastructure/http/index';
import hitTagsSaga from 'sagas/hitTagsSaga.js';
import eventsSaga from 'sagas/eventsSaga.js';
import tagsSaga from 'sagas/tagsSaga.js';
import articleSaga from 'sagas/articleSaga.js';
import newsletterSaga from 'sagas/newsletterSaga.js';
import menuSaga from 'sagas/menuSaga';
import newsletterCategorySaga from 'sagas/newsletterCategorySaga.js';
import partnerSaga from 'sagas/partnerSaga.js';
import settingsSaga from 'sagas/settingsSaga.js';
import pageSaga from 'sagas/pageSaga.js';
import sidebarSaga from 'sagas/sidebarSaga';
import faqSaga from 'sagas/faqSaga';
import searchSaga from 'sagas/searchSaga';
import newsletterSubscriptionSaga from 'sagas/newsletterSubscriptionSaga';
import instagramSaga from 'sagas/instagramSaga';
import facebookSaga from 'sagas/facebookSaga';
import twitterSaga from 'sagas/twitterSaga';
import youtubeSaga from 'sagas/youtubeSaga';
import seasonsSaga from 'sagas/seasonsSaga';
import rencontreSaga from 'sagas/rencontreSaga';
import rencontreLiveSaga from 'sagas/rencontreLiveSaga';
import rankingSaga from 'sagas/rankingSaga';
import teamSaga from 'sagas/team/teamSaga';
import teamResultSaga from 'sagas/team/teamResultSaga';
import teamCalendarSaga from 'sagas/team/teamCalendarSaga';
import albumSaga from 'sagas/albumSaga';
import partenaireSaga from 'sagas/partenaireSaga';
import mediaSaga from 'sagas/mediaSaga';
import tagArticleSaga from 'sagas/tagArticleSaga.js';
import teamPlayerSaga from 'sagas/team/teamPlayerSaga';
import contactForm7Saga from 'sagas/contactForm7Saga';
import homeSaga from 'sagas/homeSaga';

export type GeneratorType = Generator<*, *, *>;

export default function*(): GeneratorType {
  yield fork(bootstrap);
}

function* bootstrap(): GeneratorType {
  const apiExecutor = api(BASE_URL);
  const staticApiExecutor = api(STATIC_BASE_URL);

  try {
    yield all([
      spawn(hitTagsSaga()),
      spawn(articleSaga(apiExecutor, staticApiExecutor)),
      spawn(newsletterSaga(apiExecutor, staticApiExecutor)),
      spawn(eventsSaga(apiExecutor, staticApiExecutor)),
      spawn(tagsSaga(apiExecutor)),
      spawn(pageSaga(apiExecutor)),
      spawn(sidebarSaga(apiExecutor).sidebarSaga),
      spawn(faqSaga(apiExecutor)),
      spawn(searchSaga(apiExecutor)),
      spawn(newsletterCategorySaga(apiExecutor)),
      spawn(newsletterSubscriptionSaga(apiExecutor)),
      spawn(settingsSaga(apiExecutor)),
      spawn(menuSaga(apiExecutor)),
      spawn(partnerSaga(apiExecutor)),
      spawn(instagramSaga(apiExecutor)),
      spawn(facebookSaga(apiExecutor)),
      spawn(twitterSaga(apiExecutor)),
      spawn(youtubeSaga(apiExecutor)),
      spawn(seasonsSaga(staticApiExecutor)),
      spawn(rencontreSaga()),
      spawn(rencontreLiveSaga()),
      spawn(rankingSaga),
      spawn(teamSaga(apiExecutor)),
      spawn(teamResultSaga()),
      spawn(teamCalendarSaga()),
      spawn(albumSaga(apiExecutor)),
      spawn(partenaireSaga(apiExecutor)),
      spawn(mediaSaga(apiExecutor)),
      spawn(tagArticleSaga(apiExecutor)),
      spawn(teamPlayerSaga()),
      spawn(contactForm7Saga(apiExecutor)),
      spawn(homeSaga(apiExecutor))
    ]);
  } catch (e) {
    // @TODO
  }
}
