// @flow

import type { ApiExecutorType } from 'types/ApiExecutorType';
import { convertSidebarFromApi } from 'services/Sidebar';

function SidebarApi(executor: ApiExecutorType) {
  return {
    fetchGenericSidebar
  };

 function fetchGenericSidebar() {
    return executor.get(`ffr/v1/pages?slug=actualites`)
            .then(response => convertSidebarFromApi(response));
  }
}

export default SidebarApi;
