// @flow

import type { CompetitionResultType } from 'types/Result';
import type { TeamStatsType } from 'types/TeamStats';

export const FETCH_TEAM_RESULT_START = 'FETCH_TEAM_RESULT_START';
export const FETCH_TEAM_RESULT_SUCCESS = 'FETCH_TEAM_RESULT_SUCCESS';
export const FETCH_TEAM_RESULT_FAILURE = 'FETCH_TEAM_RESULT_FAILURE';

export type FETCH_TEAM_RESULT_START_ACTION = {
  type: 'FETCH_TEAM_RESULT_START',
  payload: {
    clubID: string,
    competitions: string[] | null,
    season?: string,
  }
};

export type FETCH_TEAM_RESULT_FAILURE_ACTION = {
  type: 'FETCH_TEAM_RESULT_FAILURE',
  payload: {
    season?: number,
  },
};

export type FETCH_TEAM_RESULT_SUCCESS_ACTION = {
  type: 'FETCH_TEAM_RESULT_SUCCESS',
  payload: {
    competitions: CompetitionResultType[],
    teamStats: TeamStatsType,
    season?: number,
  },
};

export type Action = FETCH_TEAM_RESULT_START_ACTION
  | FETCH_TEAM_RESULT_FAILURE_ACTION
  | FETCH_TEAM_RESULT_SUCCESS_ACTION;


export function fetchTeamResultStart(
  clubID: string, competitions: string[] | null, season?: string
): FETCH_TEAM_RESULT_START_ACTION {
  return {
    type: FETCH_TEAM_RESULT_START,
    payload: {
      clubID,
      competitions,
      season,
    }
  };
}

export function fetchTeamResultSuccess(
  competitions: CompetitionResultType[],
  teamStats: TeamStatsType,
  season?: number,
): FETCH_TEAM_RESULT_SUCCESS_ACTION {
  return {
    type: FETCH_TEAM_RESULT_SUCCESS,
    payload: {
      competitions,
      teamStats,
      season,
    },
  };
}

export function fetchTeamResultError(season?: number): FETCH_TEAM_RESULT_FAILURE_ACTION {
  return {
    type: FETCH_TEAM_RESULT_FAILURE,
    payload: {
      season,
    },
  };
}
