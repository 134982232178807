// @flow

import type { HomeType } from 'types/Home';

export const FETCH_HOME_START = 'FETCH_HOME_START';
export const FETCH_HOME_SUCCESS = 'FETCH_HOME_SUCCESS';
export const FETCH_HOME_FAILURE = 'FETCH_HOME_FAILURE';

export type FETCH_HOME_START_ACTION = {
  type: 'FETCH_HOME_START'
};

export type FETCH_HOME_FAILURE_ACTION = {
  type: 'FETCH_HOME_FAILURE'
};

export type FETCH_HOME_SUCCESS_ACTION = {
  type: 'FETCH_HOME_SUCCESS',
  payload: {
    home: HomeType
  }
};

export type Action = FETCH_HOME_START_ACTION | FETCH_HOME_FAILURE_ACTION | FETCH_HOME_SUCCESS_ACTION;

export function fetchHome(): FETCH_HOME_START_ACTION {
  return {
    type: FETCH_HOME_START
  };
}

export function homeFetched(home: HomeType): FETCH_HOME_SUCCESS_ACTION {
  return {
    type: FETCH_HOME_SUCCESS,
    payload: { home }
  };
}

export function homeFetchError(): FETCH_HOME_FAILURE_ACTION {
  return {
    type: FETCH_HOME_FAILURE
  };
}
