// @flow

import type { SocialContent } from 'types/SocialContent';

export const FETCH_INSTAGRAM_START = 'FETCH_INSTAGRAM_START';
export const FETCH_INSTAGRAM_SUCCESS = 'FETCH_INSTAGRAM_SUCCESS';
export const FETCH_INSTAGRAM_FAILURE = 'FETCH_INSTAGRAM_FAILURE';

export type FETCH_INSTAGRAM_START_ACTION = {
  type: 'FETCH_INSTAGRAM_START'
};

export type FETCH_INSTAGRAM_FAILURE_ACTION = {
  type: 'FETCH_INSTAGRAM_FAILURE'
};

export type FETCH_INSTAGRAM_SUCCESS_ACTION = {
  type: 'FETCH_INSTAGRAM_SUCCESS',
  payload: {
    posts: Array<SocialContent>
  }
};

export type Action = FETCH_INSTAGRAM_START_ACTION | FETCH_INSTAGRAM_FAILURE_ACTION | FETCH_INSTAGRAM_SUCCESS_ACTION;

export function fetchInstagramPosts(): FETCH_INSTAGRAM_START_ACTION {
  return {
    type: FETCH_INSTAGRAM_START
  };
}

export function instagramPostsFetched(posts: Array<SocialContent>): FETCH_INSTAGRAM_SUCCESS_ACTION {
  return {
    type: FETCH_INSTAGRAM_SUCCESS,
    payload: { posts }
  };
}

export function instagramPostsFetchError(): FETCH_INSTAGRAM_FAILURE_ACTION {
  return {
    type: FETCH_INSTAGRAM_FAILURE
  };
}
