// @flow

import { takeLatest, put, call, take } from 'redux-saga/effects';
import { store } from './../store';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import ArticleApi from 'api/article/ArticleApi';
import {
  FETCH_ARTICLES_CATEGORY_START,
  articlesCategoryFetched,
  articlesCategoryFetchError
} from 'actions/newsletterCategoryActions';
import type { FETCH_ARTICLES_CATEGORY_START_ACTION } from 'actions/newsletterCategoryActions';
import {FETCH_CATEGORIES_SUCCESS} from 'actions/categoriesActions';
import { FETCH_PAGE_LIST_SUCCESS } from 'actions/pageActions';
import type { FETCH_PAGE_LIST_SUCCESS_ACTION } from 'actions/pageActions';

export default function(apiExecutor: ApiExecutorType) {
  const articleApi = new ArticleApi(apiExecutor);

  return function* newsletterSaga(): GeneratorType {
    yield takeLatest(FETCH_ARTICLES_CATEGORY_START, fetchArticles);
    yield takeLatest(FETCH_PAGE_LIST_SUCCESS, fetchArticlesFromPageList);
  };

  function* fetchArticles(action: FETCH_ARTICLES_CATEGORY_START_ACTION): Saga<void> {
    try {
      const { page, per_page, category } = action.payload;
      const {categoriesState} = store.getState();
      let categories = [];
      if (categoriesState.categories.length === 0) {
        const success = yield take(FETCH_CATEGORIES_SUCCESS);
        categories = success.payload.categories;
      } else {
        categories = categoriesState.categories;
      }
      const categoryObject = categories.find(c => c.slug === category);
      const { articles, articleNumber } = yield call(
        articleApi.fetchArticleByOneCategory,
        page,
        per_page,
        categoryObject
      );

      yield put(articlesCategoryFetched(articles, articleNumber));
    } catch (e) {
      yield put(articlesCategoryFetchError(e));
    }
  }

  function* fetchArticlesFromPageList(action: FETCH_PAGE_LIST_SUCCESS_ACTION): Saga<void> {
    try {
      const { label } = action.payload.pageList;
      const {categoriesState} = store.getState();
      let categories = [];
      if (categoriesState.categories.length === 0) {
        const success = yield take(FETCH_CATEGORIES_SUCCESS);
        categories = success.payload.categories;
      } else {
        categories = categoriesState.categories;
      }
      const categoryObject = categories.find(c => c.id === label.id);
      const { articles, articleNumber } = yield call(articleApi.fetchArticleByOneCategory, 1, 10, categoryObject);
      yield put(articlesCategoryFetched(articles, articleNumber));
    } catch (e) {
      yield put(articlesCategoryFetchError(e));
    }
  }
}
