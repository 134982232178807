// @flow
import {
  FETCH_TAG_ARTICLE_START,
  FETCH_TAG_ARTICLE_SUCCESS,
  FETCH_TAG_ARTICLE_FAILURE,
} from 'actions/tagArticleActions';
import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS, STATUS_DEFAULT } from 'constants/statusConstants';
import type { ActionType } from 'types/Actions';
import type { ArticleType } from 'types/Article';
import type { Status } from 'types/Status';

export type State = {
  status: Status,
  tag: {
    id: number,
    name: string,
    slug: string,
		source: string,
  },
  tagArticles: ArticleType[],
  tagArticleNumber: number,
};

const initialState: State = {
  tagArticles: [],
  status: STATUS_DEFAULT,
  tagArticleNumber: 0,
  tag: {
		id: 0,
		name: '',
		slug: '',
		source: ''
	},
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_TAG_ARTICLE_START:
      return {
        ...state,
        tagArticles: [],
        status: STATUS_LOADING,
      };
    case FETCH_TAG_ARTICLE_SUCCESS:
      return {
        ...state,
        tagArticles: action.payload.tagArticles,
        tagArticleNumber: action.payload.tagArticleNumber,
        tag: action.payload.tag,
        status: STATUS_SUCCESS
      };
    case FETCH_TAG_ARTICLE_FAILURE:
      return {
        ...state,
        tagArticles:[],
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
