// @flow
import React, { PureComponent } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';
import { STATUS_SUCCESS, STATUS_UNKNOWN } from 'constants/statusConstants';

import Loading from 'components/fragments/Loading';
import TeamHeader from 'containers/team/TeamHeaderContainer';

import type { Status } from 'types/Status';
import type { CardType } from 'types/PageList';
import type { Pratique } from 'types/Club';
import type { RouterProps } from 'types/Router';
import { MIN_EFFECTIF } from 'constants/TeamMenuConstants';

// $FlowFixMe
const makeAsyncComponent = (componentImport: Function): $ReadOnly<Object> => {
  return Loadable({
    loader: componentImport,
    loading() {
      return <Loading />;
    }
  });
};

const TeamDetailContainer = makeAsyncComponent(() => import('containers/team/teamDetail/TeamDetailContainer'));
const TeamResultContainer = makeAsyncComponent(() => import('containers/team/TeamResultContainer'));
const TeamCalendarContainer = makeAsyncComponent(() => import('containers/team/TeamCalendarContainer'));
const TeamPlayersContainer = makeAsyncComponent(() => import('containers/team/TeamPlayersContainer'));
const TeamInfoContainer = makeAsyncComponent(() => import('containers/team/TeamInfoContainer'));
const TeamRankingContainer = makeAsyncComponent(() => import('containers/team/TeamRankingContainer'));
const TeamStatsContainer = makeAsyncComponent(() => import('containers/team/TeamStatsContainer'));

export type StateProps = {
  organization: string,
  liste_enfants: CardType[],
  compte_joueurs: number,
  pratique: Pratique,
  status: Status,
}

export type DispatchProps = {
  fetchTeam: (slug: string) => void,
  sendVpv: () => void,
}

type Props = RouterProps & StateProps & DispatchProps & RouterProps;

class TeamRoutes extends PureComponent<Props> {
  componentDidMount() {
    const {
      fetchTeam,
      sendVpv,
      match: {
        params: {
          slug
        }
      },
    } = this.props;

    if (slug) {
      fetchTeam(slug);
    }

    // virtualPageView
    sendVpv();
  }

  componentDidUpdate(prevProps: Props) {
    const {
      match: {
        params: {
          slug: prevSlug,
        },
      },
      location: prevLocation,
    } = prevProps;
    const {
      fetchTeam,
      sendVpv,
      match: {
        params: {
          slug
        }
      },
      location,
    } = this.props;

    if (slug && prevSlug !== slug) {
      fetchTeam(slug);
    }

    // virtualPageView on location change
    if (location !== prevLocation) {
      sendVpv();
    };
  }

  renderRouteEnfants = () => {
    const { match: { path }, liste_enfants } = this.props;
    /**
     * Tester si le slug de niveau 3 actuel est dans la liste des routes possibles
     * Si non, renvoyer une 404
     */
    return liste_enfants.map<any>((route, index) => {
      if (route.object_slug !== '') {
        return (
          <Route key={index} exact path={`${path}/${route.object_slug}`} component={TeamInfoContainer} />
        );
      }
      return null;
    });
  };

  renderRouteInfosPratiques = () => {
    const { match: { path }, liste_enfants } = this.props;

    const enfant_ok = liste_enfants.filter((enfant) => {
      return enfant.title.match('ratique');
    });
    if (enfant_ok.length === 0) {
      return (
        <Route key={'infos_pratiques'} exact path={`${path}/informations-pratiques`} component={TeamInfoContainer} />
      );
    } else {
      return null;
    }
  };

  render() {
    const { match: { path, params: { slug } }, status, compte_joueurs, pratique } = this.props;

    return (
      <>
        <TeamHeader slug={slug} />

        <Switch>
          <Route exact path={`${path}/resultats`} component={TeamResultContainer} />
          <Route exact path={`${path}/calendrier`} component={TeamCalendarContainer} />
          <Route exact path={`${path}/classement`} component={TeamRankingContainer} />
          {!!compte_joueurs && compte_joueurs >= MIN_EFFECTIF &&
            <Route exact path={`${path}/effectif`} component={TeamPlayersContainer} />
          }
          <Route exact path={`${path}/statistiques`} component={TeamStatsContainer} />
          {this.renderRouteEnfants()}
          {pratique && pratique.id !== '' && this.renderRouteInfosPratiques()}
          <Route exact path={`${path}`} component={TeamDetailContainer} />
          <Route exact path={`${path}/`} component={TeamDetailContainer} />
          {(status === STATUS_SUCCESS || status === STATUS_UNKNOWN) &&
            <Route render={() => <Redirect to='/404' />} />
          }
        </Switch>
      </>
    );
  }
}

export default TeamRoutes;
