// @flow

import {
  FETCH_PAGE_NODE_SUCCESS,
  FETCH_PAGE_NODE_START,
  FETCH_PAGE_NODE_FAILURE,
  FETCH_UNKNOWN
} from 'actions/pageActions';
import {
  STATUS_LOADING,
  STATUS_FAILURE,
  STATUS_SUCCESS,
  STATUS_DEFAULT,
  STATUS_UNKNOWN
} from 'constants/statusConstants';
import { nullPageNode, type PageNodeType } from 'types/PageNode';
import type { ActionType } from 'types/Actions';

export type State = {
  pageNode: PageNodeType,
  status: string,
};

const initialState: State = {
  pageNode: nullPageNode,
  status: STATUS_DEFAULT,
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_PAGE_NODE_START:
      return {
        ...state,
        pageNode: action.payload.pageNode,
        status: STATUS_LOADING
      };
    case FETCH_PAGE_NODE_SUCCESS:
      return {
        ...state,
        pageNode: action.payload.pageNode,
        status: STATUS_SUCCESS
      };
    case FETCH_PAGE_NODE_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    case FETCH_UNKNOWN:
      return {
        ...state,
        status: STATUS_UNKNOWN,
      };
    default:
      return state;
  }
}
