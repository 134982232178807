// @flow
import React from 'react';

import MediaSubmissionFormEmail from 'containers/modals/MediaSubmissionFormEmailContainer';

type Props = {
  content: any,
  hideModal: () => void
};

function MediaSubmissionModalContent({ content, hideModal }: Props) {
  return (
    <div className="modal-content box modal-share-content modal-share-content--with-bg modal-share-content--intro ">
      <div className="modal__header">
        <i role="button" tabIndex={0} className="icon icon-close js-closeModal" onClick={hideModal} />
        <h3 className="ft-h2 mb-1">Partager vos contenus</h3>
        <p>Partagez vos plus belles photos de l&apos;évènement et donnez vie au site de votre club</p>
        {/* eslint-disable-next-line */}
        <a
          href="https://ffrugby.zendesk.com/hc/fr/articles/360011943679-comment-envoyer-des-photos-a-mon-club"
          title="Comment ça fonctionne ?"
          target="__blank"
          rel="noopener noreferrer"
        >
          Comment ça fonctionne ?
        </a>
      </div>
      <div className="modal__wrapper">
        <MediaSubmissionFormEmail title={content && content.title ? content.title : ''} />
      </div>
    </div>
  );
}

export default MediaSubmissionModalContent;
