// @flow

import type { CompetitionRankingType } from 'types/Ranking';

export const FETCH_RANKING_START = 'FETCH_RANKING_START';
export const FETCH_RANKING_SUCCESS = 'FETCH_RANKING_SUCCESS';
export const FETCH_RANKING_FAILURE = 'FETCH_RANKING_FAILURE';


export type FETCH_RANKING_START_ACTION = {
  type: 'FETCH_RANKING_START',
  payload: {
    competitions: number[],
    clubID: string,
  }
};

export type FETCH_RANKING_FAILURE_ACTION = {
  type: 'FETCH_RANKING_FAILURE'
};

export type FETCH_RANKING_SUCCESS_ACTION = {
  type: 'FETCH_RANKING_SUCCESS',
  payload: {
    ranking: CompetitionRankingType[]
  }
};

export type Action =
  | FETCH_RANKING_START_ACTION
  | FETCH_RANKING_FAILURE_ACTION
  | FETCH_RANKING_SUCCESS_ACTION;

export function fetchRanking(clubID: string, competitions: number[]): FETCH_RANKING_START_ACTION {
  return {
    type: FETCH_RANKING_START,
    payload: {
      competitions,
      clubID,
    },
  };
}

export function rankingFetched(ranking: CompetitionRankingType[]): FETCH_RANKING_SUCCESS_ACTION {
  return {
    type: FETCH_RANKING_SUCCESS,
    payload: { ranking },
  };
}

export function rankingFetchError(): FETCH_RANKING_FAILURE_ACTION {
  return {
    type: FETCH_RANKING_FAILURE
  };
}
