// @flow
import { convertPartenaireFromApi } from 'services/Partenaire';

import type { ApiExecutorType } from 'types/ApiExecutorType';

export default function (executor: ApiExecutorType) {
  const fetchPartenaire = (slug: string) => executor
    .get(`/ffr/v1/partenaires?slug=${slug}`)
    .then(response => convertPartenaireFromApi(response[0]));

  return {
    fetchPartenaire,
  };
};
