// @flow
import type { BlocContent } from 'types/NewsletterBloc';
import type { SEOMetaType } from 'types/SEOType';

export type PratiqueContact = {
  id: string,
  email: string,
  emailPublic: string,
  nom: string,
  prenom: string,
  numeroAffiliation: string,
  telPublic: string,
  fonction: string[],
};

export type PratiqueAdresse = {
  complement?: string,
  email?: string,
  id?: number,
  localite?: string,
  codePostal?: string,
  voie?: string,
  lieuDitBoitePostale?: string,
  latitude?: number,
  longitude?: number,
  refPays?: string,
  paysNom?: string,
  immeuble?: string,
  codeInsee?: string,
  position?: {
    lat: string,
    lng: string
  }
}

export type PratiqueAgenda = {
  jour: number,
  jourNom: string,
  heureDebut?: string,
  heureFin?: string,
  Adresse: PratiqueAdresse,
};

export type Pratique = {
  id: string,
  emailPublic?: string,
  telPublic?: string,
  Pratique: { 
    id: string,
    nom: string,
  },
  Personne: PratiqueContact,
  Agenda: PratiqueAgenda[],
};

export type ContactRowType = {
  category: string,
  members: Array<{ 
    name: string,
    role: string,
    email: string,
    phoneNumber: string,
    emailPublic: string,
    telPublic: string
  }>
};

export type ContactListType = ContactRowType[];

export type ComiteFunctionType = {
  fonction: string,
  personne: {
    email: string,
    nom: string,
    numeroAffiliation: string,
    prenom: string,
    emailPublic: string,
    telPublic: string,
  }
};

export type PracticeType = {
  name: string,
  description: string,
  place: string
};

export type RawClubType = SEOMetaType & {
  id: number,
  date: string,
  date_gmt: string,
  guid: {
    rendered: string
  },
  modified: string,
  modified_gmt: string,
  slug: string,
  status: string,
  type: string,
  link: string,
  tel_pro: string,
  title: {
    rendered: string,
    raw: string,
    data: [
      {
        type: string,
        text: string
      }
    ]
  },
  excerpt: {
    rendered: string,
    protected: boolean,
    raw: string,
    data: Array<any>
  },
  template: string,
  categories: Array<any>,
  tags: Array<any>,
  pratiques: Array<any>,
  siege_social: {
    id: number,
    author: string,
    status: string,
    date: string,
    date_utc: string,
    modified: string,
    modified_utc: string,
    venue: string,
    description: string,
    slug: string,
    address: string,
    city: string,
    country: string,
    province: string,
    zip: string,
    phone: string,
    website: string,
    email: string,
    latitude: number,
    longitude: number
  },
  terrains: Array<any>,
  website: string,
  embleme: string,
  pratiques_list: Array<any>,
  competitions_list: Array<any>,
  tags_list: string[],
  sidebar_content: {
    data: BlocContent[]
  },
  facebook: string,
  twitter: string,
  comite_directeur: ComiteFunctionType[]
};

export type RawClubPreviewType = {
  id: number,
  title: string,
  long_name: string,
  latitude: number,
  longitude: number,
  city: string,
  address: string,
  zip: string,
  country: string,
  embleme: string,
  slug: string,
  pratiques_list: Array<{
    id: number,
    name: string
  }>,
  competitions_list: Array<{
    id: number,
    name: string
  }>
};

export type RawPresidentsType = {
  items: Array<{
    personne: {
      personneId: number,
      numeroAffiliation: string,
      nom: string,
      prenom: string,
      email: string
    },
    fonction: string,
    codeQualite: ?string
  }>
};

export const nullPratique: Pratique = {
    id: '',
    Pratique: { 
      id: '',
      nom: '',
    },
    Personne: {
      id: '',
      email: '',
      emailPublic: '',
      nom: '',
      prenom: '',
      numeroAffiliation: '',
      telPublic: '',
      fonction: [],
    },
    Agenda: [],
};
export const nullPresidents: ContactRowType = {
  category: 'Direction',
  members: []
};
