// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import TagApi from 'api/tag/TagApi';
import { FETCH_TAGS_START, tagsFetched, tagsFetchError } from 'actions/tagsActions';

export default function(apiExecutor: ApiExecutorType) {
  const tagsApi = new TagApi(apiExecutor);

  return function* tagsSaga(): GeneratorType {
    yield takeLatest(FETCH_TAGS_START, fetchTags);
  };

  function* fetchTags(action): Saga<void> {
    try {
      const tags = yield call(tagsApi.fetchTags);
      yield put(tagsFetched(tags));
    } catch (e) {
      yield put(tagsFetchError());
    }
  }
}
