// @flow
import React from 'react';
import LazyloadLibrary from 'react-lazyload';
import Loading from 'components/fragments/Loading';

type Props = {
  height?: string,
  autoSize: boolean,
  children: any,
  isWhite: boolean,
};

function getHeight(props: Props): Object {
  if (props.autoSize) {
    return { height: '100%' };
  }
  return {};
}

const LazyLoad = (props: Props) => (
  <LazyloadLibrary
    once
    offset={200}
    placeholder={<Loading />}
    {...getHeight(props)}
    {...props}>
    {props.children}
  </LazyloadLibrary>
);

LazyLoad.defaultProps = {
  autoSize: true,
  isWhite: false,
};

export default LazyLoad;
