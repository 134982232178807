// @flow

import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS, STATUS_DEFAULT } from 'constants/statusConstants';
import { FETCH_EVENT_START, FETCH_EVENT_SUCCESS, FETCH_EVENT_FAILURE } from 'actions/eventActions';
import { nullEvent } from 'types/Event';

import type { EventType } from 'types/Event';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  event: EventType,
  status: Status
};

const initialState: State = {
  event: nullEvent,
  status: STATUS_DEFAULT
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_EVENT_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_EVENT_SUCCESS:
      return {
        ...state,
        event: action.payload.event,
        status: STATUS_SUCCESS
      };
    case FETCH_EVENT_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
