// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import { convertArticleFromApi, convertCategoryFromApi, convertCategoryArticleFromApi } from 'services/Article';
import { FORM_CONTACT_TOKEN } from 'constants/api';

function ArticleApi(executor: ApiExecutorType) {
  return {
    fetchTmpCategories,
    fetchTmpArticleStaticCategories,
    fetchArticleBySlug,
    fetchArticlePreviewById,
    fetchArticlesBillboard,
    fetchNewArticles,
    fetchArticlesByPage,
    fetchCategories,
    fetchArticleByCategory,
    fetchArticlesByCategory,
    fetchArticleStaticByCategory,
    fetchArticleStaticCategories,
    fetchArticleByOneCategory,
    fetchArticleStaticByOneCategory,
  };

  function fetchArticleBySlug(slug: string) {
    return executor.get(`/ffr/v1/posts?slug=${slug}&per_page=1`).then(response => {
      if (!response || response.length === 0) {
        throw new Error('NOT FOUND');
      }

      return convertArticleFromApi(response[0]);
    });
  }

  function fetchArticlePreviewById(id: number) {
    return executor
      .get(`/ffr/v1/posts/${id}`, {
        withCredentials: false,
        headers: {
          Authorization: `Basic ${FORM_CONTACT_TOKEN}`,
        }
      })
      .then(response => convertArticleFromApi(response));
  }

  function fetchArticlesBillboard() {
    return executor.get(`last_posts.json`).then(response => response.map(a => convertArticleFromApi(a)));
  }

  function fetchNewArticles(after: Date) {
    return executor
      .get(`/ffr/v1/posts?after=${after.toISOString()}`)
      .then(response => response.map(a => convertArticleFromApi(a)));
  }

  function fetchArticlesByPage(page: number, per_page: number) {
    return executor
      .get(`/ffr/v1/posts?page=${page}&per_page=${per_page}`)
      .then(response => response.map(a => convertArticleFromApi(a)));
  }

  function fetchTmpCategories() {
    return executor.get(`/wp/v2/categories?per_page=100`).then(response => response.map(category => convertCategoryFromApi(category)));
  }

  function fetchCategories() {
    return executor.get(`categories.json`).then(response => response.map(category => convertCategoryFromApi(category)));
  }

  function fetchArticleByCategory(page: number, per_page: number, category: any) {
    return executor.get(`/ffr/v1/posts?page=${page}&per_page=${per_page}&categories=${category.id}`).then(response => {
      category.items = response.map(a => convertArticleFromApi(a));
      return {
        category: convertCategoryArticleFromApi(category),
        maxPage: 1
      };
    });
  }

  function fetchArticlesByCategory(page: number, per_page: number, category: any) {
    return executor.get(`/ffr/v1/posts?page=${page}&per_page=${per_page}&categories=${category.id}`)
      .then(response => response.map(a => convertArticleFromApi(a)));
  }

  function fetchArticleStaticByCategory(category: any) {
    return executor.get(`latest_posts_`+category.id+`.json`).then(response => {
      category.items = response.map(a => convertArticleFromApi(a));
      return {
        category: convertCategoryArticleFromApi(category),
        maxPage: 1
      };
    });
  }

  function fetchArticleStaticCategories() {
    return executor.get(`lastest_posts_categories.json`).then(response => {
      return response;
    });
  }

  function fetchTmpArticleStaticCategories() {
    return executor.get(`/ffr/v1/posts_categories`).then(response => response);
  }

  function fetchArticleByOneCategory(page: number, per_page: number, category: any) {
    return executor
      .getWithHeader(`/ffr/v1/posts?page=${page}&per_page=${per_page}&categories=${category.id}`)
      .then(response => ({
        articles: response.body.map(a => convertArticleFromApi(a)),
        articleNumber: response.headers['x-wp-total']
      }));
  }

  function fetchArticleStaticByOneCategory(category: any) {
    return executor
      .getWithHeader(`latest_posts_`+category.id+`.json`)
      .then(response => ({
        articles: response.body.map(a => convertArticleFromApi(a)),
        articleNumber: response.headers['x-wp-total']
      }));
  }
}

export default ArticleApi;
