/// @flow
import React, { Fragment, PureComponent } from 'react';
import { dynamicClassName } from 'utils/dynamicClassName';
import ErrorMessage from 'components/fragments/ErrorMessage';

type Props = {
  id: string,
  placeholder?: string,
  className?: string,
  label: string,
  errorMessage?: string,
  hasError?: boolean,
  isDark?: boolean,
  isLight?: boolean,
  onChange?: (value: string) => void,
  type?: string,
  defaultValue?: string | null,
  disable?: boolean,
  onFocus?: () => void,
  onBlur?: () => void,
};

class TextInputMaterial extends PureComponent<Props> {
  props: Props;

  static defaultProps = {
    type: 'text',
    disable: false,
    onFocus: () => {},
    onBlur: () => {},
  };

  _handleInputChanged = (e: any) => {
    if (this.props.onChange) this.props.onChange(e.target.value);
  };

  _onInputStatusChange = (event: SyntheticFocusEvent<EventTarget>, isFocus: boolean) => {
    const { target } = event;
    const { placeholder } = this.props;

    if (target instanceof HTMLInputElement) {
      if (isFocus || target.value !== '') {
        target.classList.remove('is-empty');
      } else if (!placeholder) {
        target.classList.add('is-empty');
      }
    }
  };

  render() {
    const { 
      type, 
      isDark, 
      isLight, 
      hasError, 
      className, 
      id,
      label, 
      defaultValue,
      placeholder,
      disable, 
      onFocus, 
      onBlur
    } = this.props;
    const classNames = dynamicClassName('input');
    classNames.add('input--material');
    className && classNames.add(className);
    isDark && classNames.add('is-dark');
    isLight && classNames.add('input--light');
    hasError && classNames.add('has-error');

    return (
      <Fragment>
        <div className={classNames.build()}>
          <input
            defaultValue={defaultValue && defaultValue}
            type={type}
            id={id}
            disabled={disable}
            className={!defaultValue && !placeholder ? 'is-empty' : '' }
            onChange={this._handleInputChanged}
            onFocus={(event) => {
              this._onInputStatusChange(event, true);
              onFocus && onFocus();
            }}
            onBlur={(event) => {
              this._onInputStatusChange(event, false);
              onBlur && onBlur();
            }}
            placeholder={placeholder}
            />
          <label htmlFor={id} data-label={label}>
            {label}
          </label>
          {hasError && <i className="icon icon-info" />}
        </div>
        {hasError && this.props.errorMessage && <ErrorMessage message={this.props.errorMessage} />}
      </Fragment>
    );
  }
}

export default TextInputMaterial;
