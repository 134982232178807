// @flow

import {
  STATUS_LOADING,
  STATUS_FAILURE,
  STATUS_SUCCESS,
  STATUS_DEFAULT,
  STATUS_UNKNOWN,
} from 'constants/statusConstants';
import {
  FETCH_PAGE_PREVIEWBYID_START,
  FETCH_PAGE_PREVIEWBYID_SUCCESS,
  FETCH_PAGE_PREVIEWBYID_FAILURE,
  FETCH_UNKNOWN
} from 'actions/pageActions';

import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';
import type { PageDetailPreviewType } from 'types/PageDetail';

import { nullPageDetailPreview } from 'types/PageDetail';

export type State = PageDetailPreviewType & {
  status: Status,
};

const initialState: State = {
  ...nullPageDetailPreview,
  status: STATUS_DEFAULT,
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_PAGE_PREVIEWBYID_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_PAGE_PREVIEWBYID_SUCCESS:
      return {
        ...state,
        title: action.payload.pageDetail.title, 
        title_parent: action.payload.pageDetail.title_parent,
        content: action.payload.pageDetail.content,
        description: action.payload.pageDetail.description,
        slug: action.payload.pageDetail.slug,
        slug_parent: action.payload.pageDetail.slug_parent.replace('/', ''),
        link: action.payload.pageDetail.link,
        featured_media: action.payload.pageDetail.featured_media,
        sidebar_content: action.payload.pageDetail.sidebar_content,
        meta_description: action.payload.pageDetail.meta_description,
        meta_title: action.payload.pageDetail.meta_title,
        template: action.payload.pageDetail.template,
        redirection: action.payload.pageDetail.redirection,
        rencontre_background: action.payload.pageDetail.rencontre_background,
        liste_enfants: action.payload.pageDetail.liste_enfants,
        pratiques_structure: action.payload.pageDetail.pratiques_structure,
        pratique: action.payload.pageDetail.pratique,
        status: STATUS_SUCCESS
      };
    case FETCH_PAGE_PREVIEWBYID_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    case FETCH_UNKNOWN:
      return {
        ...state,
        status: STATUS_UNKNOWN,
      };
    default:
      return state;
  }
}
