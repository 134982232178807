// @flow

import { STATUS_DEFAULT, STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_RENCONTRE_LIVE_START,
  FETCH_RENCONTRE_LIVE_SUCCESS,
  FETCH_RENCONTRE_LIVE_FAILURE,
  SET_RENCONTRE_LIVE,
  REMOVE_RENCONTRE_LIVE,
} from 'actions/rencontreLiveActions';

import type { RencontreLiveType } from 'types/Rencontre';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  rencontres: {
    [key: string]: RencontreLiveType,
  },
  status: Status,
  message: string,
};

const initialState: State = {
  rencontres: {},
  status: STATUS_DEFAULT,
  message: '',
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_RENCONTRE_LIVE_START:
      return {
        ...state,
        status: STATUS_LOADING,
      };
    case FETCH_RENCONTRE_LIVE_SUCCESS:
      return {
        ...state,
        rencontres: {
          ...state.rencontres,
          ...action.payload.rencontres
        },
        status: STATUS_SUCCESS,
      };
    case FETCH_RENCONTRE_LIVE_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE,
        message: action.payload.error,
      };
    case SET_RENCONTRE_LIVE:
      return {
        ...state,
        rencontres: {
          ...state.rencontres,
          [action.payload.id]: {
            ...state.rencontres[action.payload.id],
            actions: action.payload.actions,
          },
        }
      };
    case REMOVE_RENCONTRE_LIVE:
      delete state.rencontres[action.payload.id];
      return state;
    default:
      return state;
  }
}
