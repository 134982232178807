// @flow

import { client } from 'infrastructure/apollo';
import { gql } from '@apollo/client';
import { convertRankingFromGraph } from 'services/Ranking';
import { nullRanking } from 'types/Ranking';

export const query = gql`
  query Competitions($id: [Int!], $structures: [ID]) {
  competitions: Competitions(id: $id) {
    nom
    id
    identifiant
    saison: Saison {
      id
      nom
    }
    phases: Phases(structures: $structures) {
      id
      nom
      nombrePoules
      intraPoule: ClassementsIntraPoule(structures: $structures) {
        id
        classements: Classements {
          id
          position
          equipe: Equipe {
            id
            nom
            lastResult: DerniersResultats(limit: 5) {
              local: RencontreResultatLocale {
                structure: Structure {
                  nom
                  id
                }
                regroupement: Regroupement {
                  nom
                  id
                }
                pointsTerrains: pointsDeMarque
              }
              visiteur: RencontreResultatVisiteuse {
                structure: Structure {
                  nom
                  id
                }
                regroupement: Regroupement {
                  nom
                  id
                }
                pointsTerrains: pointsDeMarque
              }
            }
            structure: Structure {
              id
              nom
              embleme
            }
            regroupement: Regroupement {
              nom
              id
              embleme
              structures: StructuresItem {
                nom
								id
                embleme
              }
            }
          }
          classement: Classement {
              regulationPointsTerrain
              pointTerrain
              joues
              gagnes
              nuls
              perdus
              pointsDeMarqueAcquis
              pointsDeMarqueConcedes
              goalAverage
              essaiMarques
              essaiConcedes
              bonusOffensif
              bonusDefensif
            }
          }
        }
      }
    }
  }
`;

export const queryPhase = gql`
  query Phase($id: Int, $structures: [ID]) {
    Phase(id: $id) {
      id
      nom
      Competition {
        id 
        nom
        identifiant
        saison: Saison {id nom}
      }
      nombrePoules
      intraPoule: ClassementsIntraPoule(structures: $structures) {
        id
        classements: Classements {
          id
          position
          equipe: Equipe {
            id
            nom
            lastResult: DerniersResultats(limit: 5) {
              dateEffective
              local: RencontreResultatLocale {
                structure: Structure {
                  nom
                  id
                }
                regroupement: Regroupement {
                  nom
                  id
                }
                pointsTerrains: pointsDeMarque
              }
              visiteur: RencontreResultatVisiteuse {
                structure: Structure {
                  nom
                  id
                }
                regroupement: Regroupement {
                  nom
                  id
                }
                pointsTerrains: pointsDeMarque
              }
            }
            structure: Structure {
              id
              nom
              embleme
            }
            regroupement: Regroupement {
              nom
              id
              embleme
              code
              structures: StructuresItem {
                nom
								id
                code
                embleme
              }
            }
          }
          classement: Classement {
              regulationPointsTerrain
              pointTerrain
              joues
              gagnes
              nuls
              perdus
              pointsDeMarqueAcquis
              pointsDeMarqueConcedes
              goalAverage
              essaiMarques
              essaiConcedes
              bonusOffensif
              bonusDefensif
            }
          }
        }
      }
    }
`;

export const fetchRankingFromGraph = async (id: number[], clubID: string) => {
  if (!clubID) {
    return nullRanking;
  }

  const response = await client.query({
    query,
    variables: { id, structures: [parseInt(clubID, 10)] },
  });

  return convertRankingFromGraph(response, clubID);
};
