// @flow

import { connect } from 'react-redux';

import BackgroundImage from 'components/fragments/BackgroundImage';

import type { StateProps } from 'components/fragments/BackgroundImage';
import type { StateType } from 'types/Actions';

const mapStateToProps = (state: StateType): StateProps => ({
  placeholder: state.settingsState.placeholder,
  status: state.settingsState.status,
});

export default connect(mapStateToProps)(BackgroundImage);
