// @flow
import { connect } from 'react-redux';

import LiveScore, { type StateProps, type DispatchProps } from 'components/fragments/LiveScore';

import { setRencontreLive, removeRencontreLive } from 'actions/rencontreLiveActions';

import type { StateType, DispatchType } from 'types/Actions';
import type { RencontreActionContainerType } from 'types/Rencontre';

const mapStateToProps = (state: StateType): StateProps => ({
  rencontresLive: state.rencontreLiveState.rencontres,
  competitionsEquipes: state.settingsState.competitionsEquipes,
});

const mapDispatchToProps = (dispatch: DispatchType): DispatchProps => ({
  setRencontreLive: (id: string, actions: RencontreActionContainerType[]) => dispatch(setRencontreLive(id, actions)),
  removeRencontreLive: (id: string) => dispatch(removeRencontreLive(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LiveScore);
