// @flow
import type {
  RawArticleType,
  ArticleType,
  RawCategoryType,
  CategoryType,
  RawCategoryArticleType,
  CategoryArticleType
} from 'types/Article';
import { nullArticle, nullCategory, nullCategoryArticle } from 'types/Article';
import { mergeIntoDefault, convertToJsDate } from 'utils/objectUtils';
import { convertEncodedHTML } from 'utils/convertUtils';
import { convertRencontreArticleCategory } from 'services/Rencontre';

export function convertArticleFromApi(rawArticle: RawArticleType): ArticleType {
  if (!rawArticle) {
    return nullArticle;
  }

  const {
    date,
    modified,
    title,
    content,
    tags_list,
    slug,
    link,
    featured_media,
    featured_video,
    label,
    sidebar_content,
    meta_title,
    meta_description,
    meta: {
      _actualiteFFRUrlCanonical
    },
    rencontre,
    redirect: {
      url
    },
  } = rawArticle;

  
  return mergeIntoDefault(nullArticle, {
    date: new Date(convertToJsDate(date)),
    modified: new Date(convertToJsDate(modified)),
    label: {
      name: convertEncodedHTML(label.name),
      slug: label.slug
    },
    caption: typeof featured_media === 'number' ? null : convertEncodedHTML(featured_media.legend),
    title: title.raw,
    srcset: typeof featured_media === 'number' ? null : featured_media.sizes,
    illustration: typeof featured_media === 'number' ? null : 
      ((featured_media.sizes && featured_media.sizes.large && featured_media.sizes.large.src) || featured_media.src),
    tags: tags_list,
    blocs: content.data,
    illustration_video:featured_video.data,
    twitter: null,
    facebook: null,
    sidebar: sidebar_content ? sidebar_content.data : [],
    slug,
    link,
    meta_title,
    meta_description,
    redirection: url,
    ffr_url_canonical: _actualiteFFRUrlCanonical,
    rencontre_details: rencontre
            ? convertRencontreArticleCategory(rencontre)
            : null,
  });
}

export function convertCategoryFromApi(rawCategory: RawCategoryType): CategoryType {
  if (!rawCategory) {
    return nullCategory;
  }

  const { id, name, slug } = rawCategory;

  return mergeIntoDefault(nullCategory, {
    id,
    name: convertEncodedHTML(name),
    slug
  });
}

export function convertCategoryArticleFromApi(rawCategory: RawCategoryArticleType): CategoryArticleType {
  if (!rawCategory) {
    return nullCategoryArticle;
  }

  const { id, name, slug, items } = rawCategory;

  return mergeIntoDefault(nullCategoryArticle, {
    id,
    name: convertEncodedHTML(name),
    slug,
    items
  });
}
