// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';

import {
  convertMediaResponseFromApi,
} from 'services/Media';
import { FORM_CONTACT_TOKEN } from 'constants/api';

function MediaApi(executor: ApiExecutorType) {
  return { postMedia };

  function postMedia(mediaInputs: any) {
    return executor
      .post(`/ffr/v1/upload_content`, mediaInputs, {
        withCredentials: false,
        headers: {
          Authorization: `Basic ${FORM_CONTACT_TOKEN}`,
          'content-type': 'multipart/form-data'
        },
        timeout: 6000000
      })
      .then(response => convertMediaResponseFromApi(response));
  }
}
export default MediaApi;
