// @flow
import NewsletterSubscriber from 'components/footer/NewsletterSubscriber';
import { connect } from 'react-redux';
import type { NewsletterSubscriptionEntryType } from 'types/NewsletterSubscription';

import type { StateType, DispatchType } from 'types/Actions';
import type { StateProps, DispatchProps } from 'components/footer/NewsletterSubscriber';

import { fetchNewsletterOptions, subscribeNewsletter } from 'actions/newsletterSubscriptionActions';
import { displayModal } from 'actions/modalActions';

export default connect(
  (state: StateType): StateProps => ({
    options: state.newsletterSubscriptionState.options,
    newsletterSetting: state.settingsState.newsletter,
    titleClub: state.settingsState.title,
    status: state.newsletterSubscriptionState.status
  }),
  (dispatch: DispatchType): DispatchProps => ({
    fetchOptions: (campaignMonitorKey: string) => dispatch(fetchNewsletterOptions(campaignMonitorKey)),
    subscribeNewsletter: (subscription: NewsletterSubscriptionEntryType, campaignMonitorKey: string) =>
      dispatch(subscribeNewsletter(subscription, campaignMonitorKey)),
    displayModal: (modalObject: string, content: Object, force?: boolean) =>
      dispatch(displayModal(modalObject, content, force))
  })
)(NewsletterSubscriber);
