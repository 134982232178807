// @flow
import type { RawPageListType } from 'types/PageList';
import type { BlocContent } from 'types/NewsletterBloc';

export function convertSidebarFromApi(rawSidebar: RawPageListType): Array<BlocContent> {
  if (
    !rawSidebar ||
    !rawSidebar[0] ||
    !rawSidebar[0].sidebar_content ||
    !rawSidebar[0].sidebar_content.data
  ) {
    return [];
  }

  const { sidebar_content: { data } } = rawSidebar[0];
  return data;
}

export function checkSidebar(sidebar: Array<BlocContent>): boolean {
  if (sidebar.length > 0) {
    return true;
  }

  return false;
}
