// @flow
import type { ArticleType } from 'types/Article';

export const FETCH_ARTICLES_CATEGORY_START = 'FETCH_ARTICLES_CATEGORY_START';
export const FETCH_ARTICLES_CATEGORY_SUCCESS = 'FETCH_ARTICLES_CATEGORY_SUCCESS';
export const FETCH_ARTICLES_CATEGORY_FAILURE = 'FETCH_ARTICLES_CATEGORY_FAILURE';

export type FETCH_ARTICLES_CATEGORY_START_ACTION = {
  type: 'FETCH_ARTICLES_CATEGORY_START',
  payload: {
    page: number,
    per_page: number,
    category: string
  }
};

export type FETCH_ARTICLES_CATEGORY_FAILURE_ACTION = {
  type: 'FETCH_ARTICLES_CATEGORY_FAILURE'
};

export type FETCH_ARTICLES_CATEGORY_SUCCESS_ACTION = {
  type: 'FETCH_ARTICLES_CATEGORY_SUCCESS',
  payload: {
    articles: Array<ArticleType>,
    articleNumber: number
  }
};

export type Action =
  | FETCH_ARTICLES_CATEGORY_START_ACTION
  | FETCH_ARTICLES_CATEGORY_FAILURE_ACTION
  | FETCH_ARTICLES_CATEGORY_SUCCESS_ACTION;

export function fetchArticlesCategory(
  page: number,
  per_page: number,
  category: string
): FETCH_ARTICLES_CATEGORY_START_ACTION {
  return {
    type: FETCH_ARTICLES_CATEGORY_START,
    payload: { page, per_page, category }
  };
}

export function articlesCategoryFetched(
  articles: Array<ArticleType>,
  articleNumber: number
): FETCH_ARTICLES_CATEGORY_SUCCESS_ACTION {
  return {
    type: FETCH_ARTICLES_CATEGORY_SUCCESS,
    payload: { articles, articleNumber }
  };
}

export function articlesCategoryFetchError(error: any): FETCH_ARTICLES_CATEGORY_FAILURE_ACTION {
  return {
    type: FETCH_ARTICLES_CATEGORY_FAILURE
  };
}
