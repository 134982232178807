// @flow
import type { EventDetailDateType } from 'types/Event';

export function getEventDate(detail_date: EventDetailDateType) {
  const date: any = Object.keys(detail_date).reduce(
    (accumulator, key: string) => ({
      ...accumulator,
      [key]: parseInt(detail_date[key], 10)
    }),
    {}
  );
  return new Date(date.year, date.month, date.day, date.hour, date.minutes, date.seconds);
}
