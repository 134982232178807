// @flow
import React, { Component } from 'react';

import { STATUS_LOADING, STATUS_SUCCESS } from 'constants/statusConstants';
import type { PageChildType } from 'types/PageNode';
import MenuElement from 'components/header/MenuElement';
import MenuElementPlus from 'components/header/MenuElementPlus';
import { dynamicClassName } from 'utils/dynamicClassName';
import { withRouter } from 'react-router-dom';

export type StateProps = {
  subMenus: PageChildType[],
  status: string
};

type Props = {
  subMenuPath: string,
  niveauMenu: number,
  location: Location,
  navClass?: string,
  notHidden?: boolean,
} & StateProps;

type ComponentState = {
  isMobile: boolean,
  hiddenMenuItems: Array<number>,
  menuLinkX: number,
  isOpen: boolean,
  menuIsActive: number,
};

class SubMenuHeader extends Component<Props, ComponentState> {
  _menuElements: Array<?MenuElement>;
  _menu: ?HTMLElement;
  _menuLink: ?HTMLElement;
  state: ComponentState = {
    isMobile: false,
    hiddenMenuItems: [],
    menuLinkX: 0,
    isOpen: false,
    menuIsActive: 0
  };

  static defaultProps = {
    niveauMenu: 3,
    notHidden: false,
  };

  constructor(props: Props) {
    super(props);
    this._menuElements = [];
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions.bind(this));
    const { location, subMenus, niveauMenu } = this.props;
    const parseUrl = location.pathname.split('/');
    if (parseUrl.length > 2) {
      subMenus.forEach((subMenu, index) => {
        if (subMenu.object_slug === parseUrl[niveauMenu]) {
          this.activateMenu(index);
        }
      });
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.status === STATUS_LOADING) {
      this._menuElements = [];
      if (this.state.menuIsActive !== 0) {
        this.setState({ menuIsActive: 0 });
      }
    }

    if (
      (this.props.status === STATUS_SUCCESS && prevProps.status === STATUS_LOADING) ||
      (this.props.location !== prevProps.location) ||
      this.props.subMenus.length !== prevProps.subMenus.length) {
      const { location, subMenus, niveauMenu } = this.props;
      const parseUrl = location.pathname.split('/');
      if (parseUrl.length > 2) {
        this.activateMenu(0);
        subMenus.forEach((subMenu, index) => {
          if (subMenu.object_slug === parseUrl[niveauMenu]) {
            this.activateMenu(index);
          }
        });
      }
    }
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  resetMenuElementRef = (c: ?MenuElement) => {
    this._menuElements = [];
    this.addMenuElementRef(c);
  };

  addMenuElementRef = (c: ?MenuElement) => {
    this._menuElements.push(c);
  };

  isDisplayed = (id: number) => {
    return !this.state.hiddenMenuItems.includes(id);
  };

  onHandleSubMenuMobile = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen
    });
  };

  updateDimensions() {
    const menu = this._menu;
    const menuLink = this._menuLink;
    if (menu && menuLink) {
      const menuLinkBounds: any = menuLink.getBoundingClientRect();
      const menuLinkX = menuLinkBounds.left;

      if (menuLinkX !== this.state.menuLinkX) this.setState({ menuLinkX });
      let maxWidth = menu.getBoundingClientRect().width - 123;

      if (maxWidth > 1157) {
        maxWidth -= 120;
      }
      if (maxWidth > 1540) {
        maxWidth = 1540;
      }

      const width_menu_links =
        this._menuElements.reduce((total, item) => (item ? total + item.getSize() : total), 0) +
        this._menuElements.length * 32;

      let currentWidth = width_menu_links;
      let deleted = [];
      if (maxWidth > 644) {
        for (let i = this._menuElements.length - 1; i > 0; i--) {
          if (currentWidth > maxWidth) {
            if (this._menuElements[i]) currentWidth -= this._menuElements[i].getSize() + 32;
            deleted = [...deleted, i];
          }
        }
        if (this.state.isMobile) this.setState({ isMobile: false });
      } else {
        if (!this.state.isMobile) this.setState({ isMobile: true });
      }
      if (
        (deleted.length !== this.state.hiddenMenuItems.length &&
          (deleted.length > 0 || this.state.hiddenMenuItems.length > 0) &&
          !deleted.every(id => this.state.hiddenMenuItems.includes(id))) ||
        !this.state.hiddenMenuItems.every(id => deleted.includes(id))
      ) {
        this.setState({ hiddenMenuItems: deleted });
      }
    }
  }

  activateMenu = (id: number) => {
    this.setState({
      menuIsActive: id,
      isOpen: false
    });
  };

  renderMenu = () => {
    const { subMenus, subMenuPath } = this.props;
    const { menuIsActive } = this.state;

    const test = subMenus.map((menu, index) => {
      return (
        <MenuElement
          url={menu.type_name === 'external' ? menu.object_slug : `${subMenuPath}/${menu.object_slug}`}
          urlType={menu.type_name}
          title={menu.title}
          titleAll="Toutes les informations"
          isMobile={false}
          ref={ref => (index === 0 ? this.resetMenuElementRef(ref) : this.addMenuElementRef(ref))}
          id={index}
          isDisplayed={this.isDisplayed}
          menuX={this.state.menuLinkX}
          activateMenu={this.activateMenu}
          isActive={index === menuIsActive}
          key={index}
        />
      );
    });
    return test;
  };

  render() {
    const { subMenus, status, navClass, notHidden } = this.props;
    const { isOpen, isMobile, menuIsActive } = this.state;

    const overlayClass = dynamicClassName('overlay');
    const navClassName = dynamicClassName('navbar');
    navClass && navClassName.add(navClass);

    const titleClassName = dynamicClassName('accordion-trigger ft-up');
    isOpen && subMenus.length > 1 && overlayClass.add('is-visible');
    isOpen && subMenus.length > 1 && titleClassName.add('is-selected');
    isOpen && subMenus.length > 1 && navClassName.add('has-accordion-open');

    const menuContainerClassName = dynamicClassName('navbar__wrapper accordion-panel');
    isOpen && subMenus.length > 1 && menuContainerClassName.add('has-overlay');
    ((subMenus.length === 1 && !notHidden) || (!isOpen && isMobile)) && menuContainerClassName.add('is-hidden');

    const hiddenMenuElements = this.state.hiddenMenuItems.map(id => {
      return this._menuElements[id];
    }).sort((a, b) => {
      /**
       * @XXX Flow doesn't support props getter from HTML Element
       * Oh wait ... https://github.com/facebook/flow/issues/740#issuecomment-418582749
       * Sorry :'(
       */
      // $FlowFixMe
      if(a.props.titleAll > b.props.titleAll) { return 1; }
      // $FlowFixMe
      if(a.props.titleAll < b.props.titleAll) { return -1; }
      return 0;
    }).reverse();
    const nbVisibleMenuElements = subMenus.length - hiddenMenuElements.length;
    const menuElementPlusId = nbVisibleMenuElements;

    return (
      <>
      <div className={overlayClass.build()} role="button" tabIndex={0} onClick={this.onHandleSubMenuMobile}></div>
      <nav className={navClassName.build()}>
        <a // eslint-disable-line
          className={titleClassName.build()}
          role="button"
          tabIndex={0}
          onClick={this.onHandleSubMenuMobile}
        >
          {status === STATUS_LOADING ?  subMenus[0] && subMenus[0].title : subMenus[menuIsActive] && subMenus[menuIsActive].title}
        </a>
        <div className={menuContainerClassName.build()} ref={c => (this._menu = c)}>
          <ul className="accordion-panel navbar__links" ref={c => (this._menuLink = c)}>
            {this.renderMenu()}
            <MenuElementPlus
              hiddenMenuElements={hiddenMenuElements}
              isMobile={this.state.isMobile}
              id={menuElementPlusId}
              isDisplayed={this.isDisplayed}
              menuX={this.state.menuLinkX}
              activateMenu={this.activateMenu}
              isActive={menuElementPlusId === menuIsActive}
              text="En voir plus"
              isNavbar={true}
            />
          </ul>
        </div>
      </nav>
      </>
    );
  }
}

export default withRouter(SubMenuHeader);
