// @flow

import React, { PureComponent } from 'react';

import MediaUploader from 'components/fragments/MediaUploader';
import TextInputMaterial from 'components/fragments/input/material/TextInputMaterial';
import CheckboxInput from 'components/fragments/input/CheckboxInput';
import Button from 'components/fragments/button/Button';
import Loading from 'components/fragments/Loading';
import { STATUS_SUCCESS } from 'constants/statusConstants';

import { generateID } from 'utils/idGeneratorUtils';
import type { DisplayModalFunctionType } from 'types/Modal';
import type { Status } from 'types/Status';
import type { mediaInputsType } from 'types/Media';

export type DispatchProps = {
  postMedia: (mediaInputs: mediaInputsType) => void,
  displayModal: DisplayModalFunctionType,
  mediaReinit: () => void
};

export type StateProps = {
  sendStatus: Status,
  albumid: number
};
type Props = {
  email: ?string,
  title: string
} & StateProps &
  DispatchProps;

type State = {
  files: { name: string, base64: string }[],
  caption: string,
  consent: boolean,
  event: string,
  error: string,
  formSent: boolean
};

export default class ShareContentForm extends PureComponent<Props, State> {
  state = {
    files: [],
    caption: '',
    consent: false,
    event: '',
    error: '',
    formSent: false
  };

  handleFileUploaded = (files: { name: string, base64: string }[]) => {
    this.setState({ files });
  };

  handleCaptionChange = (caption: string) => {
    this.setState({ caption });
  };

  handleEventSelection = (event: string) => {
    this.setState({ event });
  };

  handleCheckConsent = (consent: boolean) => {
    this.setState({ consent });
  };

  isFormValid = () => {
    //const { event, caption } = this.state;

    //if (!event || event === '') return false;
    //if (!caption || caption === '') return false;

    return true;
  };

  handleSubmit = (e: MouseEvent) => {
    e.preventDefault();
    const { caption, event, files } = this.state;

    const { albumid } = this.props;

    this.setState({ error: '' });

    if (this.isFormValid()) {
      const { postMedia, email, title } = this.props;

      let formData = {
        'ffr-caption': caption || '',
        'ffr-email': email || '',
        'ffr-event': event || title,
        'ffr-title': title || '',
        'ffr-album': albumid || '',
        'ffr-files': JSON.stringify(files)
      };

      this.setState({ formSent: true });
      postMedia(formData);
    } else {
      this.setState({
        formSent: false,
        error: 'Veuillez vérifier le contenu du formulaire',
        event: event === null ? '' : event,
        caption: caption === null ? '' : caption
      });
    }
  };

  render() {
    const { files, caption, event, consent, formSent } = this.state;
    const { displayModal, title, sendStatus, mediaReinit } = this.props;
    const notHydrated = !files.length || !caption || (!event && !title) || !consent;

    if (sendStatus === STATUS_SUCCESS) {
      displayModal('SHARE_CONTENT_SUCCESS', { nbFiles: files.length }, true);
      mediaReinit();
    }

    return (
      <>
        {formSent ? (
          <Loading isWhite style={{ width: '300px', margin: '2em auto', textAlign: 'center' }} />
        ) : (
          <>
            <MediaUploader
              onFileChange={this.handleFileUploaded}
              accept={'image/png, image/jpeg, video/mp4, video/quicktime'}
              buttonText={'Sélectionner un nouveau fichier (jpeg, png, mp4)'}
            />
            <TextInputMaterial
              id={generateID()}
              label="Légende"
              className={'mb-2'}
              onChange={this.handleCaptionChange}
            />
            <TextInputMaterial
              id={generateID()}
              label="Évènements"
              className={'mb-2'}
              defaultValue={title}
              onChange={this.handleEventSelection}
            />
            <CheckboxInput id={generateID()} className="checkbox mb-4" onChange={this.handleCheckConsent}>
              {'Je certifie disposer des droits nécessaires pour partager et publier ce contenu et j’accepte les'}&nbsp;
              {/* @TODO: plug href */}
              {/* eslint-disable-next-line */}
              <a href="#" title="Conditions générales d'utilisation">
                conditions générales d’utilisation
              </a>
              &nbsp;
              {'du site'}
            </CheckboxInput>
            <Button label="Continuer" onClick={this.handleSubmit} isDisabled={notHydrated} isFull />
          </>
        )}
      </>
    );
  }
}
