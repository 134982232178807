// @flow

import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS, STATUS_DEFAULT } from 'constants/statusConstants';
import { FETCH_SIDEBAR_START, FETCH_SIDEBAR_SUCCESS, FETCH_SIDEBAR_FAILURE } from 'actions/sidebarActions';

import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';
import type { SidebarType } from 'types/Sidebar';

export type State = {
  data: SidebarType,
  status: Status
};

const initialState: State = {
  data: [],
  status: STATUS_DEFAULT
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_SIDEBAR_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_SIDEBAR_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        status: STATUS_SUCCESS
      };
    case FETCH_SIDEBAR_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
