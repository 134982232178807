// @flow

import { STATUS_DEFAULT, STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_NEWSLETTER_OPTIONS_START,
  FETCH_NEWSLETTER_OPTIONS_SUCCESS,
  FETCH_NEWSLETTER_OPTIONS_FAILURE,
  SUBSCRIBE_NEWSLETTER_START,
  SUBSCRIBE_NEWSLETTER_SUCCESS,
  SUBSCRIBE_NEWSLETTER_FAILURE
} from 'actions/newsletterSubscriptionActions';

import type { NewsletterSubscriptionType } from 'types/NewsletterSubscription';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';
import { nullNewsletterSubscription } from 'types/NewsletterSubscription';

export type State = {
  options: NewsletterSubscriptionType,
  status: Status
};

const initialState: State = {
  options: nullNewsletterSubscription,
  status: STATUS_DEFAULT
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case SUBSCRIBE_NEWSLETTER_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_NEWSLETTER_OPTIONS_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case SUBSCRIBE_NEWSLETTER_SUCCESS:
      return {
        ...state,
        status: STATUS_SUCCESS
      };
    case FETCH_NEWSLETTER_OPTIONS_SUCCESS:
      return {
        ...state,
        options: action.payload.options,
        status: STATUS_SUCCESS
      };
    case SUBSCRIBE_NEWSLETTER_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    case FETCH_NEWSLETTER_OPTIONS_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
