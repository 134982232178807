// @flow

export const FETCH_TEAM_START = 'FETCH_TEAM_START';
export const FETCH_TEAM_SUCCESS = 'FETCH_TEAM_SUCCESS';
export const FETCH_TEAM_FAILURE = 'FETCH_TEAM_FAILURE';

export type FETCH_TEAM_START_ACTION = {
  type: 'FETCH_TEAM_START',
  payload: {
    slug: string,
    season?: string,
  }
};

export type FETCH_TEAM_FAILURE_ACTION = {
  type: 'FETCH_TEAM_FAILURE',
};

export type FETCH_TEAM_SUCCESS_ACTION = {
  type: 'FETCH_TEAM_SUCCESS',
};

export type Action = FETCH_TEAM_START_ACTION
                  | FETCH_TEAM_FAILURE_ACTION
                  | FETCH_TEAM_SUCCESS_ACTION;


export function fetchTeam(slug: string, season?: string): FETCH_TEAM_START_ACTION {
  return {
    type: FETCH_TEAM_START,
    payload: { slug, season }
  };
}

export function fetchTeamSuccess(): FETCH_TEAM_SUCCESS_ACTION {
  return {
    type: FETCH_TEAM_SUCCESS,
  };
}

export function fetchTeamError(): FETCH_TEAM_FAILURE_ACTION {
  return {
    type: FETCH_TEAM_FAILURE
  };
}
