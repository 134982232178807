// @flow
import React, { PureComponent } from 'react';
import type { NewsletterSubscriptionType, NewsletterSubscriptionEntryType } from 'types/NewsletterSubscription';
import type { Status } from 'types/Status';
import { STATUS_DEFAULT } from 'constants/statusConstants';
import TextInput from 'components/fragments/input/TextInput';
import CheckboxInput from 'components/fragments/input/CheckboxInput';
import type { DisplayModalFunctionType } from 'types/Modal';
import { validateEmail } from 'utils/validatorUtils';
import { handleTagEvent } from 'utils/tagManagerUtils';

export type StateProps = {
  options: NewsletterSubscriptionType,
  status: Status,
  newsletterSetting: {
    type: false | 'campaign_monitor' | 'lien' | 'mailjet' | 'mailchimp',
    lien?: string,
    key?: string
  },
  titleClub: string
};

export type DispatchProps = {
  fetchOptions: (campaignMonitorKey: string) => void,
  subscribeNewsletter: (subscription: NewsletterSubscriptionEntryType, campaignMonitorKey: string) => void,
  displayModal: DisplayModalFunctionType
};

type Props = {
  mode?: string
} & StateProps &
  DispatchProps;

type State = {
  profil: null | string,
  email: null | string,
  emailValide: boolean,
  consentOpt1: boolean,
  consentOpt2: boolean
};

class NewsletterSubscriber extends PureComponent<Props, State> {
  props: Props;
  state: State = {
    profil: null,
    email: null,
    emailValide: false,
    consentOpt1: false,
    consentOpt2: false
  };

  componentDidMount() {
    const { newsletterSetting, fetchOptions } = this.props;

    if (newsletterSetting.type === 'campaign_monitor') {
      fetchOptions(newsletterSetting.key || '');
    }
  }

  componentDidUpdate() {
    if (this.props.newsletterSetting.type === 'campaign_monitor' && this.props.status === STATUS_DEFAULT)
      this.props.fetchOptions(this.props.newsletterSetting.key || '');
  }

  setProfil = (profil: string) => {
    this.setState({ profil });
  };

  setEmail = (email: string) => {
    if (validateEmail(email)) {
      this.setState({
        email,
        emailValide: true
      });
    } else {
      this.setState({ emailValide: false });
    }
  };

  setConsent = (value: boolean, checkboxType: string) => {
    this.setState({ [checkboxType]: value });
  };

  handleSubmitClicked = (e: MouseEvent) => {
    e.preventDefault();
    const { email, consentOpt1, consentOpt2 } = this.state;
    const { subscribeNewsletter, displayModal, titleClub } = this.props;
    const { key } = this.props.newsletterSetting;
    const campaignMonitorKey = !key ? '' : key;

    handleTagEvent('user', 'inscription_newsletter', window.location.pathname);
    if (email === null) {
      this.setState({
        email: email === null ? '' : email
      });
    }

    if (!consentOpt1) {
      displayModal('NEWSLETTER_MISSING_OPT', { titleClub }, true);
    } else if (validateEmail(email)) {
      const { email } = this.state;

      const options = {
        ConsentToTrack: consentOpt1 ? 'yes' : 'no',
        CustomFields: [
          {
            Key: 'Optin1',
            Value: consentOpt1 ? 'yes' : 'no'
          },
          {
            Key: 'Optin2',
            Value: consentOpt2 ? 'yes' : 'no'
          }
        ],
        EmailAddress: email,
        Name: '',
        action: 'subscribe'
      };
      subscribeNewsletter(options, campaignMonitorKey);
    }
  };

  renderLinkButton = () => {
    const { titleClub } = this.props;
    return (
      <div className="col col-lg-9">
        <h5 className="mb-1">Newsletter</h5>
        <p className="mb-2">Recevez par email les nouveautés du club {titleClub}</p>
        <a
          title={`S'inscrire à la newsletter du club ${titleClub}`}
          className="btn btn--primary"
          href={this.props.newsletterSetting.lien || '#'}
        >
          {"S'inscrire"}
        </a>
      </div>
    );
  };

  render() {
    const {
      titleClub,
      options: { options },
      newsletterSetting: { type },
      status,
      mode
    } = this.props;
    const { emailValide, email } = this.state;
    const labels = {
      optin1: `J’accepte les emails du club ${titleClub} à propos des nouveautés et promotions du club..`
    };

    if (type === false || (type === 'campaign_monitor' && status === STATUS_DEFAULT)) return null;
    if (type === 'lien') return this.renderLinkButton();
    else {
      if (type === 'campaign_monitor' && options.length === 0) {
        return null;
      }
      if (mode === 'sidebar') {
        return (
          <div className="card__body">
            <h3 className="card__title">Newsletter</h3>
            <p className="card__desc">Recevez par email les nouveautés du club {titleClub}</p>
            <form action="#">
              <TextInput
                id="email"
                isDark
                placeholder="Adresse mail*"
                hasError={email !== null && !validateEmail(email)}
                onChange={this.setEmail}
              />
              <CheckboxInput
                className={'checkbox'}
                id="hgsdfsddf123"
                label={labels['optin1']}
                onChange={value => this.setConsent(value, 'consentOpt1')}
              />
              <div></div>
              <button
                className="btn btn--primary btn--full"
                type="submit"
                onClick={this.handleSubmitClicked}
                disabled={!emailValide && true}
              >
                <i className="icon icon-valid is-inline" />
                Inscription
              </button>
            </form>
          </div>
        );
      }
      return (
        <div className="col col-lg-9">
          <h5 className="mb-1">Newsletter</h5>
          <p className="mb-2">Recevez par email les nouveautés du club {titleClub}</p>
          <form action="#">
            <TextInput
              id="email"
              isDark
              placeholder="Adresse mail*"
              hasError={email !== null && !validateEmail(email)}
              onChange={this.setEmail}
            />
            <CheckboxInput
              className={'checkbox'}
              id="hgsdfsddf123"
              label={labels['optin1']}
              onChange={value => this.setConsent(value, 'consentOpt1')}
            />
            <div></div>
            <button
              className="btn btn--primary"
              type="submit"
              onClick={this.handleSubmitClicked}
              disabled={!emailValide && true}
            >
              <i className="icon icon-valid is-inline" />
              Inscription
            </button>
          </form>
        </div>
      );
    }
  }
}

export default NewsletterSubscriber;
