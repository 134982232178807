// @flow

import type { EventPreviewType } from 'types/Event';

export const FETCH_EVENTS_PREVIEW_START = 'FETCH_EVENTS_PREVIEW_START';
export const FETCH_EVENTS_PREVIEW_SUCCESS = 'FETCH_EVENTS_PREVIEW_SUCCESS';
export const FETCH_EVENTS_PREVIEW_FAILURE = 'FETCH_EVENTS_PREVIEW_FAILURE';

export type FETCH_EVENTS_PREVIEW_START_ACTION = {
  type: 'FETCH_EVENTS_PREVIEW_START'
};

export type FETCH_EVENTS_PREVIEW_FAILURE_ACTION = {
  type: 'FETCH_EVENTS_PREVIEW_FAILURE'
};

export type FETCH_EVENTS_PREVIEW_SUCCESS_ACTION = {
  type: 'FETCH_EVENTS_PREVIEW_SUCCESS',
  payload: {
    events: Array<EventPreviewType>
  }
};

export type Action =
  | FETCH_EVENTS_PREVIEW_START_ACTION
  | FETCH_EVENTS_PREVIEW_FAILURE_ACTION
  | FETCH_EVENTS_PREVIEW_SUCCESS_ACTION;

export function fetchEventsPreview(): FETCH_EVENTS_PREVIEW_START_ACTION {
  return {
    type: FETCH_EVENTS_PREVIEW_START
  };
}

export function eventsPreviewFetched(events: Array<EventPreviewType>): FETCH_EVENTS_PREVIEW_SUCCESS_ACTION {
  return {
    type: FETCH_EVENTS_PREVIEW_SUCCESS,
    payload: { events }
  };
}

export function eventsPreviewFetchError(): FETCH_EVENTS_PREVIEW_FAILURE_ACTION {
  return {
    type: FETCH_EVENTS_PREVIEW_FAILURE
  };
}
