// @flow
import type { BlocContent } from 'types/NewsletterBloc';
import type { CardType } from 'types/PageList';
import type { Pratique } from 'types/Club';
import type { SEOMetaType } from 'types/SEOType';
import { nullSeoMeta } from 'types/SEOType';
import { nullPratique } from 'types/Club';

export type PageChildType = {
  menu_order: number,
  object_id: number,
  type_name: string,
  object_slug: string,
  title: string,
  type_name: string,
  description: string,
  featured_media: {
    src: string,
  },
  texte_cta: string,
  liste_enfants?: CardType[],
  types_public: string[],
}

export type PageNodeType = SEOMetaType & {
  id: number,
  title: string,
  titleParent: string,
  description: string,
  sidebar_content: BlocContent[],
  content: BlocContent[],
  slug_parent: string,
  slug: string,
  parent: number,
  rencontre_background: string,
  featured_media: {
    src: string,
    legend: string,
  },
  liste_enfants: PageChildType[],
  template: string,
  niveau: number,
  redirection: string,
  pratiques_structure: Pratique[],
  pratique: Pratique,
};

export type RawPageNodeType = SEOMetaType & {
  id: number,
  title: {
    raw: string
  },
  title_parent: string,
  parent: number,
  excerpt: {
    raw: string
  },
  sidebar_content: {
    data: BlocContent[]
  },
  content: {
    data: BlocContent[]
  },
  slug: string,
  slug_parent: string,
  rencontre_background: string,
  featured_media: {
    src: string,
    legend: string,
  },
  liste_enfants: PageChildType[],
  template: string,
  niveau: number,
  redirect: {
    type: string,
    postid: string,
    title: string,
    url: string,
  },
  pratiques_structure: any[],
  pratique: any,
};

export const nullPageNode: PageNodeType = {
  ...nullSeoMeta,
  id: 0,
  title: '',
  titleParent: '',
  description: '',
  content: [],
  sidebar_content: [],
  parent: 0,
  slug_parent: '',
  slug: '',
  rencontre_background: '',
  featured_media: {
    src: '',
    legend: '',
  },
  liste_enfants: [
    {
      menu_order: 0,
      object_id: 0,
      type_name: '',
      object_slug: '',
      title: '',
      description: '',
      featured_media: {
        src: ''
      },
      texte_cta: '',
      liste_enfants: [],
      types_public: [],
    }
  ],
  template: '',
  niveau: 0,
  redirection: '',
  pratiques_structure: [],
  pratique: nullPratique
};

export const loadingPageNode: PageNodeType = {
  ...nullSeoMeta,
  id: 0,
  title: 'Chargement',
  titleParent: 'Chargement',
  description: '',
  content: [],
  sidebar_content: [],
  parent: 0,
  slug_parent: '',
  slug: '',
  rencontre_background: '',
  featured_media: {
    src: '',
    legend: '',
  },
  liste_enfants: [
    {
      menu_order: 0,
      object_id: 0,
      type_name: '',
      object_slug: '',
      title: '',
      description: '',
      featured_media: {
        src: ''
      },
      texte_cta: '',
      types_public: []
    }
  ],
  template: '',
  niveau: 2,
  redirection: '',
  pratiques_structure: [],
  pratique: nullPratique
};
