// @flow

import type { ArticleType } from 'types/Article';
import {nullFailureArticle, nullLoadingArticle} from 'types/Article';

export const FETCH_ARTICLE_START = 'FETCH_ARTICLE_START';
export const FETCH_ARTICLE_START_PREVIEW = 'FETCH_ARTICLE_START_PREVIEW';
export const FETCH_ARTICLE_SUCCESS = 'FETCH_ARTICLE_SUCCESS';
export const FETCH_ARTICLE_FAILURE = 'FETCH_ARTICLE_FAILURE';
export const FETCH_ARTICLE_NOT_FOUND = 'FETCH_ARTICLE_NOT_FOUND';

export type FETCH_ARTICLE_START_ACTION = {
  type: 'FETCH_ARTICLE_START',
  payload: {
    slug: string,
    article: ArticleType
  }
};

export type FETCH_ARTICLE_START_PREVIEW_ACTION = {
  type: 'FETCH_ARTICLE_START_PREVIEW',
  payload: {
    id: number,
    article: ArticleType
  }
};

export type FETCH_ARTICLE_FAILURE_ACTION = {
  type: 'FETCH_ARTICLE_FAILURE',
  payload: {
    article: ArticleType
  }
};

export type FETCH_ARTICLE_NOT_FOUND_ACTION = {
  type: 'FETCH_ARTICLE_NOT_FOUND',
  payload: {
    article: ArticleType
  }
};

export type FETCH_ARTICLE_SUCCESS_ACTION = {
  type: 'FETCH_ARTICLE_SUCCESS',
  payload: {
    article: ArticleType,
    analytics: boolean
  }
};

export type Action = FETCH_ARTICLE_START_ACTION
                  | FETCH_ARTICLE_START_PREVIEW_ACTION
                  | FETCH_ARTICLE_FAILURE_ACTION
                  | FETCH_ARTICLE_NOT_FOUND_ACTION
                  | FETCH_ARTICLE_SUCCESS_ACTION;

export function fetchArticle(slug: string): FETCH_ARTICLE_START_ACTION {
  return {
    type: FETCH_ARTICLE_START,
    payload: {
      slug: slug,
      article: nullLoadingArticle
    }
  };
}

export function fetchArticlePreview(id: number): FETCH_ARTICLE_START_PREVIEW_ACTION {
  return {
    type: FETCH_ARTICLE_START_PREVIEW,
    payload: {
      id: id,
      article: nullLoadingArticle
    }
  };
}

export function articleFetched(article: ArticleType, analytics?: boolean): FETCH_ARTICLE_SUCCESS_ACTION {
  return {
    type: FETCH_ARTICLE_SUCCESS,
    payload: {
      article,
      analytics: analytics ? analytics : false
    }
  };
}

export function articleFetchError(error: any): FETCH_ARTICLE_FAILURE_ACTION {
  return {
    type: FETCH_ARTICLE_FAILURE,
    payload: {
      article: nullFailureArticle,
    }
  };
}

export function articleFetchNotFound(): FETCH_ARTICLE_NOT_FOUND_ACTION {
  return {
    type: FETCH_ARTICLE_NOT_FOUND,
    payload: {
      article: nullFailureArticle,
    }
  };
}
