// @flow

import { takeLatest, put, call } from 'redux-saga/effects';

import AlbumApi from 'api/album/AlbumApi';

import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';

import {
  FETCH_ALBUM_START,
  FETCH_ALBUMS_START,
  fetchAlbumSuccess,
  fetchAlbumFailure,
  fetchAlbumsSuccess,
  fetchAlbumsFailure,
} from 'actions/albumActions';
import type {
  FETCH_ALBUM_START_ACTION,
  FETCH_ALBUMS_START_ACTION
} from 'actions/albumActions';

export default function(apiExecutor: ApiExecutorType) {
  const albumApi = new AlbumApi(apiExecutor);

  function* fetchAlbum(action: FETCH_ALBUM_START_ACTION): Saga<void> {
    const { slug, args } = action.payload;
    try {
      const album = yield call(albumApi.fetchAlbum, slug, args);
      yield put(fetchAlbumSuccess(album));
    } catch (error) {
      yield put(fetchAlbumFailure(error));
    }
  }

  function* fetchAlbums(action: FETCH_ALBUMS_START_ACTION): Saga<void> {
    try {
      const albums = yield call(albumApi.fetchAlbums);
      yield put(fetchAlbumsSuccess(albums));
    } catch (error) {
      yield put(fetchAlbumsFailure(error));
    }
  }

  return function* albumSaga(): GeneratorType {
    yield takeLatest(FETCH_ALBUM_START, fetchAlbum);
    yield takeLatest(FETCH_ALBUMS_START, fetchAlbums);
  };
}
