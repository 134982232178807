// @flow

import type { EventType } from 'types/Event';

export const FETCH_EVENT_START = 'FETCH_EVENT_START';
export const FETCH_EVENT_SUCCESS = 'FETCH_EVENT_SUCCESS';
export const FETCH_EVENT_FAILURE = 'FETCH_EVENT_FAILURE';

export type FETCH_EVENT_START_ACTION = {
  type: 'FETCH_EVENT_START',
  payload: {
    slug: string
  }
};

export type FETCH_EVENT_FAILURE_ACTION = {
  type: 'FETCH_EVENT_FAILURE'
};

export type FETCH_EVENT_SUCCESS_ACTION = {
  type: 'FETCH_EVENT_SUCCESS',
  payload: {
    event: EventType,
    analytics: boolean
  }
};

export type Action = FETCH_EVENT_START_ACTION | FETCH_EVENT_FAILURE_ACTION | FETCH_EVENT_SUCCESS_ACTION;

export function fetchEvent(slug: string): FETCH_EVENT_START_ACTION {
  return {
    type: FETCH_EVENT_START,
    payload: {
      slug
    }
  };
}

export function eventFetched(event: EventType, analytics?: boolean): FETCH_EVENT_SUCCESS_ACTION {
  return {
    type: FETCH_EVENT_SUCCESS,
    payload: {
      event,
      analytics: analytics ? analytics : false
    }
  };
}

export function eventFetchError(): FETCH_EVENT_FAILURE_ACTION {
  return {
    type: FETCH_EVENT_FAILURE
  };
}
