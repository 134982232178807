// @flow

import type {
  RencontreLiveType,
  RencontreActionContainerType,
} from 'types/Rencontre';

export const FETCH_RENCONTRE_LIVE_START = 'FETCH_RENCONTRE_LIVE_START';
export const FETCH_RENCONTRE_LIVE_SUCCESS = 'FETCH_RENCONTRE_LIVE_SUCCESS';
export const FETCH_RENCONTRE_LIVE_FAILURE = 'FETCH_RENCONTRE_LIVE_FAILURE';
export const SET_RENCONTRE_LIVE = 'SET_RENCONTRE_LIVE';
export const REMOVE_RENCONTRE_LIVE = 'REMOVE_RENCONTRE_LIVE';

export type FETCH_RENCONTRE_LIVE_START_ACTION = {
  type: 'FETCH_RENCONTRE_LIVE_START',
};

export type FETCH_RENCONTRE_LIVE_FAILURE_ACTION = {
  type: 'FETCH_RENCONTRE_LIVE_FAILURE',
  payload: {
    error: string
  },
};

export type FETCH_RENCONTRE_LIVE_SUCCESS_ACTION = {
  type: 'FETCH_RENCONTRE_LIVE_SUCCESS',
  payload: {
    rencontres: { [key: string]: RencontreLiveType },
  },
};

export type SET_RENCONTRE_LIVE_ACTION = {
  type: 'SET_RENCONTRE_LIVE',
  payload: {
    id: string,
    actions: RencontreActionContainerType[],
  },
};

export type REMOVE_RENCONTRE_LIVE_ACTION = {
  type: 'REMOVE_RENCONTRE_LIVE',
  payload: {
    id: string,
  },
};

export type Action =
  | FETCH_RENCONTRE_LIVE_START_ACTION
  | FETCH_RENCONTRE_LIVE_FAILURE_ACTION
  | FETCH_RENCONTRE_LIVE_SUCCESS_ACTION
  | SET_RENCONTRE_LIVE_ACTION
  | REMOVE_RENCONTRE_LIVE_ACTION;

export function fetchRencontreLive(): FETCH_RENCONTRE_LIVE_START_ACTION {
  return {
    type: FETCH_RENCONTRE_LIVE_START,
  };
};

export function rencontreLiveFetched(
  rencontres: { [key: string]: RencontreLiveType }
): FETCH_RENCONTRE_LIVE_SUCCESS_ACTION {
  return {
    type: FETCH_RENCONTRE_LIVE_SUCCESS,
    payload: {
      rencontres,
    },
  };
};

export function rencontreLiveFetchError(error: string): FETCH_RENCONTRE_LIVE_FAILURE_ACTION {
  return {
    type: FETCH_RENCONTRE_LIVE_FAILURE,
    payload: { error },
  };
};

export function setRencontreLive(
  id: string, actions: RencontreActionContainerType[]
): SET_RENCONTRE_LIVE_ACTION {
  return {
    type: SET_RENCONTRE_LIVE,
    payload: {
      actions,
      id,
    },
  };
};

export function removeRencontreLive(id: string): REMOVE_RENCONTRE_LIVE_ACTION {
  return {
    type: REMOVE_RENCONTRE_LIVE,
    payload: {
      id,
    },
  };
}
