// @flow
import { convertAlbumFromApi, convertAlbumsFromApi } from 'services/Album';

import type { ApiExecutorType } from 'types/ApiExecutorType';

export default function (executor: ApiExecutorType) {
  const fetchAlbums = () => executor
    .get(`/ffr/v1/albums?per_page=1000`)
    .then(response => convertAlbumsFromApi(response));

  const fetchAlbum = (slug: string, args: string) => {
    let path = `/ffr/v1/albums?slug=${slug}`;
    if (args && args.length > 0) {
      path = `/ffr/v1/albums?${args}`;
    }
    return executor
      .get(path)
      .then(response => convertAlbumFromApi(response[0]));
  };

  return {
    fetchAlbum,
    fetchAlbums,
  };
};
