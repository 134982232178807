// @flow

import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_FAQ_THEME_START,
  FETCH_FAQ_THEME_SUCCESS,
  FETCH_FAQ_THEME_FAILURE,
  FETCH_FAQ_QUESTIONS_START,
  FETCH_FAQ_QUESTIONS_SUCCESS,
  FETCH_FAQ_QUESTIONS_FAILURE
} from 'actions/faqActions';

import type { ThemeType, QuestionType } from 'types/Faq';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  themes: Array<ThemeType>,
  questions: Array<QuestionType>,
  status: Status
};

const initialState: State = {
  themes: [],
  questions: [],
  status: STATUS_LOADING
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_FAQ_THEME_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_FAQ_THEME_SUCCESS:
      return {
        ...state,
        themes: action.payload.themes,
        status: STATUS_SUCCESS
      };
    case FETCH_FAQ_THEME_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    case FETCH_FAQ_QUESTIONS_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_FAQ_QUESTIONS_SUCCESS:
      return {
        ...state,
        questions: action.payload.questions,
        status: STATUS_SUCCESS
      };
    case FETCH_FAQ_QUESTIONS_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
