// @flow

import {
  STATUS_LOADING,
  STATUS_FAILURE,
  STATUS_SUCCESS,
  STATUS_DEFAULT,
  STATUS_UNKNOWN,
} from 'constants/statusConstants';
import {
  FETCH_ARTICLE_START,
  FETCH_ARTICLE_START_PREVIEW,
  FETCH_ARTICLE_SUCCESS,
  FETCH_ARTICLE_FAILURE,
  FETCH_ARTICLE_NOT_FOUND,
} from 'actions/articleActions';
import { nullArticle } from 'types/Article';

import type { ArticleType } from 'types/Article';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  article: ArticleType,
  status: Status
};

const initialState: State = {
  article: nullArticle,
  status: STATUS_DEFAULT
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_ARTICLE_START:
      return {
        ...state,
        article: action.payload.article,
        status: STATUS_LOADING
      };
    case FETCH_ARTICLE_START_PREVIEW:
      return {
        ...state,
        article: action.payload.article,
        status: STATUS_LOADING
      };
    case FETCH_ARTICLE_SUCCESS:
      return {
        ...state,
        article: action.payload.article,
        status: STATUS_SUCCESS
      };
    case FETCH_ARTICLE_FAILURE:
      return {
        ...state,
        article: action.payload.article,
        status: STATUS_FAILURE
      };
    case FETCH_ARTICLE_NOT_FOUND:
      return {
        ...state,
        article: action.payload.article,
        status: STATUS_UNKNOWN
      };
    default:
      return state;
  }
}
